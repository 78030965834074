import React, { useState, useEffect } from 'react'
import { supabase } from '../utils/supaBase';
import CardModal from './CardModal'
import { useNavigate } from 'react-router-dom'

const IntegrationCard = ({ campaign, active }) => {

  const [profile, setProfile] = useState();
  const [session, setSession] = useState(null);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [intrestModal, setIntrestModal] = useState(false);
  const [checkIntrest,setIntrest ]= useState("");

  useEffect(() => {
    let userId = "";
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      userId = session.user.id;
      fetchProfile(userId)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      fetchProfile(session.user.id)
    })

    async function fetchProfile(userId) {
 
      const { data, error } = await supabase
      .from('campaign')
      .select('*')
      .eq('uid',userId)
      .eq('campaign_type_id',campaign.campaign_id)

      if (error) {
        setError('Could not fetch data')
        setIntrest(false)
        }
      else{
        setIntrest(true)
        
      }

    }
  }, [])
  async function handleSubmit(e) {

    const { error } = await supabase.from('campaign')
      .insert({
        uid: session.user.id,
        campaign_type_id: campaign.campaign_id,
        campaign_name: campaign.title,
        campaign_type: campaign.integration_type,
        platform: campaign.integration_type,
        deliverable: [campaign.deliverables],
        startdate: campaign.shoot_date,
        enddate: campaign.closing_date,
        budgetlow: campaign.low_budget,
        budgethigh: campaign.high_budget,
        label: campaign.label,
        artist_involved: campaign.artist_involved,
        verified: true   //intrest of user saved as true
      })
    if (error) {
      setError(error.error_description)
    } else {
      if (!error) {
        //window.location.reload()
      }
    }
    navigate('/integrationthankyou')
  }
  return (
    <div className={`md:mr-10 ${active ? 'bg-[#FEE1BA]' : ' bg-[#FD3599] bg-opacity-20'}
      sm:p-8 p-4 lg:min-w-[280px] md:min-w-[280px] sm:min-w-[300px]  
      lg:min-h-[300px] md:min-h-[300px] sm:min-h-[320px] rounded-xl md:my-0 my-6`} >


      {campaign && campaign.integration_type == "SONG_INTEGRATION" ?
        <div>
          <p className='font-bold font-poppins sm:text-[15px] text-[12px] py-1 text-left' style={{ textTransform: 'capitalize' }}>  {campaign.title ? campaign.title : ''}</p>
          <p className='font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left trans' style={{ textTransform: 'capitalize' }}>Artist Involved : {campaign.artist_involved ? campaign.artist_involved : 'empty'}</p>
          <p className='font-normal font-[DM Sans] sm:text-[15px] text-[12px] py-1 text-left flex flex-wrap' style={{ textTransform: 'capitalize' }}>Date of Shoot : {campaign.shoot_date ? campaign.shoot_date : 'empty'}
          </p>
          <p className='font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left trans' style={{ textTransform: 'capitalize' }}>Date of Closing : {campaign.closing_date ? campaign.closing_date : 'empty'}</p>
          <p className='font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left trans' style={{ textTransform: 'capitalize' }}>Deliverables : {campaign.deliverables ? campaign.deliverables : 'empty'}</p>
          <p className='font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left trans' style={{ textTransform: 'capitalize' }}>Budget Involved for Integration  : ₹{campaign.low_budget ? campaign.low_budget : 'empty'}
            -to- ₹{campaign.high_budget ? campaign.high_budget : 'empty'}
          </p>
          
        </div>
        : <div>
          <p className='font-bold font-poppins sm:text-[15px] text-[12px] py-1 text-left' style={{ textTransform: 'capitalize' }}> Event Title :  {campaign.title ? campaign.title : ''}</p>
          <p className='font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left trans' style={{ textTransform: 'capitalize' }}>Description : {campaign.description ? campaign.description : 'empty'}</p>
        
        </div>
      }


{checkIntrest && checkIntrest === false ?
  <div className='flex flex-row items-center text-center'>
        <button className='mt-4 bg-[#D10269] p-2 px-4 rounded-lg mr-5 text-white sm:text-[14px] text-[11px]'
          onClick={() => {
            setIsOpen(true)
            setIntrestModal(true)
          }
          }>Intrested</button>

       {intrestModal &&
          <CardModal open={isOpen} onCLose={() => { setIsOpen(false) }} showClose={true}>
            <p>Are you sure you want to be intrested ?</p>
            <button className='bg-[#D10269] p-2 px-10 mt-4
             rounded-sm  text-white sm:text-[15px] text-[12px]'onClick={handleSubmit}>
              Intrested
            </button>
          </CardModal>
        }
      </div>
:
<div className='flex flex-row items-center text-center'>
<span className='mt-4 bg-[#D10269] p-2 px-4 rounded-lg mr-5 text-white sm:text-[14px] text-[11px]'>
    Request Already Submitted
</span>
  </div>}
      
    </div>
  )
}

export default IntegrationCard;