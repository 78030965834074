import React, { useEffect } from 'react'
import { supabase } from '../utils/supaBase'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

const IntegrationRequestList =() =>
{
    const location = useLocation();
    const [campaign, setCampaigns] = useState([]);
    const campaignId = location.state.campaignId;
    const [error, setError] = useState(" ");
    const [title, settitle] = useState(" ");
    
    useEffect(() =>{
        async function fetchCampaigns() {
            
            const { data, error } = await supabase
              .from('campaign_list')
              .select('*')
              .eq('campaign_type_id', campaignId);
               
            if (error) {
              setError('Could not fetch data');
              setCampaigns([]);
             
            } else {
              setCampaigns(data);
              setError(null);
              if(data !== "")
              {
                settitle(data[0].title)
              }
            }
            
          }
         
        fetchCampaigns();
       
    },[])
   
    return(
        
        <div className='md:mr-10 shadow-md border-2 border-gray-300 sm:p-8 p-4 
        lg:min-w-[250px] sm:min-w-[220px] min-w-[120px] sm:min-h-[150px] min-h-[200px]
               rounded-md md:my-0 my-6 text-justify items-justify bg-pink-100' >
        <div className='border-1 border-gray-300 sm:p-8 p-4 
        lg:min-w-[full] sm:min-w-[full] min-w-[full] 
               rounded-md md:my-0 my-6 text-center items-center text-[30px]'>
          <span style={{textTransform: 'capitalize' , textAlign : 'center'}}
          classname="font-dmSans sm:text-[20px] text-[20px] font-bold py-1">
            Deal Name : {title && title != ''?title : ''}
           </span>
        </div>
        <table  className='md:mr-10 shadow-md border-2 border-gray-300 sm:p-8 p-4 
        lg:min-w-[full] sm:min-w-[full] min-w-[full] sm:min-h-[full] min-h-[full]
               rounded-md md:my-0 my-6 text-justify items-justify'
        style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}>
            <tr  style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
            classname='bg-pink-500'>
            <td className='font-dmSans sm:text-[20px] text-[20px] 
             py-1 text-left bg-pink-300' style={{width:"400px"}}>Campaign Request by  </td>   
            <td className='font-dmSans sm:text-[20px] text-[20px] py-1 text-left bg-pink-300'
             style={{width:"250px"}}>Company Name  </td>
            <td className='font-dmSans sm:text-[20px] text-[20px] py-1 text-left bg-pink-300' 
            style={{width:"200px"}}>Website  </td>  
            <td className='font-dmSans sm:text-[20px] text-[20px] py-1 text-left bg-pink-300' 
            style={{width:"200px"}}>Email  </td>
            <td className='font-dmSans sm:text-[20px] text-[20px] py-1 text-left bg-pink-300' 
            style={{width:"150px"}}>Phone  </td>
            
            </tr>
            {campaign != '' ? 
                 campaign.map((campaign)=>
                 {
                  return(
                         <tr  style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}>
                          <td style={{width:"400px"}}>{campaign.agency_name}</td>
                          <td style={{width:"250px"}}>{campaign.company_name}</td>
                          <td style={{width:"200px"}}>{campaign.website}</td>
                          <td style={{width:"200px"}}>{campaign.email}</td>
                          <td style={{width:"150px"}}>{campaign.phone}</td>
                          
                        </tr>
                  )
                  
                 }
                 )
                 :<div>
                  <span style={{textTransform: 'capitalize' , textAlign : 'center'}}
                   classname="font-dmSans sm:text-[10px] text-[10px] font-bold py-1">
                    No Data Available
            </span></div> }
            </table>
      </div>
                
    )
}
export default IntegrationRequestList;