import React from "react";
import { supabase } from "../utils/supaBase";
import { useState } from "react";
import CardModal from "./CardModal";
import ApproveModalUI from "./ApproveModalUI";
import { useNavigate } from "react-router-dom";

const ApproveCard = ({ profile, declined }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [viewApprove, setViewApprove] = useState();

  const [viewDisable, setDisable] = useState(false);

  async function handleApprove() {
    const { error } = await supabase
      .from("profiles")
      .update({ verified: true , status:"APPROVE" })
      .eq("id", profile.id);

    if (error) {
      alert(error.error_description || error.message);
    } else {
      window.location.reload(true);
    }
  }

  async function handleDecline() {
    console.log("declined");

    const { error } = await supabase
      .from("profiles")
      .update({ declined: true })
      .eq("id", profile.id);

    if (error) {
      alert(error.error_description || error.message);
    } else {
      window.location.reload(true);
    }
  }

  async function handleDisable() {
   

    const { error } = await supabase
      .from("profiles")
      .update({ status: "DISABLE" })
      .eq("id", profile.id);

    if (error) {
      alert(error.error_description || error.message);
    } else {
      window.location.reload(true);
    }
  }

  return (
    <div
      className="md:mr-10 bg-black bg-opacity-10 p-5 sm:min-w-[290px] min-w-[200px]  
      rounded-md md:my-0 my-6 relative"
    >
      <p></p>
      <p className="font-bold font-poppins sm:text-[17px] text-[15px] pb-1 text-left">
        {profile.company_name ? profile.company_name : "empty"} 
      </p>
      <p className="font-normal font-[DM Sans] sm:text-[15px] text-[13px] text-opacity-60 py-1 text-left">
        {profile.email ? profile.email : "empty"} 
      </p>

      <p className="font-normal font-[DM Sans] sm:text-[17px] text-[13px] py-1 text-left">
        {profile.designation ? profile.designation : "empty"}

        {profile.agency_name ? " at " + profile.agency_name : ""}
      </p>

      <div className="flex flex-row justify-between">
        <button
          className="font-normal font-poppins text-[15px] py-1 text-[#D10269] flex"
          onClick={() => {
            setIsOpen(true);
            setViewDetails(true);
          }}
        >
          <span>View Details</span>
        </button>

        {!declined &&
          (profile.verified ? (
            ""
          ) : (
            <div className="flex flex-row justify-between">
              <button
                onClick={() => {
                  setIsOpen(true);
                  setViewDetails(false);
                  setViewApprove(true);
                }}
                className="mt-4 bg-[#D10269] p-1   px-6 rounded-sm text-white"
              >
                Accept
              </button>
              <button
                onClick={() => {
                  setIsOpen(true);
                  setViewDetails(false);
                  setViewApprove(false);
                }}
                className="pt-4 text-[#D10269] font-bold"
              >
                Decline
              </button>
            </div>
          ))}

        {!declined && profile.verified && (
          <>
            <button
              className="font-normal font-poppins text-[15px] py-1 text-[#D10269] flex"
              onClick={() =>
                navigate("/dashboardadmin/viewcampaigns", {
                  state: {
                    profileid: profile.id,
                  },
                })
              }
            >
              View Campaigns
            </button>
            {profile.status === "DISABLE" ? (  
              <div>
                <button
                  onClick={() => {
                    setIsOpen(true);
                    setViewDetails(false);
                    setViewApprove(true);
                  }}
                  className="mt-4 bg-[#D10269] p-1   px-6 rounded-sm text-white"
                >
                  Accept
                </button>
                <span className="pt-4 text-[#D10269] font-bold">
                  (Disabled)
                </span>
              </div>
            ) : (
              <button
                onClick={() => {
                  setIsOpen(true);
                  setViewDetails(false);
                  setViewApprove(false);
                  setDisable(true);
                }}
                className="mt-4 bg-[#D10269] p-1   px-6 rounded-sm text-white "
              >
                Disable
              </button>
            )}
          </>
        )}
      </div>

      {declined && <p className="pt-4 text-[#D10269] font-bold">Declined</p>}

      {viewDetails && (
        <CardModal
          open={isOpen}
          onCLose={() => {
            setIsOpen(false);
          }}
          showClose={true}
        >
          <div className="flex flex-col  h-[200px] sm:w-full w-[200px]  overflow-y-scroll flex-wrap">
            <table className="font-normal font-poppins sm:text-[13px] text-[13px] border-collapse border border-gray-300">
              <tbody>
                <tr>
                  <th className="border border-gray-300 p-2">Field</th>
                  <th className="border border-gray-300 p-2">Value</th>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Name</td>
                  <td className="border border-gray-300 p-2">
                    {profile.company_name ? profile.company_name : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Designation</td>
                  <td className="border border-gray-300 p-2">
                    {profile.designation ? profile.designation : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Email</td>
                  <td className="border border-gray-300 p-2">
                    {profile.email ? profile.email : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Agency</td>
                  <td className="border border-gray-300 p-2">
                    {profile.agency_name ? profile.agency_name : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">GST no</td>
                  <td className="border border-gray-300 p-2">
                    {profile.gst_no ? profile.gst_no : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Phone</td>
                  <td className="border border-gray-300 p-2">
                    {" "}
                    {profile.phone ? profile.phone : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Website</td>
                  <td className="border border-gray-300 p-2">
                    {profile.website ? profile.website : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Social Media</td>
                  <td className="border border-gray-300 p-2">
                    {profile.social_media ? profile.social_media : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Location</td>
                  <td className="border border-gray-300 p-2">
                    {profile.location ? profile.location : "empty"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardModal>
      )}
      {!viewDetails &&
        (viewApprove ? (
          <CardModal
            open={isOpen}
            onCLose={() => {
              setIsOpen(false);
            }}
            showClose={true}
          >
            <ApproveModalUI
              title={"ARE YOU SURE YOU WANT TO APPROVE THE REQUEST?"}
              subtitle={"If you choose to approve, the status will change ...."}
              handleApprove={handleApprove}
            />
          </CardModal>
        ) : (
          <CardModal
            open={isOpen}
            onCLose={() => {
              setIsOpen(false);
            }}
            showClose={true}
          >
            <ApproveModalUI
              title={"ARE YOU SURE YOU WANT TO DECLINE THE REQUEST?"}
              subtitle={"If you choose to decline, the status will change ...."}
              handleApprove={handleDecline}
            />
          </CardModal>
        ))}
      {!(viewDetails && viewApprove) ? (
        viewDisable ? (
          <CardModal
            open={isOpen}
            onCLose={() => {
              setIsOpen(false);
            }}
            showClose={true}
          >
            <ApproveModalUI
              title={"ARE YOU SURE YOU WANT TO DISABLE THE LOGIN?"}
              subtitle={"If you choose to Disable, the status will change ...."}
              handleApprove={handleDisable}
            />
          </CardModal>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default ApproveCard;
