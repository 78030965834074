import React, { useEffect } from 'react'
import { supabase } from '../utils/supaBase'
import { useState } from 'react'



const ContactQuery =() =>
{
    
    const [requestlist, setRequest] = useState([]);
    
    const [error, setError] = useState();
    
    

    useEffect(() =>{
        supabase.auth.getSession().then(({ data: { session } }) => {
        
        })
        async function fetchRequest() {
            
            const { data, error1 } = await supabase
              .from('contact_request')
              .select('*')
             
               
            if (error1) {
              setError('Could not fetch data');
              setRequest([]);
              alert(error1.message);
            } else {
                setRequest(data);
              setError(null);
            }
            console.log(error)
          }
         
          fetchRequest();
       
    },[])
   
    return(
        
        <div className='md:mr-10 shadow-md border-2 border-gray-300 sm:p-8 p-4 
        lg:min-w-[250px] sm:min-w-[220px] min-w-[120px] sm:min-h-[150px] min-h-[200px]
               rounded-md md:my-0 my-6 text-justify items-justify bg-pink-100' >
         <div className='border-1 border-gray-300 sm:p-8 p-4 
        lg:min-w-[full] sm:min-w-[full] min-w-[full] 
               rounded-md md:my-0 my-4 text-center items-center text-[30px]'>
          <span style={{textTransform: 'capitalize' , textAlign : 'center'}}
          classname="font-dmSans sm:text-[20px] text-[20px] font-bold py-1">
           Enquiry List</span>
        </div>
        <table  className='md:mr-10 shadow-md border-2 border-gray-300 sm:p-8 p-4 
        lg:min-w-[full] sm:min-w-[full] min-w-[full] sm:min-h-[full] min-h-[full]
               rounded-md md:my-0 my-6 text-justify items-justify'
        style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}>
            <tr  style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
            classname='bg-pink-500'>
                <td className='font-dmSans sm:text-[18px] text-[18px] py-1 text-left bg-pink-300' 
            style={{width:"250px"}}>Dated  </td> 
            <td className='font-dmSans sm:text-[18px] text-[18px] 
             py-1 text-left bg-pink-300' style={{width:"200px"}}>Name </td>   
            <td className='font-dmSans sm:text-[18px] text-[18px] py-1 text-left bg-pink-300'
             style={{width:"250px"}}>Business Type  </td>
             
            <td className='font-dmSans sm:text-[18px] text-[18px] py-1 text-left bg-pink-300' 
            style={{width:"150px"}}>Email  </td>
            <td className='font-dmSans sm:text-[18px] text-[18px] py-1 text-left bg-pink-300' 
            style={{width:"150px"}}>Phone  </td>
            <td className='font-dmSans sm:text-[18px] text-[18px] py-1 text-left bg-pink-300' 
            style={{width:"500px"}}>Description  </td>
            
            </tr>
            {requestlist != '' ? 
                 requestlist.map((requestlist)=>
                 {
                  return(
                         <tr  style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}>
                          <td style={{width:"250px",textTransform:'capitalize'}}>{requestlist.created_at}</td>
                          <td style={{width:"200px",textTransform:'capitalize'}}>{requestlist.name}</td>
                          <td style={{width:"150px"}}>{requestlist.business_type}</td>
                          <td style={{width:"250px",textTransform:'capitalize'}}>{requestlist.email}</td>
                          
                          <td style={{width:"150px",}}>{requestlist.phone}</td>
                          
                          <td style={{width:"500px"}}>{requestlist.description}</td>
                          
                        </tr>
                  )
                  
                 }
                 )
                 :<div>
                  <span style={{textTransform: 'capitalize' , textAlign : 'center'}}
                   classname="font-dmSans sm:text-[10px] text-[10px] font-bold py-1">
                    No Data Available
            </span></div> }
            </table>
      </div>
                
    )
}
export default ContactQuery;