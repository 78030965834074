import React, { useEffect, useState } from 'react'
import CardModal from './CardModal'
import { TbEdit } from "react-icons/tb";
import { BsTrash } from "react-icons/bs";
import { BsInstagram, BsTwitter, BsFacebook,BsYoutube } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supaBase';
import e from 'cors';

const InfluencerCard = ({ influencer,varNumb }) => {

  const navigate = useNavigate();

  const[isOpen, setIsOpen] = useState(false);
  const[deleteModal, setDeleteModal] = useState(false);
 
  let formattedDate ;
  const[showupdate, setshowupdate] = useState(false);

  useEffect(() => {

    const fetchInfluencers = async () => {

      if (influencer.updated_on !== '') {
        // Parse the date from the influencer object
        var date1 = new Date(influencer.updated_on);
        const today = new Date();
        formattedDate = formatTimestampToDate(influencer.updated_on);
        console.log("formattedDate=="+formattedDate)
        // Calculate the time difference in milliseconds
        const differenceInMilliseconds = today - date1;
      
        // Calculate the difference in days
        const differenceInDays = Math.floor(Number(differenceInMilliseconds) / (1000 * 60 * 60 * 24));
      
        // Now, differenceInDays contains the difference in days
        console.log(differenceInDays);

        if(Number(differenceInDays) > 14)
        {setshowupdate(true)}
      }
     };
 
     fetchInfluencers();
   }, []);
  
function formatTimestampToDate(timestampdate){
  console.log("timestamp-="+timestampdate)
  if(timestampdate){
  const date = new Date(timestampdate);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month is zero-based
  const year = date.getFullYear();

  // Ensure that day and month have leading zeros if needed
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Format the date as "dd/mm/yyyy"
  return `${formattedDay}/${formattedMonth}/${year}`;
}
else
 return ""
}

  async function handleDelete() {
    const { error } = await supabase
  .from('influencers')
  .delete()
  .eq('influencer_id', influencer.influencer_id).order('name', { ascending: false })

  if(!error) {
    window.location.reload()
  }
  } 

  return (
    <div className='flex flex-col p-2 bg-[#FFF7F1] rounded-md mb-2'>
    <div className='flex flex-col items-center font-poppins text-center gap-y-1 '>
        <p><b>{varNumb} .{influencer.name}</b></p>
        
        <p>{influencer.gender},  {influencer.age}</p>
        <p>{influencer.location} </p>
        <p>{influencer.niche}</p>

        <div className='flex flex-row mt-5'>
        <div className='flex flex-col border-r-2'>
        <a href={influencer.instagram_links} target='_blank'>
          <BsInstagram color='#d62976' size={24} className='mx-5' /></a>
        <p className='font-poppins text-sm mt-2'>{influencer.instagram_followers}</p>
        </div>

        <div className='flex flex-col border-r-2'>
        <a href={influencer.fb_links} target='_blank'>
        <BsFacebook color='#3b5998' size={24} className='mx-5'/>
        </a>
        <p className='font-poppins text-sm mt-2'>{influencer.fb_followers}</p>
       </div>

        <div className='flex flex-col'>
        <a href={influencer.twitter_links} target='_blank'>
        <BsTwitter color='#00acee' size={24} className='mx-5'/>
        </a>  
        <p className='font-poppins text-sm mt-2'></p>
        </div>
        <div className='flex flex-col border-r-2'>
         {influencer.youtube_link ? 
        <a href={influencer.youtube_link} target='_blank'>
         <BsYoutube color='#3b5998' size={24} className='mx-5'/>
         </a>
        :  <BsYoutube color='#3b5998' size={24} className='mx-5'/>}
        <p className='font-poppins text-sm mt-2'>{influencer.youtube_followers}</p>

        </div>
        </div>
        <div className='flex flex-row mt-5'>
        <button
        onClick={() => {
        setIsOpen(true)
        setDeleteModal(true)
        }
        }
        >
        <BsTrash color='red' size={24} className='mx-3'/>
        </button>

       <button
        onClick={() => {
          navigate('/dashboardadmin/edit',
          {
            state: {
              influencer: influencer,
            },
          }
          )
        }}
        >
        <TbEdit color='black' size={24} className='mx-3'/>
      </button>
        </div>
        

      <button className='pt-4 font-poppins text-md text-[#D10269] '
      onClick={() => {
        setIsOpen(true)
        setDeleteModal(false)
        }}
      >
      View Details</button>
      </div>
      
      <p>
       
       {influencer.youtube_followers>0? 
         (showupdate === true?
           <a><button className='bg-[#D10269] rounded-md mb-2 text-white'
     onClick={() => {
       navigate('/dashboardadmin/scheduledata',{
         state: {
       ytLink: influencer.youtube_link,
       infID : influencer.influencer_id
     }
       })
     }}
     >
     YT-UPDATE
   </button></a>
             :<div color='red'><i>Updated on:{formattedDate}</i></div>)
     
   :""}
     </p>
    { !deleteModal && 
      <CardModal open={isOpen} onCLose={() => {setIsOpen(false)}} showClose={true}>
    
    <table className="font-normal font-poppins sm:text-[13px] text-[13px] border-collapse border border-gray-300">
      <tbody>
        <tr>
          <th  className="border border-gray-300 p-2" > Field</th>
          <th  className="border border-gray-300 p-2">Value</th>
        </tr>
        <tr>
          <td  className="border border-gray-300 p-2">Name</td>
          <td  className="border border-gray-300 p-2">{influencer.name}</td>
        </tr>
        <tr>
          <td  className="border border-gray-300 p-2">Location</td>
          <td  className="border border-gray-300 p-2">{influencer.location}</td>
        </tr>
        <tr>
          <td  className="border border-gray-300 p-2">Gender</td>
          <td  className="border border-gray-300 p-2">{influencer.gender}</td>
        </tr>
      </tbody>
    </table>
    </CardModal>
    }

    { deleteModal && 
      <CardModal open={isOpen} onCLose={() => {setIsOpen(false)}} showClose={true}>
      <p>Are you sure you want to delete this influencer</p>
      <button
      className='bg-[#D10269] p-2 px-10 mt-4
        rounded-sm  text-white sm:text-[15px] text-[12px]'
      onClick={handleDelete}
      >
        delete
      </button>
    </CardModal>
    }
  </div>
  )
}

export default InfluencerCard