import React, { useState } from 'react'

import { FaSearch } from 'react-icons/fa';
import { CartState } from '../context/Context';

import InfluencerCart from './InfluencerCart';
import CardModal from './CardModal';
import Filters from './Filters';
import { HiShoppingCart } from 'react-icons/hi';
import { MdFilterListAlt } from 'react-icons/md';

const InfluencerHeader = ({ campaignId, filters,deliverable }) => {
  const [ShowCart, setShowCart] = useState(false);

  const { filterState: { genderFilter, costFilter, searchQuery }, 
            filterDispatch, state: { cart }, dispatch } = CartState();
  console.log(genderFilter, costFilter, searchQuery)
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  const cityOptions = [
    { value: 'Agra', label: 'Agra' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Ahmedabad', label: 'Ahmedabad' },
    { value: 'Amritsar ', label: 'Amritsar ' },
    { value: 'Andhra Pradesh ', label: 'Andhra Pradesh ' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Ajmer', label: 'Ajmer' },
    { value: 'Allahabad', label: 'Allahabad' },
    { value: 'Ambala', label: 'Ambala' },
    { value: 'Aurangabad', label: 'Aurangabad' },
    { value: 'Bareilly', label: 'Bareilly' },
    { value: 'Bilaspur H.P', label: 'Bilaspur H.P' },
    { value: 'Bangalore', label: 'Bangalore' },
    { value: 'Belgaum', label: 'Belgaum' },
    { value: 'Bhopal', label: 'Bhopal' },
    { value: 'Bilaspur', label: 'Bilaspur' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Chennai', label: 'Chennai' },
    { value: 'Chittorgarh', label: 'Chittorgarh' },
    { value: 'Chattisgarh', label: 'Chattisgarh' },
    { value: 'Coimbatore', label: 'Coimbatore' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Daman', label: 'Daman' },
    { value: 'Darjeeling', label: 'Darjeeling' },
    { value: 'Dehradun', label: 'Dehradun' },
    { value: 'Delhi', label: 'Delhi' },
    
    { value: 'Dudhi UP', label: 'Dudhi UP' },
    { value: 'Ernakulam', label: 'Ernakulam' },
    { value: 'Faizabad', label: 'Faizabad' },
    { value: 'Faridabad', label: 'Faridabad' },
    { value: 'Firozabad', label: 'Firozabad' },
    { value: 'Gandhinagar', label: 'Gandhinagar' },
    { value: 'Ghaziabad', label: 'Ghaziabad' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gurdaspur', label: 'Gurdaspur' },
    { value: 'Gurgaon', label: 'Gurgaon' },
    { value: 'Gujarat ', label: 'Gurgaon' },
    { value: 'Gwalior', label: 'Gwalior' },
    { value: 'Haridwar', label: 'Haridwar' },
    { value: 'Hooghly', label: 'Hooghly' },
    { value: 'Howrah', label: 'Howrah' },
    { value: 'Hyderabad', label: 'Hyderabad' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Imphal', label: 'Imphal' },
    { value: 'Indore', label: 'Indore' },
    { value: 'Jabalpur', label: 'Jabalpur' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Jaipur', label: 'Jaipur' },
    { value: 'Jalandhar', label: 'Jalandhar' },
    { value: 'Jaisalmer', label: 'Jaisalmer' },
    { value: 'Jammu', label: 'Jammu' },
    { value: 'Jammu And Kashmir ', label: 'Jammu And Kashmir ' },
    { value: 'Jhansi', label: 'Jhansi' },
    { value: 'Jamnagar', label: 'Jamnagar' },
    { value: 'Jodhpur', label: 'Jodhpur' },
    { value: 'Karnataka ', label: 'Karnataka' },
    { value: 'Kashmir ', label: 'Kashmir' },
    { value: 'Kerala ', label: 'Kerala' },
    { value: 'Kushinagar ', label: 'Kushinagar' },
    { value: 'Kanpur', label: 'Kanpur' },
    { value: 'Kapurthala', label: 'Kapurthala' },
    { value: 'Karnal', label: 'Karnal' },
    { value: 'Kolkata', label: 'Kolkata' },
    { value: 'Leh', label: 'Leh' },
    { value: 'Lucknow', label: 'Lucknow' },
    { value: 'Ludhiana', label: 'Ludhiana' },
    { value: 'Madurai', label: 'Madurai' },
    { value: 'Mumbai ', label: 'Mumbai ' },
    { value: 'Maharashtra ', label: 'Maharashtra' },
    { value: 'Muzaffarpur', label: 'Muzaffarpur' },
    { value: 'Maharastra ', label: 'Maharastra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Mysore', label: 'Mysore' },
    { value: 'Nagpur', label: 'Nagpur' },
    { value: 'Nainital', label: 'Nainital' },
    { value: 'Nashik', label: 'Nashik' },
    { value: 'New Delhi', label: 'New Delhi' },
    { value: 'Noida', label: 'Noida' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Panipat', label: 'Panipat' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Patiala', label: 'Patiala' },
    { value: 'Patna', label: 'Patna' },
    { value: 'Pondicherry', label: 'Pondicherry' },
    { value: 'Pune', label: 'Pune' },
    { value: 'Raipur', label: 'Raipur' },
    { value: 'Rajkot', label: 'Rajkot' },
    { value: 'Ranchi', label: 'Ranchi' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Saharanpur', label: 'Saharanpur' },
    { value: 'Shimla', label: 'Shimla' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Sonipat', label: 'Sonipat' },
    { value: 'Srinagar', label: 'Srinagar' },
    { value: 'Surat', label: 'Surat' },
    { value: 'Tawang', label: 'Tawang' },
    { value: 'Thane', label: 'Thane' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
    { value: 'Thrissur', label: 'Thrissur' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Udaipur', label: 'Udaipur' },
    { value: 'Udhampur', label: 'Udhampur' },
    { value: 'Ujjain', label: 'Ujjain' },
    { value: 'UAE', label: 'UAE' },
    { value: 'Uttarkashi', label: 'Uttarkashi' },
    { value: 'Vadodara', label: 'Vadodara' },
    { value: 'Varanasi', label: 'Varanasi' },
    { value: 'Visakhapatnam', label: 'Visakhapatnam' },
    { value: 'Warangal', label: 'Warangal' },
    { value: 'Yavatmal', label: 'Yavatmal' },
    { value: 'Uttrakhand', label: 'Uttrakhand' },
    { value: 'West Bengal', label: 'West Bengal' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Himachal pradesh', label: 'Himachal pradesh' },
    { value: 'Other', label: 'Other' },
    ]

  const genderOptions = [
    { value: 'Male', label: 'male' },
    { value: 'Female', label: 'female' },
    { value: 'Other', label: 'other' },
  ]

  return (
    <div className='flex absolute top-0 flex-col bg-white w-full'>
        <div className='flex md:flex-row flex-col items-center text-[18px] pb-10 justify-between'>
        
        <div className='flex flex-row items-center'>
        <FaSearch />
        <input
        className='mx-4 border-b-2 border-black border-opacity-40 md:w-[500px] sm:w-[400px] w-[200px] h-[50px] '
        placeholder='influencer name'
        onChange={(e) => {
          filterDispatch({
            type : "BY_SEARCH",
            payload : e.target.value,
          })
        }}
        >
        </input>
        </div>
  
        <div className='flex flex-row gap-x-10 items-center justify-center md:mt-0 mt-10 text-lg font-poppins'>
        <button onClick={() => {
            setIsOpen(true)
            setShowCart(true)
            }}
            className='flex flex-row items-center'
            ><HiShoppingCart size={36}/> Cart </button>
          <button onClick={() => {
            setIsOpen(true)
            setShowCart(false)
            }}
            className='flex flex-row items-center'
            ><MdFilterListAlt size={36} /> Filters </button>  

        </div>
        
        </div>
        
       
        <div className="flex flex-col bg-[#FFF7F1]  text-[20px]"
         style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
         <span style={{ textAlign: "center" }}>Campaign Name : {filters.campaign_name}</span></div> 
          {
            ShowCart && <CardModal open={isOpen} onCLose={() => {setIsOpen(false)}} showClose={true}>
          <InfluencerCart id={campaignId} deliverable={deliverable}/>
          </CardModal>
          }

          {
            !ShowCart && <CardModal open={isOpen} onCLose={() => {setIsOpen(false)}} showClose={true}>
            <Filters  campaignId={campaignId} filters={filters}/>
          </CardModal>
          }
  
    </div>
  )
}

export default InfluencerHeader