import React, { useState, useEffect } from "react";
import { supabase } from "../utils/supaBase";
import { useNavigate } from "react-router-dom";
import CardModal from "./CardModal";

import DeleteCard from "./DeleteCard";
import DeleteModalUI from "./DeleteModalUI";
import axios from 'axios';
import { AiOutlineDownload } from "react-icons/ai";

const CompleteCampaign = ({ campaign, active }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [verifiedModal, setVerifiedModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [reportList, setreportList] = useState(null);
  const [fileoriginalName, setfileoriginalName] = useState(null);
  const [nameToDownload, setnameToDownload] = useState(null);
  const [error, setError] = useState();
  const [influencerList, setInfluencerList] = useState(null);

  useEffect(() => {
    async function fetchReport() {//alert(campaign.id)
      const { data, error } = await supabase
        .from("reports")
        .select("*")
        .eq("campaign_id", campaign.id);


      if (error) {
        setError("Could not fetch data");
        setreportList(null);
      } else {
        setreportList(data);

        setError(null);
        if (data[0] !== []) {
         // console.log("fileoriginalName==" + fileoriginalName + "=nameToDownload==" + nameToDownload)
        }

      }

    }
    fetchReport();
    fetchInfluencer();
  }, [])

  async function fetchInfluencer()
  {
    const { data, error } = await supabase
    .from("campaign_influencer_list")
    .select("*")
    .eq("campaign_id", campaign.id);

  if (error) {
    setError("Could not fetch data");
    setInfluencerList(null);
  } else {
    setInfluencerList(data);
    setError(null);
    
  }
  }

  async function handleDelete() {
    const { error } = await supabase
      .from("campaign")
      .delete()
      .eq("id", campaign.id);

    if (error) {
      alert(error.error_description || error.message);
    } else {
      if (error) {
        console.log("Error" + error.message);
      } else {
        window.location.reload();
      }
    }
  }




  const downloadfile = () => {

    try {
      axios.get('https://brandlytical.in/downloadfile?filename=' + fileoriginalName, {

        responseType: 'blob', // Tell Axios to treat the response as binary data (blob)

      })
        .then((response) => {
          // Create a URL for the blob
          console.log(response + "--=-=-response")
          const url = window.URL.createObjectURL(new Blob([response.data]));
          // Create a temporary anchor element
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nameToDownload); // Replace 'filename.pdf' with the desired filename for the download
          // Append the anchor to the body and click it to trigger the download
          document.body.appendChild(link);
          link.click();
          // Remove the temporary anchor
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          console.error('Error fetching the file:', error.description);
        });

    }
    catch (err) { console.log("==-=-=-errr=-=-=-" + err.description) }

  }
  return (
    <div
      className={`md:mr-10 ${active ? "bg-[#FEE1BA]" : " bg-[#FD3599] bg-opacity-20"
        }
      sm:p-8 p-4 lg:min-w-[280px] md:min-w-[280px] sm:min-w-[100px]  xxs:items-center 
      lg:min-h-[300px] md:min-h-[300px] sm:min-h-[320px] rounded-xl md:my-0 my-6 py-10`}
    >
      <p className="font-bold font-poppins sm:text-[15px] text-[12px] py-1 text-left xxs:ml-[415px] sm:ml-[120px] md:ml-[10px] lg:ml-[10px] xl:ml-[10px]">
        Campaign Name : {campaign.campaign_name ? campaign.campaign_name : ""}
      </p>

      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left xxs:ml-[415px] sm:ml-[120px] md:ml-[10px] lg:ml-[10px] xl:ml-[10px]">
        Campaign Type :{" "}
        {campaign.campaign_type ? campaign.campaign_type : "empty"}
      </p>
      <p
        className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left trans xxs:ml-[415px] sm:ml-[120px] md:ml-[10px] lg:ml-[10px] xl:ml-[10px]"
        style={{ textTransform: "capitalize" }}
      >
        Platform : {campaign.platform ? campaign.platform : "empty"}
      </p>
      <p
        className="font-normal font-[DM Sans] sm:text-[15px] text-[12px] py-1 text-left  flex flex-wrap xxs:ml-[415px] sm:ml-[120px] md:ml-[10px] lg:ml-[10px] xl:ml-[10px]"
        style={{ textTransform: "capitalize" }}
      >
        Deliverable :
        {campaign.deliverable
          ? campaign.deliverable.map((item) => {
            return (
              <p className="font-normal font-[DM Sans] sm:text-[15px] text-[12px] ">
                {`${item},`}{" "}
              </p>
            );
          })
          : "empty"}
      </p>

      <div className="flex flex-row  text-left xxs:ml-[415px] sm:ml-[120px] md:ml-[10px] lg:ml-[10px] xl:ml-[10px]">
        {campaign.completed ? (
          campaign.verified && !(campaign.finished) ? (
            <button
              className="mt-4 bg-[#409b2e] p-2 px- rounded-lg mr-5 text-white sm:text-[15px] text-[12px]"
              onClick={() => {
                setIsOpen(true);
                setVerifiedModal(true);
              }}
            >
              Verified
            </button>
          ) : (
            campaign.finished ? (
              <div>
                <button className="mt-4 bg-[#bc1e1e] p-2 px-4 rounded-lg mr-5 text-white sm:text-[14px] text-[11px]">
                  Completed
                </button>
                <button
                  className="pt-4 text-[#D10269] flex flex-row items-center"
                  onClick={() => {
                    downloadfile()
                  }}
                >
                  <span>   <AiOutlineDownload size={24} /> Download Report</span> </button>
              </div>
            ) : (<button className="mt-4 bg-[#bc1e1e] p-2 px-4 rounded-lg mr-5 text-white sm:text-[14px] text-[11px]">
              Pending Verification
            </button>)

          )
        ) : (
          <div>
            <button
              className="mt-4 bg-[#D10269] p-2 px-4 rounded-lg mr-5 text-white sm:text-[14px] text-[11px] "
              onClick={() => {
                navigate("/dashboard/selectinfluencers", {
                  state: {
                    campaignId: campaign.id,
                  },
                });
              }}
            >
              Click here to Complete
            </button>
            <button
              className="pt-4 font-poppins text-md text-[#D10269] xxs:ml-[415px] sm:ml-[120px] md:ml-[10px] lg:ml-[10px] xl:ml-[10px]  "
              onClick={() => {
                setIsOpen(true);
                setShowConfirmation(true);
              }}
            >
              Delete
            </button>

          </div>
        )}
      </div>



      {showConfirmation && (
        <DeleteCard
          open={isOpen}
          onCLose={() => {
            setIsOpen(false);

          }}
          showClose={true}
        >
          <DeleteModalUI
            title={"ARE YOU SURE YOU WANT TO DELETE THIS CANT BE UNDONE ?"}
            handleApprove={handleDelete}
          />
        </DeleteCard>
      )}

      <button
        className="pt-4 font-poppins text-md text-[#D10269] xxs:ml-[415px] sm:ml-[120px] md:ml-[10px] lg:ml-[10px] xl:ml-[10px]  "
        onClick={() => {
          setIsOpen(true);
          setViewDetails(true);
        }}
      >
        View Details
      </button>

      {viewDetails && (
        <CardModal
          open={isOpen}
          onCLose={() => {
            setIsOpen(false);
            setViewDetails(false);
          }}
          showClose={true}
        >
          <div className="h-[400px] sm:w-full w-[200px] overflow-y-scroll">
            <table className="font-normal font-poppins sm:text-[13px] text-[13px] border-collapse border border-gray-300">
              <tbody>
                <tr>
                  <th className="border border-gray-300 p-2">Field</th>
                  <th className="border border-gray-300 p-2">Value</th>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Campaign Type:</td>
                  <td className="border border-gray-300 p-2">
                    {campaign.campaign_type ? campaign.campaign_type : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Platform:</td>
                  <td className="border border-gray-300 p-2">
                    {campaign.platform ? campaign.platform : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Deliverable:</td>
                  <td className="border border-gray-300 p-2">
                    {campaign.deliverable ? (
                      campaign.deliverable.map((item, index) => (
                        <span key={index}>{item}, </span>
                      ))
                    ) : (
                      <span>empty</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Influencers:</td>
                  <td className="border border-gray-300 p-2">
                  {influencerList? influencerList.map((item) =>
                  {
                   return <>{item.name?item.name+" " :item.influencer_list+" "} : {item.description}<br/></>
                   
                  })
                  :"Empty"
                }
{/*
                    {campaign.influencers ? (
                      campaign.influencers.map((item, index) => (
                        <span key={index}>{item}, </span>
                      ))
                    ) : (
                      <span>empty</span>
                    )}*/}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Start Date:</td>
                  <td className="border border-gray-300 p-2">
                    {campaign.startdate ? campaign.startdate : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">End Date:</td>
                  <td className="border border-gray-300 p-2">
                    {campaign.enddate ? campaign.enddate : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Promotion:</td>
                  <td className="border border-gray-300 p-2">
                    {campaign.promotion ? campaign.promotion : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">
                    Influencer Gender Preferred:
                  </td>
                  <td className="border border-gray-300 p-2">
                    {campaign.gender ? (
                      campaign.gender.map((item, index) => (
                        <span key={index}>{item}, </span>
                      ))
                    ) : (
                      <span>empty</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">
                    Campaign Budget (rs.):
                  </td>
                  <td className="border border-gray-300 p-2">
                    {campaign.budgetlow ? campaign.budgetlow : "empty"} -{" "}
                    {campaign.budgethigh ? campaign.budgethigh : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">
                    Influencer Follower Range:
                  </td>
                  <td className="border border-gray-300 p-2">
                    {campaign.followers[0] ? campaign.followers[0] : "empty"} -{" "}
                    {campaign.followers[1] ? campaign.followers[1] : "empty"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardModal>
      )}

      {verifiedModal && (
        <CardModal
          open={isOpen}
          onCLose={() => {
            setIsOpen(false);
            setVerifiedModal(false);
          }}
          showClose={true}
        >
          <p>
            Your campaign has been verified the team will contact you shortly!
          </p>
        </CardModal>
      )}
    </div>
  );
};

export default CompleteCampaign;
