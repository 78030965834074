import React ,{useState,useEffect}from "react";
import { supabase } from '../utils/supaBase'
import { useNavigate } from 'react-router-dom';

import plus from '../assets/plus.svg'
import AddSongDetails from '../components/AddSongDetails';
import CardModal from './CardModal'
import ApproveModalUI from './ApproveModalUI'

const IntegrationStatus = ({ campaign, active })  =>
{
    const[isOpen, setIsOpen] = useState(false);
    const [viewDetails, setViewDetails] = useState(false)
    const [viewApprove, setViewApprove] = useState();
    const navigate = useNavigate();

    
      async function handleDecline() {
            
        const { error } = await supabase
        .from('integration_details')
        .delete()
        .eq('campaign_id', campaign.campaign_id)
    
        if (error) {
            alert(error.error_description || error.message)
          } else {
            window.location.reload(true)
          }
      }
    
      return (

    <div >
   
   <div className='flex flex-col pb-10 sm:min-w-[200px] min-w-[200px] 
       bg-black bg-opacity-10 p-5 sm:min-w-[290px] min-w-[200px]  
      rounded-md md:my-0 my-6 relative text-left text-black font-bold' 
   style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}>
    
        {campaign && campaign.integration_type == 'SONG_INTEGRATION' ? 
        <div>
        <p style={{textAlign:"left"}}>Title : {campaign.title}</p>
         
          <p style={{textAlign:"left"}}>Singer : {campaign.artist}</p>
          <p style={{textAlign:"left"}}>Artist Involved : {campaign.artist_involved}</p>
          <p style={{textAlign:"left"}}>Date of Shoot : {campaign.shoot_date}</p>
          <p style={{textAlign:"left"}}>Date of Closing : {campaign.closing_date}</p>
          <p style={{textAlign:"left"}}>Deleverables: {campaign.deliverables}</p>
          <p style={{textAlign:"left"}}>Budget Involved : <br/>₹{campaign.low_budget} -to- ₹{campaign.high_budget}</p>
          <div className='flex flex-row justify-between '>
          <button onClick={() => {
        navigate('../updateintegration',{
          state : {
            campaignId : campaign.campaign_id
        }
        })
        }} 
        className='mt-4 bg-[#D10269] p-1   px-6 rounded-sm text-white'>Update Details</button>
        <button onClick={() => {
        setIsOpen(true)
        setViewDetails(false)
        setViewApprove(false)
        }}
        className='pt-4 text-[#D10269] font-bold'>Delete</button>
         
          </div>
        </div>
        :
        <div>
           <p style={{textAlign:"left"}}><h1>Event : {campaign.title}</h1></p>
          <p style={{textAlign:"left"}}>Event Description : {campaign.description}</p>
          <div className='flex flex-row justify-between '>
          <button onClick={() => {
        navigate('../updatesponsorship',{

          state : {
            campaignId : campaign.campaign_id
        }
        })
        }} 
        className='mt-4 bg-[#D10269] p-1   px-6 rounded-sm text-white'>Update Details</button>
        <button onClick={() => {
        setIsOpen(true)
        setViewDetails(false)
        setViewApprove(false)
        }}
        className='pt-4 text-[#D10269] font-bold'>Delete</button>
         
          </div>
        </div>
        }
          <p>
            <button className='pt-1 font-normal text-md text-[15px]
             md:px-5 px-5 bg-[#b2b3ae]  mt-5 text-center rounded-md font-bold'
            onClick={() => {navigate('../integrationrequestlist',{
              state : {
                campaignId : campaign.campaign_id
            }
            })}
              } >
              View Intrested Brand/Agency </button>
            </p>
            
   
            </div>
    <CardModal open={isOpen} onCLose={() => {setIsOpen(false)}} showClose={true}>
      <ApproveModalUI 
        title={'ARE YOU SURE YOU WANT TO DELETE THE DETAILS?'}
        subtitle={'If you choose to delete, the all the details will delete ....'}
        handleApprove={handleDecline}
      />
      </CardModal>
   </div>
  )
}
export default IntegrationStatus;