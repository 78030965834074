import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../utils/supaBase";
import FinishedCampaignList from "./FinishedCampaignList";

const CampaignCompleteList = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    async function fetchCampaigns() {
      const { data, error } = await supabase
        .from("campaign")
        .select("*")
        .eq("finished", true)
        .neq("campaign_type", "SONG_INTEGRATION") //otherwise error of map rendering occurred
        .neq("campaign_type", "SPONSORSHIP")
        .order("created_at", { ascending: false })
        // .orderBy('created_at')
        .limit(2);

      if (error) {
        setError("Could not fetch data");
        setCampaigns([]);
      } else {
        setCampaigns(data);
        setError(null);
        console.log(data);
      }
    }


    fetchCampaigns();

  }, []);

  return (
    <div className="flex flex-col">
      <div className="">
        <div >
          <div className="flex flex-row justify-between text-center">
            <h1 className="font-poppins sm:text-xl text-md">
              <u> Finished Campaigns </u>
            </h1>
            <h1 className='md:pr-20'>
              <Link to="/dashboardadmin/finishedcampaign" className='font-poppins sm:text-xl text-md text-red-400 '>&nbsp;View All</Link></h1>
          </div>

          <div
            className="flex md:flex-row flex-col 
                lg:w-[1170px] md:w-[950px] sm:min-h-[100px] min-h-[120px]  justify-start py-5"
          >
            {campaigns &&
              campaigns.map((campaign) => (
                <FinishedCampaignList
                  campaign={campaign}
                  key={campaign.id}
                  finished={true}
                />
              ))}

            {!campaigns && <p>No data available</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCompleteList;
