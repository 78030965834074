import React, { useState, useEffect } from 'react'
import { supabase } from '../utils/supaBase';
import ApproveCampaign from '../components/ApproveCampaign';
import ApproveCard from '../components/ApproveCard';
import { Link, useNavigate } from 'react-router-dom';
import CampaignCompleteList from '../components/CampaignCompleteList';


const AdminDashboardHome = () => {

  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [error, setError] = useState();
  const [profiles, setProfiles] = useState(null);
  const [finishedCampaigns, setFinishedCampaigns] = useState([])
  const [agencyProfiles, setAgencyProfiles] = useState(null);

  useEffect(() => {
    async function fetchCampaigns() {
      const { data, error } = await supabase
        .from('campaign')
        .select('*')
        .eq('verified', true)
        //.neq('status', "REJECT")
        .neq('campaign_type', 'SONG_INTEGRATION')//otherwise error of map rendering occurred
        .neq('campaign_type', 'SPONSORSHIP')
        .order('created_at', { ascending: false })
        //.orderBy('created_at')
        .limit(2);

      if (error) {
        setError('Could not fetch data');
        setCampaigns([]);
      } else {
        setCampaigns(data);
        setError(null);
        console.log(data);
      }
    }
    async function fetchFinishedCampaigns() {
      const { data, error } = await supabase
        .from('campaign')
        .select('*')
        .eq('verified', true)
        .limit(2);

      if (error) {
        setError('Could not fetch data');
        setFinishedCampaigns([]);
      } else {
        setFinishedCampaigns(data);
        setError(null);
        console.log(data);
      }
    }
    async function fetchProfiles() {

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('verified', false)
        .eq('type', 'brand')
        .limit(3);


      if (error) {
        setError('Could not fetch data')
        setProfiles(null)
      } else {
        setProfiles(data)
        setError(null)
        console.log(data)
      }
    }

    async function fetchAgencyProfiles() {

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('verified', false)
        .eq('type', 'agency')
        .limit(3);

      if (error) {
        setError('Could not fetch data')
        setAgencyProfiles(null)
      } else {
        setAgencyProfiles(data)
        setError(null)
        console.log(data)
      }
    }

    fetchFinishedCampaigns();
    fetchCampaigns();
    fetchProfiles();
    fetchAgencyProfiles();
  }, []);

  return (
    <div className='flex flex-col'>
      <div className=''>
        <div >
          <div className='flex flex-row justify-between text-center'>
            <h1 className='font-poppins sm:text-xl text-md'><u>Live Campaigns </u></h1>
            <h1 className='md:pr-20'>
              <Link to="/dashboardadmin/approvedcampaign" className='font-poppins sm:text-xl text-md text-red-400 '>View All</Link></h1>
          </div>
          
          <div className='flex md:flex-row flex-col 
            lg:w-[1100px] md:w-[950px] md:max-h-[315px] max-h-[2000px]  justify-start py-5'>

            {campaigns && campaigns.map((campaign) => (
              <ApproveCampaign campaign={campaign} key={campaign.id} approved={true} />
            ))}

            {!campaigns && <p>loading</p>}
          </div>
        </div>

        <div
          className="flex md:flex-row flex-col 
            lg:w-[1100px] md:w-[950px] md:max-h-[315px] max-h-[2000px]   justify-start py-5"
        >
          <CampaignCompleteList />
        </div>
      </div>
    </div>
  )
}

export default AdminDashboardHome

