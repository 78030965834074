import React from "react";
import c1 from "../assets/c1.svg";
import c2 from "../assets/c2.svg";
import styles from "../styles";
import Nav from "../components/Nav";
import navLogo2 from "../assets/navlogo2.png";
import B2 from "../assets/B2.png";
import faithandpatience from '../assets/CaseStudies/faithandpatience.png'
//import fitshot from '../assets/CaseStudies/fitshot.png';
import kfc from '../assets/CaseStudies/kfc.png'
import vlcc from '../assets/CaseStudies/vlcc.png'
//import toothsi from '../assets/CaseStudies/toothsi.png'
import Footer from "../components/Footer";

const CaseStudyLinks = () => {
  return (
    <div className=" w-full overflow-hidden relative bg-white">
      <div className={"xl:max-w-[800px] w-full"}>
        <div
          className="md:absolute
           block  w-full bg-white"
        ></div>
        <Nav />

        <div className="flex md:h-[130vh] md:flex-row flex-col sm:flex-row flex-col items-center w-full relative">
          <div className="absolute animate-[none] w-[250px] h-[40px] left-[6%] top-[1%]  ">
            <h1 class="flex-1 lg:text-[30px] sm:text-[24px] text-[20px] text-black text-left pt-2">
              Case Studies
            </h1>
            <p class="font-[400]  font-[DM sans] text-left text-black sm:text-[15px] mt-2 sm:mt-1 text-[10px] ">
              How brands are establishing <br />
              a name in the market using <br />
              influencer marketing
            </p>
          </div>
          <div className="grid grid-cols-3  mx-auto mt-[130px] gap-x-1 gap-y-3 w-[100%] sm:grid-cols-3 sm:w-[50%] lg:mt-[180px] lg:w-[40%]  lg:gap-y-1" >
            <div>
              
              <img src={faithandpatience} alt="Img1" />
              
              <p className=" text-sm text-black font-bold mt-4">
                Faith & Patience
              </p>
            </div>
            {/* <div>
             
              <img src={fitshot} alt="Img2" />
              
              <p className=" text-sm text-black font-bold mt-4">Fitshot</p>
            </div> */}
            <div>
           
              <img src={kfc} alt="Img3" />
             
              <p className=" text-sm text-black font-bold mt-4">KFC</p>
            </div>
            <div>
           
              <img src={vlcc} alt="Img4" />
             
              <p className=" text-sm text-black font-bold mt-4">VLCC</p>
            </div>
            {/* <div>
          
              <img src={toothsi} alt="Img5" />
              
              <p className="text-sm text-black font-bold mt-4">Toothsi</p>
            </div> */}
          </div>
          
        </div>

        <div id="footer" className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default CaseStudyLinks;

