import React, { useEffect } from 'react'
import { supabase } from '../utils/supaBase'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import IntegrationCard from '../components/IntegrationCard';

const SponsorshipDealsUser = () => {

    const navigate = useNavigate();
    const [integrationdetails, setCampaigns] = useState([]);
    const [error, setError] = useState();

    useEffect(() =>{
      async function fetchCampaigns() {
        const { data, error } = await supabase
          .from('integration_details')
          .select('*')
          .eq('status', "ACTIVE")
          .eq('integration_type','SPONSORSHIP');
           console.log("integration details"+data);
        if (error) {
          setError('Could not fetch data');
          setCampaigns([]);
        } else {
          setCampaigns(data);
          setError(null);
          console.log(data);
        }
      }

    fetchCampaigns();
  
    }, [])
    

return(
    <div className='flex flex-col'>
                  <div className='flex flex-row justify-between text-center'>
                  <div className='flex md:flex-row flex-col 
            lg:w-[1100px] md:w-[950px] md:max-h-[315px] max-h-[2000px]  justify-start py-5'>
        
          {integrationdetails && integrationdetails.map((item)=>{

            <div>{integrationdetails.map.id}</div>
            return <IntegrationCard campaign={item} active={true}/>
          }
            )}
            </div>
                  </div>
    </div>
)

}
export default SponsorshipDealsUser;