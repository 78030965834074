import React, { useEffect, useState } from 'react';
import { useLocation,useNavigate } from "react-router-dom";
import { supabase } from '../utils/supaBase'
import { minRoiVal } from '../collection/constVariable'
import { maxRoiVal } from '../collection/constVariable'
const axios = require('axios');

const ChannelData = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const customUrl = location.state.ytLink;
  const infID = location.state.infID;
  let totalA = 0;
  let totalB = 0;
  let totSubs = 0;
  //console.log("=-=-minRoiVal]==-=-=-"+minRoiVal)
  //console.log("=-=-maxRoiVal]==-=-=-"+maxRoiVal)
  const [channelData, setChannelData] = useState(null);
  const [subscribercount, setsubscribercount] = useState(null);
  const [videocount, setvideocount] = useState(null);
  const [viewcount, setviewcount] = useState(null);
  const [engagementData, setEngagementData] = useState(null);
  const [loading, setLoading] = useState(true);
  let [engRate, setEngRate] = useState(0);
  let [minRoi, setminRoi] = useState(0);
  let [maxRoi, setMaxRoi] = useState(0);

  const [averageViews, setAverageViews] = useState(null);
  const [timeVal, setTimeVal] = useState();

  const API_KEY = 'AIzaSyBUSj0p0TD9vJyP7T9o8phyXHl9RjLRvI4';//generated on140823
 
  const username = customUrl.split('@')[1];
  //console.log(username)
  const extractChannelIdFromUrl = (url) => {
    const match = url.match(/youtube\.com\/@([A-Za-z0-9_-]+)/);
    return match ? match[1] : null;
  };

  const getChannelData = (channelId) => {
    const uName = extractChannelIdFromUrl(customUrl)
console.log("uName=-=-="+uName)

    const url = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&q=${username} &type=channel&key=${API_KEY}`
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();


      })
      .then((data) => {
        if (data.items && data.items.length > 0) {
          console.log(data.items.length+"--- data.items.length")
          console.log(data.items[0].snippet.channelTitle+"=-=1-=-=-="+data.items[0].snippet.customUrl+"=-="+data.items[0].snippet.title)
          console.log(data.items[1].snippet.channelTitle+"=-=2-=-=-=")
          console.log(data.items[2].snippet.channelTitle+"=-=-3=-=-=")
          console.log(data.items[3].snippet.channelTitle+"=-=-4=-=-=")
          console.log(data.items[4].snippet.channelTitle+"=-=-=5-=-=")
        {/*-------------------------------------------------- 
        axios.get(url)
        .then(response => {
          const channelItems = response.data.items;

          // Find the exact match based on the username
          const exactMatch = channelItems.find(item => item.snippet.channelTitle === username);

          if (exactMatch) {
            const channelId = exactMatch.id.channelId;
            console.log('Exact match found! Channel ID:', channelId);
          } else {
            console.log('No exact match found for the username:', username);
          }
        })
        .catch(error => {
          console.error('Error fetching channel data:', error.message);
        });*/} 
        {/*-------------------------------------------------- */}
       
          setChannelData(data.items[0]);

          const channelId = data.items[0].snippet.channelId;

          const url = `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics,contentDetails&id=${channelId}&key=${API_KEY}`;

          fetch(url)
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then((data1) => {
              if (data1.items && data1.items.length > 0) {

                setsubscribercount(data1.items[0].statistics.subscriberCount);
                setviewcount(data1.items[0].statistics.viewCount);
                setvideocount(data1.items[0].statistics.videoCount);

                totalB = data1.items[0].statistics.viewCount;
                //handleSubmit(infID)
                const uploadsPlaylistId = data1.items[0].contentDetails.relatedPlaylists.uploads;

                const playlistUrl = `https://www.googleapis.com/youtube/v3/playlistItems?part=contentDetails&playlistId=${uploadsPlaylistId}&maxResults=15&key=${API_KEY}`;

                fetch(playlistUrl)
                  .then((response) => response.json())
                  .then((playlistData) => {
                    if (playlistData.items && playlistData.items.length > 0) {
                      const videoIds = playlistData.items.map((item) => item.contentDetails.videoId);

                      getVideoStatistics(videoIds).then((videoStatistics) => {
                        // Calculate approximate engagement metrics
                        const totalLikes = videoStatistics.reduce((sum, stats) => sum + parseInt(stats.likeCount || 0), 0);
                        const totalComments = videoStatistics.reduce((sum, stats) => sum + parseInt(stats.commentCount || 0), 0);
                        const totalShares = videoStatistics.reduce((sum, stats) => sum + parseInt(stats.shareCount || 0), 0);

                        const totalViews = videoStatistics.reduce((sum, stats) => sum + parseInt(stats.viewCount || 0), 0);
                        const averageViews = Math.round(totalViews / videoIds.length);

                        setAverageViews(averageViews);

                        const approximateLikes = Math.round(totalLikes / videoIds.length);
                        const approximateComments = Math.round(totalComments / videoIds.length);
                        const approximateShares = Math.round(totalShares / videoIds.length);


                        totSubs = data1.items[0].statistics.subscriberCount;
                        totalA = approximateLikes + approximateComments + approximateShares;
                       

                        engRate = ((totalA / averageViews) * 100).toFixed(2);

                        console.log("engRate=" + engRate)
                        minRoi = (engRate * minRoiVal).toFixed(2);
                        maxRoi = (engRate * maxRoiVal).toFixed(2);
                        setEngRate(engRate)
                        setminRoi(minRoi)
                        setMaxRoi(maxRoi)

                        setEngagementData({
                          approximateLikes,
                          approximateComments,
                          approximateShares,
                          totalLikes,
                          totalComments,
                          totalShares,
                        });

                        // Update data in Supabase (you can call handleSubmit here)
                      });
                    }
                  })
              }
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            })

        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getVideoStatistics = (videoIds) => {
    const videoIdString = videoIds.join(',');
    const url = `https://youtube.googleapis.com/youtube/v3/videos?part=statistics&id=${videoIdString}&key=${API_KEY}`;

    return fetch(url)
      .then((response) => response.json())
      .then((data) => data.items.map((item) => item.statistics))
      .catch((error) => {
        console.error('Error fetching video statistics:', error);
        throw error;
      });
  };

  useEffect(() => {
    getChannelData(customUrl);
    getTimeVal();

  }, []);

  function getTimeVal() {
    const timestamp = (new Date()).toString();

    var dateTimeStamp = Date.now();
    var dateObject = new Date(dateTimeStamp);

    var yearFromTS = dateObject.getFullYear();
    var monthFromTS = dateObject.getMonth() + 1;
    var dateFromTS = dateObject.getDate();
    var dateFromHH = dateObject.getHours();
    var dateFromMIN = dateObject.getMinutes();
    var dateFromSS = dateObject.getSeconds();
    var dateFromTime = +dateFromHH + ":" + dateFromMIN + ":" + dateFromSS
    var dateformat = yearFromTS + "-" + monthFromTS + "-" + dateFromTS + " " + dateFromTime;


    setTimeVal(dateformat)
  }

  async function handleSubmit(infID) {

    const { error } = await supabase
      .from("influencers")
      .update({
        youtube_followers: subscribercount,
        yt_videos_count: videocount,
        yt_views: viewcount,
        yt_engrate: engRate,
        yt_min_roi: minRoi,
        yt_max_roi: maxRoi,
        yt_average_views: averageViews,
        updated_on: timeVal
      })
      .eq("influencer_id", infID);

    if (error) {
      alert(error.error_description || error.message);
    }
    else {
      alert("Data Saved Successfully")
      navigate('/dashboardadmin/influencers')
    }

  }

  return (
    <div>
      {loading ? (
        <p>Loading channel data...</p>
      ) : channelData ? (
        <>
          <h1 style={{ color: 'green' }}>{customUrl}</h1>
         <center><img src={channelData.snippet.thumbnails.default.url} alt="Channel Thumbnail" /></center> 
          {/*<p>Channel-Id: {channelData.snippet.channelId}</p>*/}
          <table className="md:mt-14 mt-28 max-h-screen overflow-x-scroll"  
        style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)", width: "100%" }}>
            <tr>
            <td style={{width:"25%"}}>Title:</td>
              <td style={{width:"75%"}}>{channelData.snippet.title}</td>
              </tr>
              <tr>
              <td style={{width:"25%"}}>Description: </td>
              <td style={{width:"75%"}}>{channelData.snippet.description}</td>
              </tr>
              <tr>
              <td>Subscriber Count: </td><td>{subscribercount}</td>
              </tr>
              <tr>
              <td>View Count:</td><td> {viewcount}</td>
            </tr>
            <tr>
            <td>
            {averageViews !== null && (
                 <>Average Views : {averageViews}</>
               )}
            </td>
           <td>
           Video Count: {videocount}
          
           </td>
            </tr>
            {engagementData && (
              <>
              <tr>
              <td colspan="4"> <span>Based on last 15 uploads</span></td>
              </tr>
              <tr>
              <td>Total Likes : {engagementData.totalLikes}</td>
              <td>Total Comments: {engagementData.totalComments}</td>
              </tr>
              <tr>
              <td>Total Shares : {engagementData.totalShares}</td>
            </tr>
              </>
           
      )}
      <tr><td>Engagement Rate : {engRate} %</td></tr>
      <tr><td style={{colspan:"4",alignItems:"center"}}><button className='bg-[#D10269] rounded-md mb-2 text-white' 
            onClick={() => { handleSubmit(infID) }}
          >
            Update Details
          </button></td></tr>
          </table>
          
         

         {/* {engagementData && (
            <>
              <span>Based on last 15 uploads</span>
              <p>Approximate Likes : {engagementData.approximateLikes}</p>
              <p>Approximate Comments: {engagementData.approximateComments}</p>
              <p>Approximate Shares : {engagementData.approximateShares}</p>

              <p>Total Likes : {engagementData.totalLikes}</p>
              <p>Total Comments: {engagementData.totalComments}</p>
              <p>Total Shares : {engagementData.totalShares}</p>

            </>
          )}
          <p>Engagement Rate : {engRate} : {minRoi} : {maxRoi}</p>
          <button className='bg-[#D10269] rounded-md mb-2 text-white'
            onClick={() => { handleSubmit(infID) }}
          >
            Update Details
          </button>*/}
        </>
      ) : (
        <p>No channel data available.</p>
      )}
    </div>
  );
};

export default ChannelData;
