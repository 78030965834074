import React, { useState, useEffect } from "react";
import { supabase } from "../utils/supaBase";
import { AiOutlineDownload } from "react-icons/ai";
import CardModal from "./CardModal";
import axios from 'axios'

const FinishedCampaignList = ({ campaign, finished }) => {
  
  const [isOpen, setIsOpen] = useState(false);
  const [showApproval, setShowApproval] = useState();
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState();

  const [influencerList, setInfluencerList] = useState(null);
  const [reportList, setreportList] = useState(null);
  const [fileoriginalName, setfileoriginalName] = useState(null);
  const [nameToDownload, setnameToDownload] = useState(null);
  
  useEffect(() => {
    async function fetchProfile() {
      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", campaign.uid);

      if (error) {
        setError("Could not fetch data");
        setProfile(null);
      } else {
        setProfile(data);
        setError(null);
        
      }
    }
    fetchInfluencer();
    fetchReport();
    fetchProfile();
  }, []);
  async function fetchInfluencer()
  {
    const { data, error } = await supabase
    .from("campaign_influencer_list")
    .select("*")
    .eq("campaign_id", campaign.id);

  if (error) {
    setError("Could not fetch data");
    setInfluencerList(null);
  } else {
    setInfluencerList(data);
    setError(null);
    
  }
  }
  async function fetchReport()
  {
    const { data, error } = await supabase
    .from("reports")
    .select("*")
    .eq("campaign_id", campaign.id);
    

  if (error) {
    setError("Could not fetch data");
    setreportList(null);
  } else {
    setreportList(data);
    
    setError(null);
    if(data !== "")
    {
      setfileoriginalName(data[0].filepath)
      setnameToDownload(data[0].filename)
      //nameToDownload = data[0].filename;
     //console.log("fileoriginalName=="+fileoriginalName+"=nameToDownload=="+nameToDownload)
    }
    
  }
  
}
  const downloadfile =()=>{
    
    try{
      axios.get('https://brandlytical.in/downloadfile?filename='+fileoriginalName, {
     
        responseType: 'blob', // Tell Axios to treat the response as binary data (blob)
        
      })
      .then((response) => {
        // Create a URL for the blob
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nameToDownload); // Replace 'filename.pdf' with the desired filename for the download
        // Append the anchor to the body and click it to trigger the download
        document.body.appendChild(link);
        link.click();
        // Remove the temporary anchor
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error.description);
      });

    }
    catch(err)
    {console.log("==-=-=-errr=-=-=-"+err.description)}
   
  }

  return (
    <div
    className="md:mr-10 shadow-md border-2 border-gray-300 sm:p-8 p-4 
    lg:min-w-[250px] sm:min-w-[220px] min-w-[295px] sm:min-h-[350px] min-h-[300px]
           rounded-md md:my-0 my-6 text-justify items-justify"
  >
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left">
        Campaign Name:&nbsp;
        {campaign.campaign_name ? campaign.campaign_name : "empty"}
      </p>
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left">
        Campaign request by : &nbsp;{profile && profile[0].company_name} 
       
      </p>
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left">
        Campaign Type :&nbsp;
        {campaign.campaign_type ? campaign.campaign_type : "empty"}
      </p>
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 flex flex-wrap text-left">
        Objective : &nbsp;
        {campaign.deliverable
          ? campaign.deliverable.map((item) => {
              return <>{`${item},`}</>;
            })
          : "empty"}
      </p>
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left">
        Start Date :&nbsp; {campaign.startdate ? campaign.startdate : "empty"}
      </p>
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left">
        End Date : &nbsp;{campaign.enddate ? campaign.enddate : "empty"}
      </p>
     <div className="pr-2 sm:text-[18px] text-[15px]">
        <div>
      {reportList && reportList.map((reportList)=>{
               return( <>
     <button
          className="pt-4 text-[#D10269] flex flex-row items-center"
          onClick={() => {
            downloadfile()
          }}
        >
             <span style={{display:"flex"}}>   <AiOutlineDownload size={24} /> Download Report</span> </button></>)
         }
         )}
      <button
          className="pt-4 text-[#D10269] flex flex-row items-center"
          onClick={() => {
            setIsOpen(true);
            setShowApproval(false);
          }}
        >
         <span className="pr-2 sm:text-[18px] text-[15px]">Status </span>
      </button>
      </div>
        </div>

      {!showApproval && (
        <CardModal
          open={isOpen}
          onCLose={() => {
            setIsOpen(false);
          }}
          showClose={true}
        >
          <div className="flex flex-col gap-y-2 sm:w-full w-[220px] text-left">
            <table className="font-normal font-poppins sm:text-[13px] text-[13px] border-collapse border border-gray-300">
              <tbody>
                <tr>
                  <th className="border border-gray-300 p-2">Campaign Name</th>
                  <th className="border border-gray-300 p-2">
                  {campaign.campaign_name ? campaign.campaign_name : "empty"}
                  </th>
                </tr>
                
                <tr>
                  <td className="border border-gray-300 p-2">
                    Campaign request by
                  </td>
                  <td className="border border-gray-300 p-2">
                    {profile && profile[0].company_name}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Campaign Type</td>
                  <td className="border border-gray-300 p-2">
                    {campaign.campaign_type ? campaign.campaign_type : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Platform</td>
                  <td className="border border-gray-300 p-2">
                    {campaign.platform ? campaign.platform : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Promotion</td>
                  <td className="border border-gray-300 p-2">
                    {" "}
                    {campaign.promotion ? campaign.promotion : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Location </td>
                  <td className="border border-gray-300 p-2">
                    {campaign.location ? campaign.location : "NA"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Influencers</td>
                  <td className="border border-gray-300 p-2">
                 
                  {influencerList? influencerList.map((item) =>
                  {
                   return <>{item.name?item.name+" " :item.influencer_list+" "} : {item.description}<br/></>
                   
                  })
                  :"Empty"
                }
                </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Deliverable</td>
                  <td className="border border-gray-300 p-2"> {campaign.deliverable ? campaign.deliverable : "empty"}</td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Niche</td>
                  <td className="border border-gray-300 p-2">  {campaign.niche ? campaign.niche : "empty"}</td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Budget(In₹)</td>
                  <td className="border border-gray-300 p-2"> {campaign.budgetlow ? campaign.budgetlow : "0"} -{" "}
            {campaign.budgethigh ? campaign.budgethigh : "0"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardModal>
      )}
    </div>
  );
};

export default FinishedCampaignList;


