import React, { useState } from "react";
import insa from "../assets/instagram.svg";
import fw from "../assets/fw22.svg";
import fi from "../assets/fi22.svg";
import yellowImg from "../assets/Rectangle311.png";
import blueImg from "../assets/Rectangle312.svg";
import yellowImg2 from "../assets/yellow2img.png";
import blueImg2 from "../assets/blue2img.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Fshweta from "../assets/Fshweta.png";
import Fimg21 from "../assets/Fimg21.png";
import Franveer from "../assets/Franveer.png";
import styles from "../styles";
import CardModal from "./CardModal";
import { HiAtSymbol } from "react-icons/hi";
import { HiPhone } from "react-icons/hi";
import { HiLocationMarker } from "react-icons/hi";

const Footer = () => {
  const phoneNumber = "8800880730"; // Replace with the desired WhatsApp number

  // Prefix the phone number with the country code, e.g., '91' for India
  const countryCode = "91";
  const whatsappLink = `https://wa.me/${countryCode}${phoneNumber}`;

  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  return (
    <section
      id="footer"
      className={`flex md:h-[100vh] xs:h-auto ss:h-auto sm:h-[120vh] w-full relative mt-[200px]  flex-col bg-[#7A1848] pt-56 sm:px-4 px-6 md:items-start items-center md:text-start text-center`}
    >
      <div className="m-auto w-[80%] justify-between  right-0 left-0 flex bg-[#FFC9E4] flex-wrap  absolute rounded-xl mt-[-150px] top-0">
        <div className="flex flex-col items-start md:w-[30%] w-[90%] md:m-[70px] m-[20px]    ">
          <div>
            <p className="px-3 font-bold font-[DM Sans] text-black text-[22px]">
              Socialize
            </p>
          </div>
          <div className="flex flex-row">
            <div className="p-3  m-[10px]   cursor-pointer   border-2 border-[#7A1848]  border-dotted  rounded-full w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] sm:p-4 bg-white">
              {" "}
              <Link
                to="https://www.instagram.com/brandlytical/"
                title="Instagram"
              >
                <img src={insa} className="w-[100%]   h-[100]%" />
              </Link>
            </div>
            <div className="p-3  m-[10px]   cursor-pointer   border-2 border-[#7A1848]  border-dotted  rounded-full w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] sm:p-4  bg-white">
              {" "}
              <Link
                to="https://www.instagram.com/brandlytical_studio/?hl=en"
                title="Brandlytical Studio"
              >
                <img src={insa} className="w-[100%]   h-[100]%" />
              </Link>
            </div>
            <div className="p-3  m-[10px]    cursor-pointer   border-2 border-[#7A1848]  border-dotted  rounded-full w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] sm:p-4  bg-white">
              {" "}
              <Link to={whatsappLink} title="WhatsApp">
                <img src={fw} className="w-[100%]    cursor-pointer h-[100]%" />
              </Link>
            </div>
            <div className="p-3  m-[10px]      cursor-pointer border-2 border-[#7A1848]  border-dotted rounded-full w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] sm:p-4  bg-white">
              {" "}
              <Link
                to="https://www.linkedin.com/company/brandlytical/"
                title="Linkden"
              >
                <img
                  src={fi}
                  className="w-[100%]    cursor-pointer   h-[100]%"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className=" h-[300px] w-[100%]  md:w-[30%] sm:h-[300px] ">
          <div className="absolute">
            <img src={Fimg21} alt="" />
          </div>

          <div className="absolute w-[60px] h-[100px]   ml-[140px] ">
            <img
              src={yellowImg}
              className="h-[100%]  m-auto w-[100%]   "
              alt=""
            />
          </div>
          <div className="absolute w-[120px] h-[100px] mt-[20px]  right-0">
            <img src={Fshweta} className="h-[100%] m-auto w-[100%]  " alt="" />
          </div>
          <div className="absolute w-[100px] h-[70px] mt-[130px]   ">
            <img
              src={blueImg2}
              className="  h-[100%] m-auto w-[100%]  "
              alt=""
            />
          </div>
          <div className="absolute w-[60px] h-[60px] mt-[210px]   ">
            <img
              src={yellowImg2}
              className="  h-[100%] m-auto w-[100%]"
              alt=""
            />
          </div>

          <div className="absolute   rotate-90 w-[110px] h-[110px] ml-[140px] mb-[20px]  bottom-0  ">
            <img src={Franveer} className="h-[100%] m-auto w-[100%]  " alt="" />
          </div>
          <div className="absolute   rotate-90 w-[60px] h-[150px]  bottom-0 right-0">
            <img src={blueImg} className="h-[100%] m-auto w-[100%]   " alt="" />
          </div>
        </div>
      </div>

      <div
        className={`flex-1 ss:${styles.flexStart} flex-col xl:px-0 sm:pl-10  md:text-start text-center md:items-start items-center min-w-[900px]`}
      ></div>
      <div className="flex flex-col w-full mb-10 md:h-[100%] h-[50%] m-auto justify-center md:justify-between   ">
        <div className="flex item-end w-auto sm:flex-row  sm:w-[100%] sm:flex-flex-col   flex-col lg:pl-[400px] ">
          <a href="#about">
            <p className="px-3 cursor-pointer hover:text-red-500 hover:underline font-bold lg:text-[16px]  sm:text-[14px] text-[13px] md:py-0 py-2 text-white">
              ABOUT US
            </p>
          </a>

          <a href="#services">
            <p className="px-3 cursor-pointer hover:text-red-500 hover:underline font-bold lg:text-[16px]   sm:text-[14px] text-[13px] md:py-0 py-2 text-white">
              OUR SERVICES
            </p>
          </a>
          <a href="../Termscondition" target="_blank">
            <p className="px-3 cursor-pointer hover:text-red-500 hover:underline  font-bold lg:text-[16px]   sm:text-[14px] text-[13px] md:py-0 py-2 text-white">
              T&C
            </p>
          </a>
          {/*<p className="px-3 cursor-pointer hover:text-red-500 hover:underline font-bold lg:text-[16px] sm:text-[14px] text-[16p] md:py-0 py-2 text-white">
            TEAM
    </p>*/}
          <a href="#casestudy">
            <p className="px-3 cursor-pointer hover:text-red-500 hover:underline  font-bold lg:text-[16px]   sm:text-[14px] text-[13px] md:py-0 py-2 text-white">
              CASE STUDY
            </p>
          </a>

          <a href="../PressRelease" target="_blank">
            <p className="px-3 cursor-pointer hover:text-red-500 hover:underline  font-bold lg:text-[16px]   sm:text-[14px] text-[13px] md:py-0 py-2 text-white">
              PRESS RELEASE
            </p>
          </a>
          <a href="../ContactUs" target="_blank">
            <p className="px-3 font-[DM Sans] cursor-pointer hover:text-red-500 hover:underline font-bold lg:text-[16px]   sm:text-[14px] text-[13px] md:py-0 py-2 text-white">
              CONTACT US
            </p>
          </a>
        </div>
        <div className=" p-[1%]  flex   md:flex-row flex-col  md:gap-5 gap-1 item-start md:w-[90%] m-auto w-full ">
          <div>
            <p className="text-white font-[DM Sans] text-start  font-bold  text-[13px]  sm:text-[18px] ">
              Contact Us:
            </p>
            <hr className="w-full" />
          </div>
          <div>
            <p className="flex text-white font-[DM Sans] justify-start text-start cursor-pointer  text-[13px]  sm:text-[18px]">
              <HiAtSymbol size={20} /> contact@brandlytical.in
            </p>
          </div>
          <div>
            <p className="flex text-white font-[DM Sans] text-start  justify-end text-[15px]  sm:text-[18px]">
              <HiPhone size={20} />
              &nbsp; +918800880730
            </p>
          </div>
        </div>
        <div className=" p-[1%]  flex   md:flex-row flex-col justify-start  md:gap-5 gap-1 item-start md:w-[90%] m-auto w-full ">
          <div>
            <span className=" flex text-white font-[DM Sans] text-start  font-bold  text-[13px]  sm:text-[18px]  ">
              <HiLocationMarker size={20} />
              Address :
            </span>
            <hr className="w-full" />
          </div>
          <div>
            <p className="text-white font-semi text-start  font-[DM Sans] text-[13px]  sm:text-[18px]">
              <p className="text-white font-semi text-start font-bold  font-[DM Sans] text-[13px]  sm:text-[18px]">
                Delhi Office :
              </p>
              Plot No - 35-36 Ground Floor, <br />
              Pocket 14, Sector-24, Rohini, New Delhi, <br />
              Delhi, 110085
            </p>
          </div>
          <div>
            <p className="text-white font-semi text-start  font-[DM Sans] text-[13px]  sm:text-[18px]">
            <p className="text-white font-semi text-start font-bold  font-[DM Sans] text-[13px]  sm:text-[18px]">
                Gurugram Office :
              </p> Wework Forum, DLF Cyber City, <br />
              DLF Phase 3, Sector 24, Gurugram,
              <br /> Haryana 122002
            </p>
            {/* <p className="text-white font-semi text-start  font-[DM Sans] text-[13px]  sm:text-[18px]">
            <HiLocationMarker size={20} />  Block E south city,
              <br />
              sector-41,Gurugram Haryana-122022.
            </p>*/}
          </div>
          <div>
            <p className="text-white font-semi text-start  font-[DM Sans] text-[13px]  sm:text-[18px]">
            <p className="text-white font-semi text-start font-bold  font-[DM Sans] text-[13px]  sm:text-[18px]">
                Maharashtra Office :
              </p>
              WeWork Vaswani Chambers,
              <br /> 1st floor, 264-265, Dr. Annie Besant Rd, Worli,
              <br /> Maharashtra, 400051
            </p>
          </div>
        </div>
        {/*<div className=" p-[1%]  flex   md:flex-row flex-col  md:gap-5 gap-1 item-start md:w-[90%] m-auto w-full ">
          <div>
          
            <p className="text-white font-[DM Sans]  justify-start text-start cursor-pointer  text-[13px]  sm:text-[18px]">
            <HiAtSymbol size={20} />  contact@brandlytical.in
            </p>
            </div>
            <div>
          
            <p className="text-white font-[DM Sans] text-start  justify-end text-[15px]  sm:text-[18px]">
            <HiPhone size={20} />  +918800880730
            </p>
            </div>
          
        </div>*/}
        <div className="     text-white  w-full ">
          <hr className="w-full" />
          <p className="font-[DM Sans] text-white ">
            Copyrights @2023 Brandlytical
          </p>
        </div>
      </div>
      {!deleteModal && (
        <CardModal
          open={isOpen}
          onCLose={() => {
            setIsOpen(false);
          }}
          showClose={true}
        ></CardModal>
      )}
    </section>
  );
};

export default Footer;
