import React, { useState } from "react";
import styles from "../styles";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import navLogo2 from "../assets/navlogo2.png";
import B2 from "../assets/B2new.png";

const PressRelease = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 200); // Show the button when scrolling down 200 pixels
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Add event listener to handle scroll events
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-white">
      <Nav />
    <div class="md:px-43 sm:px-16 px-6 bg-white">
   
    <section id="home" className={`flex md:h-[100vh] md:flex-row flex-col bg-[url('/src/assets/home1.png')] bg-center bg-cover ${styles.paddingY} items-center w-full relative`}>
    
    <div className={`flex md:h-[100vh] md:flex-row flex-col items-center w-full relative`}>
         <div className={`absolute animate-[none]
                         w-[170px] h-[40px] md:top-12.8px top-[12.8px] 
                        left-[10%] md:left-[10%] `}>
                          <h1 className="font-[Cormorant Garamond] text-[35px] font-normal text-black  ">
                        Terms&nbsp;and&nbsp;Conditions</h1></div>
        <div className={`absolute animate-[bounce_3s_ease-in-out_infinite] bg-[#5D35FD] 
              rounded-full w-[40px] h-[40px] md:top-12.5px top-[12.5px] 
                 left-[0.4%] md:left-[0.4%] bg-opacity-40`}
         ></div>
      <div
        className={`absolute animate-[bounce_3s_ease-in-out_infinite] 
            bg-[#5D35FD] rounded-full w-[30px] h-[30px] 
                md:top-15.9 top-[15.9px] md:left-[4%] left-[4%]`}
      ></div>
            <div className="font-bold text-2xl cursor-pointer font-[DM Sans] 
                text-gray-800 ml-[310px] " >
              <div classname="top-[60px] md:top-[40px]">
              <img src={B2}  
                className="xs:px:140px md:w-[80px] md:h-[100px] w-[80px] left-[80%] md:left-[80%] ml-[90px] " />
                <img src={navLogo2} className="w-[255px] h-[34px] left-[1%] md:left-[1%]" />
              </div>
            </div>
    </div>
          
            <div className="bg-pink-900 md:px-100 sm:px-100 px-8 flex flex-col w-[700px] h-[350px] 
        items-center justify-center p-[25px] rounded-lg shadow-bs top-[50px] mr-[310px] ">
                <span className="text-white font-[DM Sans]">
                . We work on 50% advance basis and 50%   
                 within 7 days of deliverables (- Payment
                  terms might differ in case of celebrities)
                <br/>
                .Additional 10% commission will be charged 
                as a service fee on overall billing
                <br/>
                .TDS deduction - 2%, as we fall under 194J 
                (technical services-21)
                <br/>
                .These are estimated ROI 
                <br/>
                . In case of backout replacement 
                will be provided
                </span>
      </div>
            </section>
</div>
      <div className="mt-2 bg-white" id="footer">
        <Footer />
      </div>
    </div>
  );
};
export default PressRelease;
