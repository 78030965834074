import { createClient } from '@supabase/supabase-js'

// const supabaseUrl = 'https://eehkrjkcvdbnlzmdegvn.supabase.co'
// const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVlaGtyamtjdmRibmx6bWRlZ3ZuIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODA1OTQ5NjEsImV4cCI6MTk5NjE3MDk2MX0.VjvQ_1AmRIA7WrdkHJII72o167Z1fJYM1Avx6OR_ZWE'

//const supabaseUrl = 'https://bcplblyhvznsyqmdcbrp.supabase.co'
//onst supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJjcGxibHlodnpuc3lxbWRjYnJwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODU5NTkwOTksImV4cCI6MjAwMTUzNTA5OX0.9b-Im3ttSRaKXKUwIGX_98Wk94sJVvZMGh0Rqqd70Dw'

// brandlyticalv2 db asiapacafic south server
const supabaseUrl = 'https://jqbqkovcsjmkzdwttevj.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpxYnFrb3Zjc2pta3pkd3R0ZXZqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODYxNDE0MzUsImV4cCI6MjAwMTcxNzQzNX0.tmCbDkod7IOpEcZruBqwNQ8zY33nKNaDFZpEwm23--Q'

export const supabase = createClient(supabaseUrl, supabaseAnonKey)
