import React, { Children } from "react";
import HomePage from "./pages/HomePage";
import SignupAs from "./pages/SignupAs";
import CreateAccount from "./pages/CreateAccount";
import {
  BrowserRouter as Router,
  Link,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import { supabase } from "./utils/supaBase";
import Approval from "./pages/Approval";
import Login from "./pages/Login";
import { useState, useEffect } from "react";
import EvalOrCompleteProfile from "./pages/EvalOrCompleteProfile";
import CampaignType from "./pages/CampaignType";
import Test from "./pages/Test";
import CampaignSubmitted from "./pages/CampaignSubmitted";
import Influencers from "./pages/Influencers";
import Subscription from "./pages/Subscription";
import CreateAccountAgency from "./pages/CreateAccountAgency";
import SelectCampaignType from "./pages/SelectCampaignType";
import UserCampaigns from "./pages/UserCampaigns";
import { Layout, Menu } from "antd";
import { CartState } from "./context/Context";
import BrandApproval from "./pages/BrandApproval";
import CampaignApproval from "./pages/CampaignApproval";
import CampaignApproved from "./pages/CampaignApproved";
import InfluencerListAdmin from "./pages/InfluencerListAdmin";
import { AiFillAccountBook } from "react-icons/ai";
import AdminDashboard from "./components/AdminDashboard";
import UserDashboard from "./components/UserDashboard";
import ManageProfile from "./pages/PersonalDetails";
import PersonalDetails from "./pages/PersonalDetails";
import CompanyDetails from "./pages/CompanyDetails";
import ChangePassword from "./pages/ChangePassword";
import AdminDashboardHome from "./pages/AdminDashboardHome";
import CreateAccountTeam from "./pages/CreateAccountTeam";
import TeamApproval from "./pages/TeamApproval";
import AdminSupport from "./pages/AdminSupport";
import ThankyouPage from "./pages/ThankyouPage";
import AddInfluencer from "./pages/AddInfluencer";
import AgencyApproval from "./pages/AgencyApproval";
import ForgotPassword from "./pages/ForgotPassword";
import AdminReports from "./components/AdminReports";
import UserReports from "./pages/UserReports";
import UserSupportData from "./pages/UserSupportData";
import BulkImport from "./pages/BulkImport";
// import Navbar from './components/Navbar/Navbar';
import PressRelease from "./components/PressRelease";
import ContactUs from "./components/ContactUs";
import Termscondition from "./components/Termsncondition";
import CaseStudyLinks from "./components/CaseStudyLinks";
import SongIntegrationCampaign from "./pages/SongIntegrationCampaign";
import SongIntegrationAdmin from "./pages/SongIntegrationAdmin";
import SponsorshipDealsAdmin from "./pages/SponsorshipDealsAdmin";
import IntegrationThankyouPage from "./pages/IntegrationThankyouPage";
import AddSongDetails from "./components/AddSongDetails";
import UpdateIntegration from "./components/UpdateIntegration";
import AddSponsorship from "./components/AddSponsorship";
import UpdateSponsorship from "./components/UpdateSponsorship";
import IntegrationRequestList from "./components/IntegrationRequestList";
import SponsorshipDealsUser from "./pages/SponsorshipDealsUser";
import UserDashboardCampain from "./pages/UserDashboardCampain";
import ApprovalHub from "./pages/ApprovalHub";
import ApproveHubList from "./components/ApproveHubList";
import EditInfluencer from "./pages/EditInfluencer";
import SupportReportAdmin from "./pages/SupportReportAdmin";
import ContactQuery from "./pages/ContactQuery";
import UploadFile from "./components/UploadFile";
import PendingBrand from "./pages/PendingBrand";
import ApprovedAgency from "./pages/ApprovedAgency";
import ApprovedBrand from "./pages/ApprovedBrand";
import ViewCampaigns from "./pages/ViewCampaigns";
import CampaignFinished from "./pages/CampaignFinished";
import SchedulerData from "./components/SchedulerData"
const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: { cart },
    dispatch,
    filterDispatch,
  } = CartState();
  const { userState, userDispatch } = CartState();

  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    userDispatch({
      type: "LOGOUT",
    });
    navigate("/login");
  };

  const handleMyCampaigns = async () => {
    dispatch({
      type: "CLEAR_CART",
    });
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pressrelease" element={<PressRelease />} />
        <Route path="/signupas" element={<SignupAs />} />
        <Route path="/signupasbrand" element={<CreateAccount />} />
        <Route path="/signupasagency" element={<CreateAccountAgency />} />
        <Route path="/signupasteam" element={<CreateAccountTeam />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route
          path="/resetpassword"
          element={!session ? <Login /> : <ChangePassword />}
        />
        <Route
          path="/evalprofile"
          element={!session ? <Login /> : <EvalOrCompleteProfile />}
        />
        <Route
          path="/subscribe"
          element={!session ? <Login /> : <Subscription />}
        />
        <Route
          path="/thankyou"
          element={!session ? <Login /> : <ThankyouPage />}
        />
        handleCampaign={handleMyCampaigns}
        {/*/>*/}){/*}*/}
        {/*}>*/}
        <Route
          path="/integrationthankyou"
          element={!session ? <Login /> : <IntegrationThankyouPage />}
        />
        <Route path="" element={<div>Home</div>}></Route>
        <Route
          path="campaign"
          element={!session ? <Login /> : <UserCampaigns />}
        ></Route>
        <Route
          path="report"
          element={!session ? <Login /> : <UserReports />}
        ></Route>
        <Route
          path="support"
          element={!session ? <Login /> : <UserSupportData />}
        ></Route>
        <Route path="signout" element={<div>Home</div>}></Route>
        <Route path="pressRelease" element={<PressRelease />}></Route>
        <Route path="termscondition" element={<Termscondition />}></Route>
        <Route path="ContactUs" element={<ContactUs />}></Route>
        <Route path="CaseStudyLinks" element={<CaseStudyLinks />}></Route>
        <Route
          path="managepersonal"
          element={!session ? <Login /> : <PersonalDetails />}
        ></Route>
        <Route
          path="managecompany"
          element={!session ? <Login /> : <CompanyDetails />}
        ></Route>
        <Route
          path="changepassword"
          element={!session ? <Login /> : <ChangePassword />}
        ></Route>
        <Route
          path="campaigntype"
          element={!session ? <Login /> : <CampaignType />}
        />
        <Route
          path="selectcampaigntype"
          element={!session ? <Login /> : <SelectCampaignType />}
        />
        <Route
          path="selectinfluencers"
          element={!session ? <Login /> : <Influencers />}
        />
      </Routes>

      <Routes>
        <Route
          path="/dashboardadmin"
          element={
            !session ? <Login /> : <AdminDashboard signOut={handleSignOut} />
          }
        >
          <Route
            path=""
            element={!session ? <Login /> : <AdminDashboardHome />}
          ></Route>
          <Route
            path="approvalhub"
            element={!session ? <Login /> : <ApprovalHub />}
          ></Route>
          <Route
            path="approvehublist"
            element={!session ? <Login /> : <ApproveHubList />}
          ></Route>
          <Route
            path="supportreportadmin"
            element={!session ? <Login /> : <SupportReportAdmin />}
          />
          <Route
            path="contactquery"
            element={!session ? <Login /> : <ContactQuery />}
          />

          <Route
            path="campaign"
            element={!session ? <Login /> : <Approval />}
          ></Route>
          <Route
            path="pendingcampaign"
            element={!session ? <Login /> : <CampaignApproval />}
          ></Route>
          <Route
            path="approvedcampaign"
            element={!session ? <Login /> : <CampaignApproved />}
          ></Route>
          <Route
            path="finishedcampaign"
            element={!session ? <Login /> : <CampaignFinished />}
          ></Route>
          <Route
            path="agency"
            element={!session ? <Login /> : <AgencyApproval />}
          ></Route>
          <Route
            path="viewcampaigns"
            element={!session ? <Login /> : <ViewCampaigns />}
          ></Route>
          <Route
            path="approvedagencies"
            element={!session ? <Login /> : <ApprovedAgency />}
          ></Route>
          <Route
            path="brand"
            element={!session ? <Login /> : <BrandApproval />}
          ></Route>
          <Route
            path="pendingbrands"
            element={!session ? <Login /> : <PendingBrand />}
          ></Route>
          <Route
            path="approvedbrands"
            element={!session ? <Login /> : <ApprovedBrand />}
          ></Route>
          <Route
            path="team"
            element={!session ? <Login /> : <TeamApproval />}
          ></Route>
          <Route
            path="influencers"
            element={!session ? <Login /> : <InfluencerListAdmin />}
          ></Route>
          <Route
            path="edit"
            element={!session ? <Login /> : <EditInfluencer />}
          ></Route>
           <Route
            path="scheduledata"
            element={!session ? <Login /> : <SchedulerData />}
          ></Route>
          <Route
            path="addinfluencers"
            element={!session ? <Login /> : <BulkImport />}
          ></Route>
          <Route
            path="report"
            element={!session ? <Login /> : <AdminReports />}
          ></Route>
          <Route
            path="support"
            element={!session ? <Login /> : <AdminSupport />}
          ></Route>
          <Route path="signout" element={<div>Home</div>}></Route>
          <Route
            path="managepersonal"
            element={!session ? <Login /> : <PersonalDetails />}
          ></Route>
          <Route
            path="changepassword"
            element={!session ? <Login /> : <ChangePassword />}
          ></Route>
          <Route
            path="songintegrationadmin"
            element={!session ? <Login /> : <SongIntegrationAdmin />}
          />
          <Route
            path="addsongdetails"
            element={!session ? <Login /> : <AddSongDetails />}
          />
          <Route
            path="addsponsorship"
            element={!session ? <Login /> : <AddSponsorship />}
          />
          <Route
            path="sponsorshipdeals"
            element={!session ? <Login /> : <SponsorshipDealsAdmin />}
          />
          <Route
            path="updateintegration"
            element={!session ? <Login /> : <UpdateIntegration />}
          />
          <Route
            path="updatesponsorship"
            element={!session ? <Login /> : <UpdateSponsorship />}
          />
          <Route
            path="integrationrequestlist"
            element={!session ? <Login /> : <IntegrationRequestList />}
          />
        </Route>
        <Route
          path="uploadfile"
          element={!session ? <Login /> : <UploadFile />}
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="/dashboard"
          element={
            !session ? (
              <Login />
            ) : (
              <UserDashboard
                signOut={handleSignOut}
                handleCampaign={handleMyCampaigns}
              />
            )
          }
        >
          {/*<Route path='' element={<div>Home</div>}></Route>*/}
          <Route
            path=""
            element={!session ? <Login /> : <UserDashboardCampain />}
          ></Route>
          <Route
            path="campaign"
            element={!session ? <Login /> : <UserCampaigns />}
          ></Route>
          <Route
            path="report"
            element={!session ? <Login /> : <UserReports />}
          ></Route>
          <Route
            path="support"
            element={!session ? <Login /> : <UserSupportData />}
          ></Route>
          <Route path="signout" element={<div>Home</div>}></Route>
          <Route
            path="managepersonal"
            element={!session ? <Login /> : <PersonalDetails />}
          ></Route>
          <Route
            path="managecompany"
            element={!session ? <Login /> : <CompanyDetails />}
          ></Route>
          <Route
            path="changepassword"
            element={!session ? <Login /> : <ChangePassword />}
          ></Route>
          <Route
            path="campaigntype"
            element={!session ? <Login /> : <CampaignType />}
          />
          <Route
            path="selectcampaigntype"
            element={!session ? <Login /> : <SelectCampaignType />}
          />
          <Route
            path="selectinfluencers"
            element={!session ? <Login /> : <Influencers />}
          />
          <Route
            path="songintegrationcampaign"
            element={!session ? <Login /> : <SongIntegrationCampaign />}
          />
          <Route
            path="sponsorshipdealuser"
            element={!session ? <Login /> : <SponsorshipDealsUser />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default App;
