import React, { useState } from "react";
import { supabase } from "../utils/supaBase";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from 'react-router-dom';
import influencertemplate from "../assets/influencer_upload_sheet.csv";

const BulkImport = () => {
  const navigate = useNavigate();
  const [parsedData, setParsedData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);
  const [isSuccess, setIsSuccess] = useState([false]);
  const [isError, setIsError] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const allowedTypes = ["text/csv"];
  const [file, setFile] = useState(null);
  let minroi ,maxroi = 0;
  //checking uploaded file is correct or not 
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const selectedFileType = selectedFile?.type;
    try {
      if (allowedTypes.includes(selectedFileType)) {
        Papa.parse(event.target.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: async function (results) {
            const rowsArray = [];
            const valuesArray = [];
            results.data.map((d) => {
              rowsArray.push(Object.keys(d));
              valuesArray.push(Object.values(d));

                   console.log(d.engagement_rate)
                   if(d.engagement_rate)
                   {

                    minroi = d.engagement_rate * 0.7
                    results.data.map((d) => (d.min_roi = minroi));
                   
                    maxroi = d.engagement_rate * 1.34
                    results.data.map((d) => (d.roi = maxroi));
                    console.log(d.min_roi+"-=-=-"+d.roi)
                    
                   }
              if(rowsArray[0].includes("influencer_id"))
                {
                  if(d.influencer_id !== "")
                  {
                    console.log("Available to Update===="+d.influencer_id)

                  }
                  else{
                    
                    results.data.map((d) => (d.influencer_id = uuidv4()));
                    console.log("blank column added"+d.influencer_id)
                  }
                  
                }
                else
                {
                  console.log("NOT-AVAILABLE")
                }
              //results.data.map((d) => (d.influencer_id = uuidv4()));
              //results.data.map(d=>d.Timestamp=new Date.now())
             
            });

            setParsedData(results.data);
            setTableRows(rowsArray[0]);
            setValues(valuesArray);
           
            if (results.data.length !== 0) {
              const { data, error } = await supabase
                .from("influencers")
                .upsert(results.data,{onConflict:"influencer_id"})
           
                if(error)
                {
                  console.log(results.data)
                  alert("Data not saved ="+error.message+"----"+error.code)
                  
                }
                else{
                  alert("Data Saved Successfully");
                  navigate('/dashboardadmin/influencers')
                }
              
            } else {
              alert("File is not containg Data");
            }
          },
        });
      } else {
        alert("Only CSV files are allowed");
      }
    } catch (error) {
      this.setState({ error: error.message });
      alert("errorrrrrrr"+error)
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-2xl p-6 max-w-md mx-auto">
      <h1 className="text-2xl font-bold mb-4">
        Select File to Upload Influencers Details
      </h1>
      <div className="mb-4 cursor-pointer mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        <label htmlFor="fileInput" className="relative cursor-pointer">
          <input
            type="file"
            id="fileInput"
            accept=".csv,text/csv"
            onChange={handleFileChange}
            className="opacity-0 absolute inset-0 h-full w-full cursor-pointer"
          />
          <div className="bg-gray-500 text-white py-1 px-2 rounded-lg shadow-md inline-block cursor-pointer">
            Choose File
          </div>
        </label>
      </div>
      {isError && <div className="text-red-500 mb-4">{errorMsg}</div>}
      <div className="flex justify-between">
        <div className="">
          <h2 className="font-bold text-left mb-1 text-lg">Note to Remember</h2>
          <ul className="list-disc pl-4">
            <li> Only CSV File format is acceptable. </li>
            <li> All the data should be left align. </li>
            <li>Do not disturb the CSV file while uploading (Better to keep close the file)</li>
            <li> For better and fast result please upload data of approx 200 rows for uploads at once. </li>
            <li>Do not disturb the header row.</li>
            <li>Check all the input data before uploading, i.e., Numeric field must have a number or 0.</li>
          </ul>
          <a href={influencertemplate} download="Master-Influencer-Sheet.csv" target="_blank" rel="noreferrer" className="inline-block mt-2">
            <button className="bg-blue-500 text-white py-1 px-2 mt-2 rounded-lg shadow-md"> Download .csv Template </button>
          </a>
        </div>
      </div>
    </div>

  );
};

export default BulkImport;
