import React, { useEffect } from 'react'
import { supabase } from '../utils/supaBase'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'


const SupportReportAdmin =() =>
{
    const location = useLocation();
    const [campaign, setCampaigns] = useState([]);
    //const campaignId = location.state.campaignId;
    const [error, setError] = useState();
    const [title, settitle] = useState();
    const [session, setSession] = useState();
    const [created_for, setCreated_by] = useState();

    useEffect(() =>{
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session);
      setCreated_by(session.user.id)
      
        
        })
        async function fetchCampaigns() {
            
           // if(created_for){
              const { data, error } = await supabase
              .from('support_list')
              .select('*')
              //.eq('created_for', created_for);
              //.eq('created_for',session.user.id)
              .eq('receiver_name', 'brandlytical');
               
            if (error) {
              setError('Could not fetch data');
              setCampaigns([]);
              alert(error.message);
            } else {
              setCampaigns(data);
              setError(null);
              if(data !== "")
              {
                settitle(data[0].title)
              }
            }
           // }
          }
         
        fetchCampaigns();
       
    },[])
   
    return(
        
        <div className='md:mr-10 shadow-md border-2 border-gray-300 sm:p-8 p-4 
        lg:min-w-[250px] sm:min-w-[220px] min-w-[120px] sm:min-h-[150px] min-h-[200px]
               rounded-md md:my-0 my-6 text-justify items-justify bg-pink-100' >
        
        <table  className='md:mr-10 shadow-md border-2 border-gray-300 sm:p-8 p-4 
        lg:min-w-[full] sm:min-w-[full] min-w-[full] sm:min-h-[full] min-h-[full]
               rounded-md md:my-0 my-6 text-justify items-justify'
        style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}>
            <tr  style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
            classname='bg-pink-500'>
                <td className='font-dmSans sm:text-[18px] text-[18px] py-1 text-left bg-pink-300' 
            style={{width:"250px"}}>Campaign  </td> 
             
             <td className='font-dmSans sm:text-[18px] text-[18px] 
             py-1 text-left bg-pink-300' style={{width:"200px"}}>Issue Raised By </td> 
            <td className='font-dmSans sm:text-[18px] text-[18px] py-1 text-left bg-pink-300'
             style={{width:"250px"}}>Company Name  </td>
             
            <td className='font-dmSans sm:text-[18px] text-[18px] py-1 text-left bg-pink-300' 
            style={{width:"150px"}}>Email  </td>
            <td className='font-dmSans sm:text-[18px] text-[18px] py-1 text-left bg-pink-300' 
            style={{width:"150px"}}>Phone  </td>
            <td className='font-dmSans sm:text-[18px] text-[18px] py-1 text-left bg-pink-300' 
            style={{width:"500px"}}>Description  </td>
            
            </tr>
            {campaign != '' ? 
                 campaign.map((campaign)=>
                 {
                  return(
                         <tr  style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}>
                          <td style={{width:"250px",textTransform:'capitalize'}}>{campaign.campaign_name}</td>
                          
                          <td style={{width:"200px",textTransform:'capitalize'}}>{campaign.creator_company_name}</td>
                          <td style={{width:"250px",textTransform:'capitalize'}}>{campaign.creator_agency_name}</td>
                          
                          <td style={{width:"150px",}}>{campaign.creator_email}</td>
                          <td style={{width:"150px"}}>{campaign.creator_phone}</td>
                          <td style={{width:"500px"}}>{campaign.description}</td>
                          
                        </tr>
                  )
                  
                 }
                 )
                 :<div>
                  <span style={{textTransform: 'capitalize' , textAlign : 'center'}}
                   classname="font-dmSans sm:text-[10px] text-[10px] font-bold py-1">
                    No Data Available
            </span></div> }
            </table>
      </div>
                
    )
}
export default SupportReportAdmin;