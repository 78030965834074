import React ,{useState}from "react";
import InputField from '../components/InputField';

import CardModal from '../components/CardModal';
import ApproveModalUI from '../components/ApproveModalUI';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4, v4 } from "uuid";
import { supabase } from '../utils/supaBase';
import { data } from 'autoprefixer';
const AddSponsorship = () =>
{
    const navigate = useNavigate();
    const[eventtitle, seteventtitle] = useState('');
    const[description, setdescription] = useState('');
    const [formError, setFormError] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionChange = (value, selectedOptions, setSelectedOptions, maxInputs) => {
        if (selectedOptions.includes(value)) {
          setSelectedOptions(selectedOptions.filter((option) => option !== value));
        } else if (selectedOptions.length < maxInputs) {
          setSelectedOptions([...selectedOptions, value]);
        }
      };
      async function handleSubmit(e) {
        e.preventDefault();
        //alert("Hello");
        if (eventtitle === '' || description === '' ) {
        setFormError('Please fill in all the fields correctly.')
    } else {
      
      }
      console.log(e)
      const { error } = await supabase
      .from('integration_details')
      .insert({
        campaign_id: uuidv4, 
        integration_type: "SPONSORSHIP",  
        title: eventtitle,  
        status: "ACTIVE",
        description : description,
       })

      if (error) {
        alert(error.error_description || error.message)
      }
      if(!error) {
        console.log(data[0])
        setIsOpen(true)
      }
    }
  
  return (
    <div >
   <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
    className='flex md:flex-row  flex-col w-center h-30 md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8 ' >
    <h2 className='mb-4 font-semibold text-[35px] text-center'>Enter Event-Sponsorship Details</h2>
    </div>
    <form className='w-center w-full  relative text-left item-left' onSubmit={handleSubmit}>
    <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
    className='flex md:flex-row  flex-col w-center h-30 md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8 py-5' >
       <span className="text-[25px] font-bold w-[200px]">Event Title</span>
       <InputField
                label=''
                id='seteventtitle'
                value={eventtitle}
                onChange={(e) => seteventtitle(e.target.value)}
                className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                />
    </div>
    <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
    className='flex md:flex-row  flex-col w-center h-30 md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8 py-5' >
       <span className="text-[25px] font-bold w-[200px]">Event Description</span>
       <InputField
                label=''
                id='description'
                value={description}
                onChange={(e) => setdescription(e.target.value)}
                className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                />
    </div>
    <div className='flex flex-col text-center justify-center 
    items-center md:px-8 sm:px-56 ss:px-32 xs:px-24 px-18 py-5'
     style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}>
                
        <button className='bg-[#D10269] text-white sm:p-3 sm:px-16 p-2 px-8 rounded-md'>Add Sponsorship Deals</button>
    </div>
      <CardModal open={isOpen} onCLose={() => {setIsOpen(false)}} showClose={true}>
      <ApproveModalUI
        title={'ARE YOU SURE YOU WANT TO ADD?'}
        subtitle={'If you choose to add, your integration deals will be visible for Brand/Agency !'}
        handleApprove={() => {
          navigate('/dashboardadmin/sponsorshipdeals')
        }}
      />
      </CardModal>



    

    
    </form>
   

    </div>
    
    
  )
}
export default AddSponsorship;