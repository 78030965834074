import React, { useState, useEffect } from 'react'
const UploadFile= ()=>{
    const [selectedFile, setSelectedFile] = useState(null);
     const upload_path = "src\reportfolder";
    const handleFileChange = (e) => {
      setSelectedFile(e.target.files[0]);
   }

   const handleUpload = async () => {
      if (!selectedFile) {
         alert("Please first select a file");
         return;
      }else{
      }
    }

    return(
        <div className='md:mr-10 shadow-md border-2 border-gray-300 sm:p-8 p-4 
        lg:min-w-[250px] sm:min-w-[220px] min-w-[120px] sm:min-h-[150px] min-h-[200px]
               rounded-md md:my-0 my-6 text-justify items-justify' >
             <h2>Single File Upload</h2>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
        </div>
    )
}
export default UploadFile;