import React from 'react'

const ApproveHubList = (campaign, approved) => {
   
console.log("hiii",campaign.id)
  return (
    <div>ApproveHubList</div>
  )
}

export default ApproveHubList