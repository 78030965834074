import React, { useEffect, useState } from "react";
import { supabase } from "../utils/supaBase";
import ApproveCard from "../components/ApproveCard";
import UserReportCard from "../components/UserReportCard";
import FinishedCampaignList from '../components/FinishedCampaignList';

const UserReports = () => {
  const [campaigns, setCampaigns] = useState(null)

  const [reports, setReports] = useState();
  const [error, setError] = useState();
  const [session, setSession] = useState();

  

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      console.log(session && session.user.id);

      async function fetchCampaigns() {
        const { data, error } = await supabase
        .from('campaign')
        .select('*')
        .eq('uid', session.user.id)
        .neq('campaign_type','SONG_INTEGRATION')
        .neq('campaign_type','SPONSORSHIP')
    
      if (error) {
        setError('Could not fetch data')
        setCampaigns(null)
        
      } else {
        setCampaigns(data) 
        setError(null)
      }
      }
  
      


      async function fetchReports() {
        const { data, error } = await supabase
          .from("reports")
          .select("*")
          .eq("created_for", session.user.id);

        if (error) {
          setError("Could not fetch data");
          setReports(null);
        } else {
          setReports(data);
          setError(null);
          
        }
      }
      fetchCampaigns();
      fetchReports();
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  return (
    <div className="items-center flex flex-col">
      <h1>List of Completed Campaigns</h1>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-y-4">

      <div className='flex md:flex-row flex-col overflow-x-scroll md:scrollbar 
        scrollbar-thumb-gray-500 scrollbar-track-gray-200
        lg:w-[1200px] md:w-[1000px] md:max-h-[500px] max-h-[2000px]  justify-start py-5'>
        {campaigns && campaigns.map((item) => {
          if(item.finished ) {
           // return <CompleteCampaign campaign={item} active={false}/>
           return <FinishedCampaignList campaign={item} status={true}/>
          }
        })}
        </div>
{/*
        {reports &&
        reports.map((item) => <UserReportCard report={item} key={item.id} />)}*/}
      </div>
    </div>
  );
};

export default UserReports;
