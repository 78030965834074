import React, { useState, useEffect } from "react";
import { supabase } from "../utils/supaBase";
import { useRef } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import CardModal from "./CardModal";
import ApproveModalUI from "./ApproveModalUI";

import { useNavigate } from "react-router-dom";

const ApproveCampaign = ({ campaign, approved }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showApproval, setShowApproval] = useState();
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState();

  const [influencerList, setInfluencerList] = useState(null);
  useEffect(() => {
    async function fetchProfile() {
      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", campaign.uid);

      if (error) {
        setError("Could not fetch data");
        setProfile(null);
      } else {
        setProfile(data);
        setError(null);
       
      }
    }
    fetchInfluencer();
    fetchProfile();
  }, []);
  async function fetchInfluencer()
  {
    const { data, error } = await supabase
    .from("campaign_influencer_list")
    .select("*")
    .eq("campaign_id", campaign.id);

  if (error) {
    setError("Could not fetch data");
    setInfluencerList(null);
  } else {
    setInfluencerList(data);
    setError(null);
    
  }
  }

  async function handleReject() {
    const { error } = await supabase
      .from("campaign")
      .update({ status: "REJECT" })
      .eq("id", campaign.id);

    if (error) {
      alert(error.error_description || error.message);
    } else {
      if (error) {
        console.log("error=-=-=-=-=" + error.message);
      } else {
        window.location.reload();
      }
    }
  }

  async function handleApprove() {
    const { error } = await supabase
      .from("campaign")
      .update({ verified: true ,"status" : "APPROVED" })
      .eq("id", campaign.id);
    

    if (error) {
      alert(error.error_description || error.message);
    } else {
    //  console.log("updated");
    
      if (error) {
        console.log("error=-=-=-=-=" + error.message);
    
      } else {
        window.location.reload();
      }
    }
  }

  return (
    <div
      className="md:mr-10 shadow-md border-2 border-gray-300 sm:p-8 p-4 
      lg:min-w-[250px] sm:min-w-[220px] min-w-[295px] sm:min-h-[150px] min-h-[200px]
             rounded-md md:my-0 my-6 text-justify items-justify"
    >
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left">
        Campaign Name:
        {campaign.campaign_name ? campaign.campaign_name : "empty"}
      </p>
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left">
        Campaign request by : {profile && profile[0].company_name}
      </p>
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left">
        Campaign Type :
        {campaign.campaign_type ? campaign.campaign_type : "empty"}
      </p>
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 flex flex-wrap text-left">
        Objective :
        {campaign.deliverable
          ? campaign.deliverable.map((item) => {
              return <>{`${item},`}</>;
            })
          : "empty"}
      </p>
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left">
        Start Date : {campaign.startdate ? campaign.startdate : "empty"}
      </p>
      <p className="font-normal font-poppins sm:text-[15px] text-[12px] py-1 text-left">
        End Date : {campaign.enddate ? campaign.enddate : "empty"}
      </p>

      <div className="flex flex-row items-center">
        {!approved ? (
          <>
            {campaign.status === "REJECT"? 
             <span
            
             className="mt-4 bg-[#F17C7C] p-2 px-4  rounded-lg mr-5 text-white sm:text-[15px] text-[12px]"
           >
             Rejected
           </span>
            :
            <div>
            <button
            className="mt-4 bg-[#1EC61A] p-2 px-4 rounded-lg mr-5 text-white sm:text-[15px] text-[12px]"
            onClick={() => {
              setIsOpen(true);
              setShowApproval(true);
            }}
          >
            Approve
          </button>
            <button
            onClick={handleReject}
            className="mt-4 bg-[#F17C7C] p-2 px-4  rounded-lg mr-5 text-white sm:text-[15px] text-[12px]"
          >
            Reject
          </button> 
            </div>
           
            }
           
          </>
        ) : (
          <div className="pr-2 sm:text-[18px] text-[15px]">
            <button className="mt-4 bg-green-400 p-2 px-4 rounded-lg mr-5 text-white sm:text-[15px] text-[12px]">
              Approved
            </button>
           
          </div>
        )}

        <button
          className="pt-4 text-[#D10269] flex flex-row items-center"
          onClick={() => {
            setIsOpen(true);
            setShowApproval(false);
          }}
        >
          <span className="pr-2 sm:text-[18px] text-[15px]">Status</span>
          {/*<span>
            <AiOutlineDownload size={24} />
        </span>*/}
          
        </button>
        
      </div>

      {!showApproval && (
        <CardModal
          open={isOpen}
          onCLose={() => {
            setIsOpen(false);
          }}
          showClose={true}
        >
          <div className="flex flex-col gap-y-2 sm:w-full w-[220px] text-left">
            <table className="font-normal font-poppins sm:text-[13px] text-[13px] border-collapse border border-gray-300">
              <tbody>
                <tr>
                  <th className="border border-gray-300 p-2">Campaign Name</th>
                  <th className="border border-gray-300 p-2">
                  {campaign.campaign_name ? campaign.campaign_name : "empty"}
                  </th>
                </tr>
                
                <tr>
                  <td className="border border-gray-300 p-2">
                    Campaign request by
                  </td>
                  <td className="border border-gray-300 p-2">
                    {profile && profile[0].company_name}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Campaign Type</td>
                  <td className="border border-gray-300 p-2">
                    {campaign.campaign_type ? campaign.campaign_type : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Platform</td>
                  <td className="border border-gray-300 p-2">
                    {campaign.platform ? campaign.platform : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Promotion</td>
                  <td className="border border-gray-300 p-2">
                    {" "}
                    {campaign.promotion ? campaign.promotion : "empty"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Location </td>
                  <td className="border border-gray-300 p-2">
                    {campaign.location ? campaign.location : "NA"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Influencers</td>
                  <td className="border border-gray-300 p-2">
                 
                  {influencerList? influencerList.map((item) =>
                  {
                   return <>{item.name?item.name+" " :item.influencer_list+" "} : {item.description}<br/></>
                   
                  })
                  :"Empty"
                }
                </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Deliverable</td>
                  <td className="border border-gray-300 p-2"> {campaign.deliverable ? campaign.deliverable : "empty"}</td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Niche</td>
                  <td className="border border-gray-300 p-2">  {campaign.niche ? campaign.niche : "empty"}</td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2">Budget(In₹)</td>
                  <td className="border border-gray-300 p-2"> {campaign.budgetlow ? campaign.budgetlow : "0"} -{" "}
            {campaign.budgethigh ? campaign.budgethigh : "0"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardModal>
      )}

      {showApproval && (
        <CardModal
          open={isOpen}
          onCLose={() => {
            setIsOpen(false);
          }}
          showClose={true}
        >
          <ApproveModalUI
            title={"ARE YOU SURE YOU WANT TO APPROVE THE CAMPAIGN?"}
            subtitle={"If you choose to approve, the status will change ...."}
            handleApprove={handleApprove}
          />
        </CardModal>
      )}
    </div>
  );
};

export default ApproveCampaign;

