import React, { useState, useEffect } from 'react'

import RadioInput from '../components/RadioInput';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Range } from 'react-range'
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supaBase';
import CheckboxInput from '../components/CheckboxInput';

import Select from 'react-select';

import { CartState } from '../context/Context';
import instagram from '../assets//instacampaign.svg'
import youtube from '../assets/ytcampaign.svg'
import twitter from '../assets/twittercampaign.svg'
import linkedin from '../assets/linkedin.svg'
import { data } from 'autoprefixer';
import CardModal from '../components/CardModal';
import ApproveModalUI from '../components/ApproveModalUI';

import accesories from '../assets/promotionimages/accesories.png'
import cosmetics from '../assets/promotionimages/cosmetics.png'
import fashion from '../assets/promotionimages/fashion.png'
import foodnbeverages from '../assets/promotionimages/foodnbeverages.png'
import InputField from '../components/InputField';
import DropDownMulti from '../components/DropdownMulti';

const DELIVERABLES = {
  reel: 'Reel',
}

const CampaignType = () => {

  const navigate = useNavigate();
  const location = useLocation();
  // const userId = location.state.userId;
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedCity, setselectedCity] = useState();

  const [campaign, setCampaign] = useState("");
  const [platform, setPlatform] = useState('');
  const [promotion, setPromotion] = useState('');
  const [deliverable, setDeliverable] = useState([]);
  const [followers, setFollowers] = useState([500, 6000000]);
  const [gender, setGender] = useState([]);
  const [cities, setCities] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [progress, setProgress] = useState(1);
  const [budget, setBudget] = useState([500, 5000000]);
  const [niche, setNiche] = useState("");
  const [formError, setFormError] = useState("");
  const [session, setSession] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState();
  const [error, setError] = useState();
  const [campaignName, setcampaignName] = useState();

  const cityOptions = [
    { value: 'Agra', label: 'Agra' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Ahmedabad', label: 'Ahmedabad' },
    { value: 'Amritsar', label: 'Amritsar' },
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Ajmer', label: 'Ajmer' },
    { value: 'Allahabad', label: 'Allahabad' },
    { value: 'Ambala', label: 'Ambala' },
    { value: 'Aurangabad', label: 'Aurangabad' },
    { value: 'Bareilly', label: 'Bareilly' },
    { value: 'Bilaspur H.P', label: 'Bilaspur H.P' },
    { value: 'Bangalore', label: 'Bangalore' },
    { value: 'Belgaum', label: 'Belgaum' },
    { value: 'Bhopal', label: 'Bhopal' },
    { value: 'Bilaspur', label: 'Bilaspur' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Chennai', label: 'Chennai' },
    { value: 'Chittorgarh', label: 'Chittorgarh' },
    { value: 'Chattisgarh', label: 'Chattisgarh' },
    { value: 'Coimbatore', label: 'Coimbatore' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Daman', label: 'Daman' },
    { value: 'Darjeeling', label: 'Darjeeling' },
    { value: 'Dehradun', label: 'Dehradun' },
    { value: 'Dudhi UP', label: 'Dudhi UP' },
    { value: 'Ernakulam', label: 'Ernakulam' },
    { value: 'Faizabad', label: 'Faizabad' },
    { value: 'Faridabad', label: 'Faridabad' },
    { value: 'Firozabad', label: 'Firozabad' },
    { value: 'Gandhinagar', label: 'Gandhinagar' },
    { value: 'Ghaziabad', label: 'Ghaziabad' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gurdaspur', label: 'Gurdaspur' },
    { value: 'Gurgaon', label: 'Gurgaon' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Gwalior', label: 'Gwalior' },
    { value: 'Haridwar', label: 'Haridwar' },
    { value: 'Hooghly', label: 'Hooghly' },
    { value: 'Howrah', label: 'Howrah' },
    { value: 'Hyderabad', label: 'Hyderabad' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Imphal', label: 'Imphal' },
    { value: 'Indore', label: 'Indore' },
    { value: 'Jabalpur', label: 'Jabalpur' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Jaipur', label: 'Jaipur' },
    { value: 'Jalandhar', label: 'Jalandhar' },
    { value: 'Jaisalmer', label: 'Jaisalmer' },
    { value: 'Jammu', label: 'Jammu' },
    { value: 'Jammu And Kashmir', label: 'Jammu And Kashmir' },
    { value: 'Jhansi', label: 'Jhansi' },
    { value: 'Jamnagar', label: 'Jamnagar' },
    { value: 'Jodhpur', label: 'Jodhpur' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kashmir', label: 'Kashmir' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Kushinagar ', label: 'Kushinagar' },
    { value: 'Kanpur', label: 'Kanpur' },
    { value: 'Kapurthala', label: 'Kapurthala' },
    { value: 'Karnal', label: 'Karnal' },
    { value: 'Kolkata', label: 'Kolkata' },
    { value: 'Leh', label: 'Leh' },
    { value: 'Lucknow', label: 'Lucknow' },
    { value: 'Ludhiana', label: 'Ludhiana' },
    { value: 'Madurai', label: 'Madurai' },
    { value: 'Mumbai', label: 'Mumbai' },

    { value: 'Muzaffarpur', label: 'Muzaffarpur' },
    { value: 'Maharastra', label: 'Maharastra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Mysore', label: 'Mysore' },
    { value: 'Nagpur', label: 'Nagpur' },
    { value: 'Nainital', label: 'Nainital' },
    { value: 'Nashik', label: 'Nashik' },
    { value: 'New Delhi', label: 'New Delhi' },
    { value: 'Noida', label: 'Noida' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Panipat', label: 'Panipat' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Patiala', label: 'Patiala' },
    { value: 'Patna', label: 'Patna' },
    { value: 'Pondicherry', label: 'Pondicherry' },
    { value: 'Pune', label: 'Pune' },
    { value: 'Raipur', label: 'Raipur' },
    { value: 'Rajkot', label: 'Rajkot' },
    { value: 'Ranchi', label: 'Ranchi' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Saharanpur', label: 'Saharanpur' },
    { value: 'Shimla', label: 'Shimla' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Sonipat', label: 'Sonipat' },
    { value: 'Srinagar', label: 'Srinagar' },
    { value: 'Surat', label: 'Surat' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'Tawang', label: 'Tawang' },
    { value: 'Thane', label: 'Thane' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
    { value: 'Thrissur', label: 'Thrissur' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Udaipur', label: 'Udaipur' },
    { value: 'Udhampur', label: 'Udhampur' },
    { value: 'Ujjain', label: 'Ujjain' },
    { value: 'UAE', label: 'UAE' },
    { value: 'USA', label: 'USA' },
    { value: 'Uttarkashi', label: 'Uttarkashi' },
    { value: 'Vadodara', label: 'Vadodara' },
    { value: 'Varanasi', label: 'Varanasi' },
    { value: 'Visakhapatnam', label: 'Visakhapatnam' },
    { value: 'Warangal', label: 'Warangal' },
    { value: 'Yavatmal', label: 'Yavatmal' },
    { value: 'Uttrakhand', label: 'Uttrakhand' },
    { value: 'West Bengal', label: 'West Bengal' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Himachal pradesh', label: 'Himachal pradesh' },
    { value: 'Other', label: 'Other' },

  ]
  const nicheOptions = [
    { value: 'Any', label: 'Any' },
    { value: 'Aesthetic Creators ', label: 'Aesthetic Creators' },
    { value: 'Beauty', label: 'Beauty' },
    { value: 'Bolloywood Celeb', label: 'Bolloywood Celeb' },
    { value: 'Chef', label: 'Chef' },
    { value: 'Fashion', label: 'Fashion' },
    { value: 'Skincare', label: 'Skincare' },
    { value: 'Haircare', label: 'Haircare' },
    { value: 'Dance', label: 'Dance' },
    { value: 'Lifestyle', label: 'Lifestyle' },
    { value: 'Food', label: 'Food' },
    { value: 'Blog Channels', label: 'Blog Channels' },
    
    { value: 'Cricketer', label: 'Cricketer' },
    { value: 'Comedy', label: 'Comedy' },
    { value: 'Content Creator', label: 'Content Creator' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Exclusive Creators', label: 'Exclusive Creators' },
    { value: 'Animation', label: 'Animation' },
    { value: 'Celebrities', label: 'Celebrities' },    
    { value: 'Doctor', label: 'Doctor' },
    { value: 'Engineer', label: 'Engineer' },
    { value: 'Environment', label: 'Environment' },
    { value: 'Education', label: 'Education' },
    { value: 'Earning Channels', label: 'Earning Channels' },
    { value: 'Fitness', label: 'Fitness'},    
    { value: 'LGBTQ', label: 'LGBTQ' },
    { value: 'Make-up', label: 'Make-up' },
    { value: 'Grooming', label: 'Grooming' },
    { value: 'Travel', label: 'Travel' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Food Blog', label: 'Food Blog' },
    { value: 'Recipes', label: 'Recipes' },
    { value: 'Football', label: 'Football' },
    { value: 'Freelancer', label: 'Freelancer' },
    { value: 'Video Creator', label: 'Video Creator' },
    { value: 'General', label: 'General' },
    { value: 'Games', label: 'Games' },
    { value: 'Home-Decor', label: 'Home-Decor' },
    { value: 'Home-Chef', label: 'Home-Chef' },
    { value: 'Humour', label: 'Humour' },
    { value: 'Haul-Creators', label: 'Haul-Creators' },
    { value: 'Influencer', label: 'Influencer' },
    { value: 'Lifestyle Parenting', label: 'Lifestyle Parenting' },
    { value: 'Mom Blogger', label: 'Mom Blogger' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Health', label: 'Health' },
    { value: 'Mom Influencer', label: 'Mom Influencer' },
    { value: 'Gaming', label: 'Gaming' },
    { value: 'Meme Pages', label: 'Meme Pages' },
    { value: 'Memers', label: 'Memers' },
    { value: 'Music', label: 'Music' },
    { value: 'Nutritionist', label: 'Nutritionist' },
    { value: 'Motivational Speaker', label: 'Motivational Speaker' },
    { value: 'Trading', label: 'Trading' },
    { value: 'Pet Creators', label: 'Pet Creators' },
    { value: 'Punjabi Celeb', label: 'Punjabi Celeb' },
    { value: 'Punjabi Singers', label: 'Punjabi Singers' },
    { value: 'Spritiuality', label: 'Spritiuality' },
    { value: 'Sustainable Living', label: 'Sustainable Living' },
    { value: 'South Celeb', label: 'South Celeb' },
    { value: 'South Creators', label: 'South Creators' },
    { value: 'TV Celebs', label: 'TV Celebs' },
    { value: 'Singers', label: 'Singers' },
    { value: 'Plant Based Creators', label: 'Plant Based Creators' },
    { value: 'Podcasts Creators', label: 'Podcasts Creators' },
    { value: 'Roast channels', label: 'Roast channels' },
    { value: 'Stand up', label: 'Stand up' },
    { value: 'Swimmer', label: 'Swimmer' },
    { value: 'Vegan Food Influencers', label: 'Vegan Food Influencers' },
    { value: 'Wildlife Rescuer,Lifestyle', label: 'Wildlife Rescuer,Lifestyle' },
  ]

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      console.log(session && session.user.id)
      fetchProfile(session.user.id)

    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      fetchProfile(session.user.id)
    })

    async function fetchProfile(userId) {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)

      if (error) {
        setError('Could not fetch data')
        setProfile(null)
      } else {
        setProfile(data)
      }
    }

  }, [])

  const handleOptionChange = (value, selectedOptions, setSelectedOptions, maxInputs) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else if (selectedOptions.length < maxInputs) {
      setSelectedOptions([...selectedOptions, value]);
    }
  };


  const handleCityChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setCities(selectedOptions);
  };

  const { filterState: { genderFilter, costFilter, searchQuery }, filterDispatch, userState } = CartState();


  async function handleSubmit(e) {
    e.preventDefault()
  
    if (platform === '' || campaign === '' || startdate === '' || enddate === '' ||
      promotion === '' || deliverable === '' || gender == [] || cities == [] || niche == []) {
      setFormError('Please fill in all the fields correctly.')
    } else {
      filterDispatch({
        type: "BY_GENDER",
        payload: gender
      })
    
      const { error } = await supabase
          .from('campaign')
          .insert({
            uid: session.user.id, 
            platform: platform,  
            campaign_name: campaignName,  
            campaign_type: campaign, 
            startdate: startdate, 
            enddate: enddate, 
            promotion: promotion, 
            deliverable: deliverable, 
            niche: niche, 
            followers: followers, 
            gender: gender,
            budgetlow: budget[0],
            budgethigh: budget[1],
            verified: false,
            location: cities,
            completed: false
          })

      if (error) {
        alert(error.error_description || error.message)
      }
      if (!error) {
        console.log(data[0])
        setIsOpen(true)
      }
    }
  }

  function handleNext() {
    if (progress <= 2 && progress >= 1) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setProgress(progress + 1)
      setFormError("")
    }
  }

  function handleBack() {
    if (progress <= 3 && progress >= 2) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setProgress(progress - 1)
      setFormError("")
    }
  }

  function handleSelect(data) {
     setSelectedOptions(data)
    //setSelectedOptions(Array.isArray(data) ? data.map(x => x.value) : []);
    setNiche((data.map(x => x.value) ))
  
  }
  function handleSelectLocation(data) {
    setselectedCity(data)
    setCities((data.map(x => x.value) ))
  
 }
 
  return (
    <div className='flex'>
      <div className='flex flex-col p-5 w-center w-75 p-3 items-start z-0' >

        <div className='flex flex-col w-center items-center '>

          <div className='flex md:flex-row flex-col gap-x-12 p-4 pt-10 justify-center items-center'>
            <div className={`rounded-2xl font-normal text-[14px] text-center text-black p-3 px-8 duration-300 ease-in-out md:my-0 my-3 ${progress === 1 ? 'bg-[#FFD397] text-black' : 'bg-[#FFF2E1] text-[#FFBD66]'}`}>General Information</div>
            <div className={`rounded-2xl font-normal text-[14px] text-center text-black p-3 px-8 duration-300 ease-in-out md:my-0 my-3 ${progress === 2 ? 'bg-[#FFD397] text-black' : 'bg-[#FFF2E1] text-[#FFBD66]'}`}>Additional Information</div>
            <div className={`rounded-2xl font-normal text-[14px] text-center text-black p-3 px-8 duration-300 ease-in-out md:my-0 my-3 ${progress === 3 ? 'bg-[#FFD397] text-black' : 'bg-[#FFF2E1] text-[#FFBD66]'}`}>Pricing and Location</div>
            <br />
            <span className='flex flex-col text-end text-[#FF0000] w-right font-DM Sans text-[12px] text-#7A1848red p-3 px-8'>
              *All the fields are required
            </span>
          </div>


          <div className='w-center' style={{ border: '1px solid rgba(93, 53, 253, 0.2)' }} >
            <form className='w-center w-400px h-700 relative text-center' onSubmit={handleSubmit}>
              {formError && <p className="error text-red-600 pt-4">{formError}</p>}
              {progress === 1 && (
                <>
                  <div style={{ border: '1px solid rgba(93, 53, 253, 0.2)' }}
                    className='flex flex-col text-center w-center h-258px'>
                    <h1 className='mb-4 font-semibold text-[20px]'>Do you want to enter Campaign Name : </h1>
                    <div className='flex md:flex-row  flex-col w-center h-30 md:px-35
                 sm:px-60 ss:px-35 xs:px-24 px-4 py-5'>
                      <InputField id="campaignName" type="text" value={campaignName}
                        onChange={(e) => setcampaignName(e.target.value)}
                      >
                      </InputField>
                    </div>

                  </div>
                  <div style={{ border: '1px solid rgba(93, 53, 253, 0.2)' }}
                    className='flex flex-col text-center w-center h-258px'>
                    <h1 className='mb-4 font-semibold text-[20px]'>1. Select Platform</h1>
                    <div
                      className='flex md:flex-row  flex-col w-center h-30 md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8'>

                      <RadioInput
                        name={'platform'}
                        value={'instagram'}
                        onChange={(e) => setPlatform(e.target.value)}
                        icon={instagram}
                        checked={platform === "instagram"}
                      />

                      <RadioInput
                        name={'platform'}
                        value={'youtube'}
                        onChange={(e) => {
                          setPlatform(e.target.value)
                          setDeliverable([])
                        }}
                        icon={youtube}
                        checked={platform === "youtube"}
                      />

                      <RadioInput
                        name={'platform'}
                        value={'twitter'}
                        onChange={(e) => {
                          setPlatform(e.target.value)
                          setDeliverable([])
                        }}
                        icon={twitter}
                        checked={platform === "twitter"}
                      />

                      <RadioInput
                        name={'platform'}
                        value={'linkedin'}
                        onChange={(e) => {
                          setPlatform(e.target.value)
                          setDeliverable([])
                        }}
                        icon={linkedin}
                        checked={platform === "linkedin"}
                      />

                      <RadioInput
                        name={'platform'}
                        value={'other'}
                        label={'other'}
                        onChange={(e) => {
                          setPlatform(e.target.value)
                          setDeliverable([])
                        }}

                        checked={platform === "other"}
                      />
                    </div>
                    <span className='flex flex-col text-end w-right'>
                      Select any 1 option
                    </span>
                  </div>
                  <div style={{ border: '1px solid rgba(93, 53, 253, 0.2)' }}
                    className='flex flex-col text-center w-center '>
                    <h1 className='mb-2 font-semibold text-[20px] '>2. Select Campaign Type</h1>
                    <div className='flex md:flex-row position:absolute
                   h-258px flex-col w-center md:px-10 sm:px-56 ss:px-32 xs:px-24 px-8 mb:10px py-5'>

                      <RadioInput
                        name={'campaign'}
                        value={'Paid'}
                        label={'Paid'}
                        onChange={(e) => setCampaign(e.target.value)}
                        checked={campaign === "Paid"}
                      />

                      <RadioInput
                        name={'campaign'}
                        value={'Barter'}
                        label={'Barter'}
                        onChange={(e) => setCampaign(e.target.value)}
                        checked={campaign === "Barter"}
                      />
                      <RadioInput
                        name={'campaign'}
                        value={'Affiliate Program'}
                        label={'Affiliate Program'}
                        onChange={(e) => setCampaign(e.target.value)}
                        checked={campaign === "Affiliate Program"}
                      />
                      <RadioInput
                        name={'campaign'}
                        value={'E-commerce Review'}
                        label={'E-commerce Review'}
                        onChange={(e) => setCampaign(e.target.value)}
                        checked={campaign === "E-commerce Review"}
                      />
                      <RadioInput
                        name={'campaign'}
                        value={'Brand Ambassador'}
                        label={'Brand Ambassador'}
                        onChange={(e) => setCampaign(e.target.value)}
                        checked={campaign === "Brand Ambassador"}
                      />

                    </div>
                  </div>
                  <div style={{ border: '1px solid rgba(93, 53, 253, 0.2)' }}
                    className='flex flex-col  text-center w-center'>
                    <h1 className='mb-3 font-semibold text-[20px]'>3. Timeline</h1>
                    <div className='flex md:flex-row flex-col w-center justify-center
                 md:px-0 sm:px-56 ss:px-32 xs:px-24'>

                      <input
                        type="date"
                        id='start'
                        value={startdate}
                        onChange={(e) => setStartdate(e.target.value)}
                        className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx-8 my-4'
                      />

                      <input
                        type="date"
                        id='end'
                        value={enddate}
                        onChange={(e) => setEnddate(e.target.value)}
                        className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx-8 my-4'
                      />
                    </div>
                  </div>
                </>
              )}
              {progress === 2 && (
                <>
                  <div style={{ border: '1px solid rgba(93, 53, 253, 0.2)' }}
                    className='flex flex-col  text-center w-center'>
                    <h1 className='mb-4 font-semibold text-[20px]'>4. What Are You Promoting?</h1>
                    <div className='flex md:flex-row flex-col w-center 
                    md:px-24 sm:px-56 ss:px-32 xs:px-24 px-8 py-5'>

                      <RadioInput
                        name={'promotion'}
                        value={'Cosmetics'}
                        label={'Cosmetics'}
                        onChange={(e) => setPromotion(e.target.value)}
                        icon={cosmetics}
                        title={'Cosmetics'}
                      >Cosmetics</RadioInput>
                      <RadioInput
                        name={'promotion'}
                        value={'Accessories'}
                        label={'Accessories'}
                        onChange={(e) => setPromotion(e.target.value)}
                        icon={accesories}
                      />
                      <RadioInput
                        name={'promotion'}
                        value={'Fashion'}
                        label={'Fashion'}
                        onChange={(e) => setPromotion(e.target.value)}
                        icon={fashion}
                      />
                      <RadioInput
                        name={'promotion'}
                        value={'Food and Beverages'}
                        label={'Food and Beverages'}
                        onChange={(e) => setPromotion(e.target.value)}
                        icon={foodnbeverages}
                      />
                      <RadioInput
                        name={'promotion'}
                        value={'other'}
                        label={'other'}
                        onChange={(e) => setPromotion(e.target.value)}
                    />
                    </div>
                  </div>


                  {platform === 'instagram' && (
                    <div style={{ border: '1px solid rgba(93, 53, 253, 0.2)' }}
                      className='flex flex-col  text-center w-center'>
                      <h1 className='mb-4 font-semibold text-[20px]'>5. Deliverables</h1>
                      <div className='flex md:flex-row flex-col w-center 
                           md:px-24 sm:px-56 ss:px-32 xs:px-24 px-8 py-5'>

                        <CheckboxInput
                          name={'deliverable'}
                          value={'Reels'}
                          label={'Reels'}
                          onChange={(e) => handleOptionChange(e.target.value, deliverable, setDeliverable, 4)}
                          checked={deliverable.includes("Reels")}
                        />
                        <CheckboxInput
                          name={'deliverable'}
                          value={'Story'}
                          label={'Story'}
                          onChange={(e) => handleOptionChange(e.target.value, deliverable, setDeliverable, 4)}
                          checked={deliverable.includes("Story")}
                        />
                        <CheckboxInput
                          name={'deliverable'}
                          value={'Video Story'}
                          label={'Video Story'}
                          onChange={(e) => handleOptionChange(e.target.value, deliverable, setDeliverable, 4)}
                          checked={deliverable.includes("Video Story")}
                        />
                        <CheckboxInput
                          name={'deliverable'}
                          value={'igtv'}
                          label={'igtv'}
                          onChange={(e) => handleOptionChange(e.target.value, deliverable, setDeliverable, 4)}
                          checked={deliverable.includes("igtv")}
                        />
                        <CheckboxInput
                          name={'deliverable'}
                          value={'Static post'}
                          label={'Static post'}
                          onChange={(e) => handleOptionChange(e.target.value, deliverable, setDeliverable, 4)}
                          checked={deliverable.includes("Static post")}
                        />
                        <CheckboxInput
                          name={'deliverable'}
                          value={'carousel'}
                          label={'carousel'}
                          onChange={(e) => handleOptionChange(e.target.value, deliverable, setDeliverable, 4)}
                          checked={deliverable.includes("Carousel")}
                        />
                      </div>
                    </div>
                  )}

                  {platform === 'youtube' && (
                    <div style={{ border: '1px solid rgba(93, 53, 253, 0.2)' }}
                      className='flex flex-col  text-center w-full'>
                      <h1 className='mb-4 font-semibold text-[20px]'>5. Deliverables</h1>
                      <div className='flex md:flex-row flex-col w-full
                      md:px-24 sm:px-56 ss:px-32 xs:px-24 px-8 py-5'>

                        <CheckboxInput
                          name={'deliverable'}
                          value={'Integration video'}
                          label={'Integration video'}
                          onChange={(e) => handleOptionChange(e.target.value, deliverable, setDeliverable, 4)}
                          checked={deliverable.includes("Integration video")}
                        />
                        <CheckboxInput
                          name={'deliverable'}
                          value={'Dedicated video'}
                          label={'Dedicated video'}
                          onChange={(e) => handleOptionChange(e.target.value, deliverable, setDeliverable, 4)}
                          checked={deliverable.includes("Dedicated video")}
                        />
                        <CheckboxInput
                          name={'deliverable'}
                          value={'Youtube shorts'}
                          label={'Youtube shorts'}
                          onChange={(e) => handleOptionChange(e.target.value, deliverable, setDeliverable, 4)}
                          checked={deliverable.includes("Youtube shorts")}
                        />
                      </div>
                    </div>
                  )}
                  {platform === 'twitter' && (
                    <div style={{ border: '1px solid rgba(93, 53, 253, 0.2)' }}
                      className='flex flex-col  text-center w-full'>
                      <h1 className='mb-4 font-semibold text-[20px]'>5. Deliverables</h1>
                      <div className='flex md:flex-row flex-col w-full
                       md:px-24 sm:px-56 ss:px-32 xs:px-24 px-8 py-5'>

                        <CheckboxInput
                          name={'deliverable'}
                          value={'tweet static'}
                          label={'Tweet Static'}
                          onChange={(e) => handleOptionChange(e.target.value, deliverable, setDeliverable, 4)}
                          checked={deliverable.includes("Tweet Static")}
                        />
                        <CheckboxInput
                          name={'deliverable'}
                          value={'Video Tweet'}
                          label={'Video Tweet'}
                          onChange={(e) => handleOptionChange(e.target.value, deliverable, setDeliverable, 4)}
                          checked={deliverable.includes("Video Tweet")}
                        />
                      </div>
                    </div>
                  )}

                  <div style={{ border: '1px solid rgba(93, 53, 253, 0.2)' }}
                    className='flex flex-col  text-center w-full'>
                    <h1 className='mb-4 font-semibold text-[20px]'>6. Select Creator’s Niche</h1>
                    <div
                      className='flex md:flex-row flex-col w-center text-center
                    item-justify md:px-35 sm:px-60 ss:px-35 xs:px-30 px-8 py-5'>
                     
                      <Select
                        options={nicheOptions}
                        placeholder="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Select Niche     "
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti
                       // fullWidth={true}
                        name="niche"
                        className='w-[400px] text-left'
                      />
                    </div>
                  </div>

                  <div
                    className='flex  flex-col justify-center items-center md:px-40 sm:px-56 ss:px-32 xs:px-24 px-8'>

                    <div
                      className='flex flex-col text-center w-full '>
                      <h1 className='mb-6 font-semibold text-[20px]'>7. Followers Range</h1>
                      <div className='flex flex-row w-full justify-center '>
                        <div className='md:w-96 w-52'>
                          <Range
                            step={1}
                            min={500}
                            max={6000000}
                            values={followers}
                            onChange={(e) => setFollowers(e)}
                            renderTrack={({ props, children }) => (
                              <div {...props} className="h-2 flex w-full bg-gray-300 rounded-md">
                                {children}
                                <div
                                  className="bg-[#D10269] rounded-full"
                                  style={{
                                    position: 'absolute',
                                    width: `${(followers[1] - followers[0]) * 100 / (6000000 - 500)}%`,
                                    height: '100%',
                                    left: `${(followers[0] - 500) * 100 / (6000000 - 500)}%`,
                                    zIndex: -1,

                                  }}
                                />
                              </div>
                            )}
                            renderThumb={({ props }) => (
                              <div
                                {...props}
                                className="h-6 w-6 bg-white rounded-full shadow flex justify-center items-center"
                              >
                                <div className="h-5 w-5 bg-white rounded-full border-purple-400" />
                              </div>
                            )}
                          />
                          <p className="mt-4">Followers: {followers[0]} - {followers[1]}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      className='flex flex-col text-center w-full'>
                      <h1 className='font-semibold text-[20px]'>8. Gender</h1>
                      <div className='flex md:flex-row flex-col w-full '>
                        <CheckboxInput
                          name={'gender'}
                          value={'Male'}
                          label={'Male'}
                          onChange={(e) => handleOptionChange(e.target.value, gender, setGender, 3)}
                          checked={gender.includes("Male")}
                        />
                        <CheckboxInput
                          name={'gender'}
                          value={'Female'}
                          label={'Female'}
                          onChange={(e) => handleOptionChange(e.target.value, gender, setGender, 3)}
                          checked={gender.includes("Female")}
                        />
                        <CheckboxInput
                          name={'gender'}
                          value={'Other'}
                          label={'Other'}
                          onChange={(e) => handleOptionChange(e.target.value, gender, setGender, 3)}
                          checked={gender.includes("Other")}
                        />
                      </div>
                    </div>
                  </div>

                </>
              )}

              {progress === 3 && (

                <>

                  {profile &&


                    <div style={{ border: '1px solid rgba(93, 53, 253, 0.2) w-400px h-700' }}
                      className={profile[0].type === 'agency' ?
                        'hidden' :
                        'flex flex-col  text-center w-full'
                      }>
                      <h1 className='mb-4 font-semibold text-[20px]'>9. Budget</h1>
                      <div className='flex flex-col w-full 
                    md:px-8 sm:px-56 ss:px-32 xs:px-24 px-8 items-center'>

                        <div className='md:w-96 w-52'>
                          <Range
                            step={1}
                            min={500}
                            max={5000000}
                            values={budget}
                            onChange={(e) => setBudget(e)}
                            renderTrack={({ props, children }) => (
                              <div {...props} className="h-2 flex w-full bg-gray-300 rounded-md">
                                {children}
                                <div
                                  className="bg-[#D10269] rounded-full"
                                  style={{
                                    position: 'absolute',
                                    width: `${(budget[1] - budget[0]) * 100 / (5000000 - 500)}%`,
                                    height: '100%',
                                    left: `${(budget[0] - 500) * 100 / (5000000 - 500)}%`,
                                    zIndex: -1,

                                  }}
                                />
                              </div>
                            )}
                            renderThumb={({ props }) => (
                              <div {...props} className="h-6 w-6 bg-white rounded-full shadow flex justify-center items-center"
                              >
                                <div className="h-5 w-5 bg-white rounded-full border-purple-400" />
                              </div>
                            )}
                          />
                        </div>
                        <p className="mt-2">Budget: {budget[0]} - {budget[1]}</p>
                      </div>
                    </div>
                  }

                  <div style={{ border: '1px solid rgba(93, 53, 253, 0.2) w-650px' }}
                    className='flex flex-col  text-center justify-center py-5 '>
                    <h1 className='mb-4 font-semibold text-[20px]'>10. Location</h1>
                    <div className='flex flex-row w-400px justify-center md:px-0 sm:px-56 ss:px-32 xs:px-24 px-8'>
                      <div style={{ width: "400px" }}>
                      <Select
                        options={cityOptions}
                        placeholder="Select Locations     "
                        value={selectedCity}
                        onChange={handleSelectLocation}
                        isSearchable={true}
                        isMulti
                        fullWidth={true}
                        name="cities"
                        className='w-[400px] text-left'
                      />
                      
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col text-center justify-center items-center md:px-8 sm:px-56 ss:px-32 xs:px-24 px-8'>

                    <button className='bg-[#D10269] text-white sm:p-3 sm:px-16 p-2 px-8 rounded-md'>Apply</button>
                  </div>
                </>
              )}

            </form>
            <div className='flex flex-row my-6 text-center items-center justify-between px-10 w-full'>
              {progress === 1 ? null :
                <button
                  className=' text-[#D10269] font-bold flex flex-row items-center sm:text-[20px] text-[15px]'
                  onClick={handleBack}><span className='pr-1'><AiOutlineArrowLeft /></span> <span>Back</span></button>}

              {progress <= 2 ?
                <button
                  className='bg-[#D10269] text-white sm:p-3 sm:px-16 p-2 px-8 rounded-md'
                  onClick={handleNext}>Next</button>
                : null}

            </div>
          </div>
        </div>
      </div>

      <CardModal open={isOpen} onCLose={() => { setIsOpen(false) }} showClose={true}>
        <ApproveModalUI
          title={'Your Campaign filters has been set!'}
          subtitle={'Please click on confirm, you will be redirected to the campaigns page. Then click on complete campaign to select influencers and submit your campaign request!'}
          handleApprove={() => {
            navigate('/dashboard/campaign')
          }}
        />
      </CardModal>
    </div>
  )
}

export default CampaignType