import React, { useState } from "react";
import styles from "../styles";
import Hero from "../components/Hero";
import Nav from "../components/Nav";
import navLogo2 from "../assets/navlogo2.png";
import B2 from "../assets/B2new.png";
import Footer from "../components/Footer";
import InputField from "../components/InputField";
import { supabase } from '../utils/supaBase';
import CardModal from '../components/CardModal';

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [business, setbusiness] = useState("");
  const [description, setDescription] = useState("");
  const [isOpen, setIsOpen] = useState();
  const [formError, setFormError] = useState(null);
  const [error, setError] = useState();
  async function handleSubmit(event) {
    event.preventDefault()

    if (name === '' || email === '' || phone === '' || business === ''||description === '') {
        setFormError('Please fill all fields correctly')
    } else {
        console.log(name, email, phone,business,description)

        const { error } = await supabase
        .from('contact_request')
        .insert({ 
          name: name, 
          email: email,
          phone: phone,
          business_type: business,
          //created_at : Date.now(),
          description : description,
          status : 'Request'
        })
         if (error) {
          setError(error.error_description)
          alert(error.message)
         } 

         setIsOpen(true)
    }
  }


  return (
    <div className="bg-white">
      <Nav />
      <div className="md:px-43 sm:px-16 px-6 bg-white">
        <section
          id="home"
          className={`flex md:h-[100vh] md:flex-row flex-col bg-[url('/src/assets/home1.png')] bg-center bg-cover ${styles.paddingY} items-center w-full relative`}
        >
          <div
            className={`flex md:h-[100vh] md:flex-row flex-col items-center w-full relative`}
          >
            <div
              className={`absolute animate-[none]
                         w-[250px] h-[40px] md:top-10px top-[1px]
                        left-[4%] md:left-[10%]`}
            >
              <h1 className="font-[Cormorant Garamond] font-normal text-[25px] font-bold text-black text-left ">
                Contact Us
              </h1>
              <span className="font-[DM Sans] w-[100px] text-[14px]  lg:w-[200px] lg:text-[15px]">
                Connect with our experts to learn
                <br />
                how Brandlytical can help your <br />
                brand drive meaningful results
              </span>
            </div>
            <div
              className={`absolute animate-[bounce_3s_ease-in-out_infinite] bg-[#5D35FD] 
              rounded-full w-[60px] h-[60px] md:top-12.5px top-[12.5px] lg:w-[50px] lg:h-[50px]
                 left-[0.5%] md:left-[0.5%] bg-opacity-40`}
            ></div>
            <div
              className={`absolute animate-[bounce_3s_ease-in-out_infinite] 
            bg-[#5D35FD] rounded-full w-[25px] h-[25px] 
                md:top-15.9 top-[30.9px] md:left-[4%] left-[9%] lg:left-[2.5%] sm:left-[5%]`}
            ></div>
            <div
              className="font-bold text-2xl cursor-pointer font-[DM Sans] 
                text-gray-800 ml-[270px] "
            >
              <div classname="top-[60px] md:top-[40px]">
                <img
                  src={B2}
                  className="xs:px:140px md:w-[80px] md:h-[100px] w-[80px] left-[80%] md:left-[80%] ml-[2px] lg:ml-[60px] md:ml-[60px] sm:ml-[60px] ss:ml-[60px] xs:ml-[43px]"
                />
                <img
                  src={navLogo2}
                  className="w-[200px] h-[34px] left-[1%] md:left-[1%]"
                />
              </div>
            </div>
          </div>

          <div
            className="bg-pink-900 md:px-100 sm:px-100 px-8 mt-[10px] xs:mt-[10px] lg:mr-[200px] md:mr-[100px] 
            lg:w-[650px] md:w-[550px]  flex flex-col w-[300px] h-[460px] xs:w-[380px] xs:h-[558px] xxs:w-[300px] xxs:h-[560px]
             lg:w-[400px] lg:h-[540px] sm:w-[400px] sm:h-[570px] md:w-[400px] md:h-[550px] xl:w-[600px] xl:h-[650px]
        items-center justify-center p-[25px] rounded-lg shadow-bs lg:mt-[15px] xs:mt-[150px] md:mt-[10px]  "
          >
            <form onSubmit={handleSubmit} className='flex flex-col items-center mb-5 pb-5'>
            <span className="text-white font-[DM Sans] text-[25px] lg:text-[20px] md:text-[20px]">
              Talk to us
            </span>
            <div className="text-black text-[10px] w-[80%] lg:text-[10px] lg:w-[100%] flex flex-col items-center justify-center">
              <InputField
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border-[1px]  border-[#F0F0F0] rounded-md p-2 border-opacity-40 "
                label="Name"
              />

              <InputField
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border-[1px] border-[#7A1848] rounded-md p-3 border-opacity-40"
                label="Email"
              />

              <InputField
                type="text"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="border-[1px] border-[#7A1848] rounded-md p-3 border-opacity-40"
                label="Phone No."
              />

              <InputField
                type="text"
                id="business"
                value={business}
                onChange={(e) => setbusiness(e.target.value)}
                className="border-[1px] border-[#7A1848] rounded-md p-3 border-opacity-40"
                label="Businness Type"
              />

              <InputField
                type="text"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="border-[1px] border-[#7A1848] rounded-md p-3 border-opacity-40"
                label="Describe your requirement Type"
              />
              <button
                className="mt-4 bg-[#FFC9E4] p-2 px-10 lg:mt-[20px] lg:px-7 lg:text-[13px]  
                  rounded-sm  text-black sm:text-[15px] text-[12px] md:mt-[8px] md:px-7 md:text-[10px] "
              >
                Submit
              </button>
              {formError && <p className="error text-red-600 pt-4">{formError}</p>}
            </div>
            </form>
           
            <CardModal open={isOpen} onCLose={() => {setIsOpen(false)}} showClose={false}>
        <p className='font-poppins font-bold'>{error ? error : 'Are you sure to send enquiry !'}</p>
        <button className='mt-10 bg-[#D10269] p-2 px-10 
        rounded-sm  text-white sm:text-[15px] text-[12px]'
        onClick={() => {
          window.location.reload();
        }}
        >OK</button>
        </CardModal>
          </div>
        </section>
      </div>
      <div className="mt-2 bg-white" id="footer">
        <Footer />
      </div>
    </div>
  );
};
export default ContactUs;




