import React from 'react';

const ColorfulBar = ({ value, maxValue }) => {
  // Calculate the width of the bar as a percentage
  const widthPercentage = (value / maxValue) * 100;

  // Define a CSS class for the bar based on its value
  let colorfulbase = 'colorful-base';
  let barClass = 'colorful-bar';

  if (value < 800) {
    barClass = 'colorful-bar low-value';
  } else if (value < 2500) {
    barClass = 'colorful-bar medium-value';
  } else if (value < 5000) {
    barClass = 'colorful-bar high-value';
  }

  return (
    <div className={colorfulbase} style={{ width: `${maxValue}` }}>
    <div className={barClass} style={{ width: `${widthPercentage}%` }}>
      
    </div>
    </div>
  );
};

export default ColorfulBar;
