import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { supabase } from "../utils/supaBase";

const ViewCampaigns = () => {
  const [campaigns, setCampaigns] = useState(null);
  const [error1, setError] = useState(null);
  const location = useLocation();
  const profileid = location.state.profileid;

  useEffect(() => {
    async function fetchCampaigns() {
      const { data, error } = await supabase
        .from("campaign_list")
        .select("*")
        .eq("uid", profileid);

      if (error) {
        setError("Could not fetch data");
        console.log(error1,error1.message)
        setCampaigns(null);
      } else {
        setCampaigns(data);
        setError(null);
        console.log(data);
      }
    }

    fetchCampaigns();
  }, []);

  return (
    <>
      <h1 className="font-poppins text-xl mb-4 ">CAMPAIGN DETAILS</h1>
      <div className="flex flex-col p-4 bg-[#FFF7F1] rounded-md mb-5 w-[max-content]">
        <div className="flex flex-col justify-self-end font-poppins gap-y-1 ">
          <table style={{ borderCollapse: "collapse", width: "max-content" }}>
            <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
              <th style={{ border: "2px solid #dddddd" }}>Campaign Name </th>
              <th style={{ border: "2px solid #dddddd" }}>Start date</th>
              <th style={{ border: "2px solid #dddddd" }}>End date </th>
              <th style={{ border: "2px solid #dddddd" }}>Deliverable </th>
              <th style={{ border: "2px solid #dddddd" }}>Niche</th>
              <th style={{ border: "2px solid #dddddd" }}>Budget</th>
              <th style={{ border: "2px solid #dddddd" }}>Status</th>
            </tr>
            {campaigns &&
              campaigns.map((campaign) => (
                <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                  <td style={{ border: "2px solid #dddddd", padding: "5px" }}>
                    {campaign.campaign_name}
                  </td>
                  <td style={{ border: "2px solid #dddddd", padding: "5px" }}>
                    {campaign.startdate}
                  </td>
                  <td style={{ border: "2px solid #dddddd", padding: "5px" }}>
                    {campaign.enddate}
                  </td>
                  <td style={{ border: "2px solid #dddddd", padding: "5px" }}>
                    {campaign.deliverable}
                  </td>
                  <td style={{ border: "2px solid #dddddd", padding: "5px" }}>
                    {campaign.niche}
                  </td>
                  <td style={{ border: "2px solid #dddddd", padding: "5px" }}>
                    {campaign.budgetlow}-{campaign.budgethigh}
                  </td>
                  <td style={{ border: "2px solid #dddddd", padding: "5px" }}>
                    {!campaign.verified &&
                    !campaign.completed &&
                    !campaign.finished
                      ? "Influencer not Added"
                      : campaign.completed &&
                        !campaign.verified &&
                        !campaign.finished
                      ? "Pending for verification"
                      : campaign.completed &&
                        campaign.verified &&
                        !campaign.finished
                      ? "Active campaign"
                      : campaign.completed &&
                        campaign.verified &&
                        campaign.finished
                      ? "Completed"
                      : "-"}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewCampaigns;
