import React from "react";
import { supabase } from "../utils/supaBase";
import { useState, useEffect } from "react";
import {AiOutlineUpload} from 'react-icons/ai'

const ApprovalHub = () => {
  const [campaign, setCampaigns] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    async function fetchCampaigns() {
      const { data, error } = await supabase
        .from("campaign_list")
        .select("*")
        // .eq('verified', true)
        .neq("campaign_type", "SPONSORSHIP")
        .neq("campaign_type", "SONG_INTEGRATION");

      if (error) {
        setError("Could not fetch data");
        setCampaigns([]);
      } else {
        setCampaigns(data);
        setError(null);
        console.log(data);
      }
    }

    fetchCampaigns();
  }, []);

  return (
    <div className="items-left flex flex-col w-full justify-start ">
      <div className="flex flex-row mb-[20px]">
        <div className="ml-[200px]">
          <select
            style={{
              width: "200px ",
              background: "#E879B0",
              padding: "6px",
              borderRadius: "3px",
            }}
          >
            <option value="">Filter by brand</option>
            <option value="">Filter by brand 1</option>
            <option value="">Filter by brand 2</option>
          </select>
        </div>
        <div className="ml-[100px]">
          <select
            style={{
              width: "200px ",
              background: "#E879B0",
              padding: "6px",
              borderRadius: "3px",
            }}
          >
            <option value="">Filter by campaign status</option>
            <option value="">Filter by campaign status 1</option>
            <option value="">Filter by campaign status 2</option>
          </select>
        </div>
      </div>

      <div>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <tr>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
                width: "20%",
              }}
            >
              BRAND NAME
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
                width: "20%",
              }}
            >
              NICHE/CATEGORY
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
                width: "10%",
              }}
            >
              STATUS
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
                width: "10%",
              }}
            >
              REPORT
            </th>
            <th
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
                width: "10%",
              }}
            ></th>
          </tr>
          {campaign != "" ? (
            campaign.map((campaign) => {
              return (
                <tr>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    {campaign.agency_name}
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    {campaign.niche}
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                  
                    <select>
                    <option value="">Select a Status</option>
                      <option value="">Approved</option>
                      <option value="">Declined</option>
                      <option value="">Reviewed</option>
                    </select>
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "center",
                      justifyContent:"center",
                      padding: "8px",
                      display:"flex",
                      background:"#D10269",
                      color:"white"
                    }}
                  >Upload &nbsp;&nbsp; <AiOutlineUpload/></td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  ></td>
                </tr>
              );
            })
          ) : (
            <div>
              <span
                style={{ textTransform: "capitalize", textAlign: "center" }}
                classname="font-dmSans sm:text-[10px] text-[10px] font-bold py-1"
              >
                No Data Available
              </span>
            </div>
          )}
        </table>
      </div>
    </div>
  );
};

export default ApprovalHub;