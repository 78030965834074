import React, { useEffect, useState } from 'react'
import { supabase } from '../utils/supaBase';
import CompleteCampaign from '../components/CompleteCampaign';
import FinishedCampaignList from '../components/FinishedCampaignList';

const UserDashboardCampain = () => {

  const [campaigns, setCampaigns] = useState(null)
  const [error, setError] = useState(null)
  const [session, setSession] = useState(null)

  useEffect(() => {

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      
      async function fetchCampaigns() {
        const { data, error } = await supabase
        .from('campaign')
        .select('*')
        .eq('uid', session.user.id)
        .neq('campaign_type','SONG_INTEGRATION')
        .neq('campaign_type','SPONSORSHIP')
    
      if (error) {
        setError('Could not fetch data')
        setCampaigns(null)
        
      } else {
        setCampaigns(data) 
        setError(null)
      }
      }
  
      fetchCampaigns();
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  },[])
  return (
    <div className='flex flex-col w-[1000px]'>
      <h1 className='font-poppins text-xl md:text-start text-center left-100px'>To Complete Campaigns</h1>

        <div className={`flex md:flex-row flex-col overflow-x-scroll md:scrollbar
        scrollbar-thumb-gray-500 scrollbar-track-gray-200 scrollbar-rounded-md
         lg:w-[1180px] md:w-[1000px] md:max-h-[1000px] max-h-[2000px] justify-start py-8 mb-20`}>
        {campaigns && campaigns.map((item) => {
          if(!item.completed  ) {
            return <CompleteCampaign campaign={item} active={true}/>
          }
          
        })}
        </div>
            <h1 className='font-poppins text-xl mt-1 md:text-start text-center'>Active Campaigns</h1>
              <div className='flex md:flex-row flex-col overflow-x-scroll md:scrollbar 
        scrollbar-thumb-gray-500 scrollbar-track-gray-200
        lg:w-[1200px] md:w-[1000px] md:max-h-[315px] max-h-[2000px]  justify-start py-5 mb-20'>
        {campaigns && campaigns.map((item) => {
          if(item.verified && !item.finished) {
            return <CompleteCampaign campaign={item} active={false}/>
          }
        })}
        </div>

        <h1 className='font-poppins text-xl mt-1 md:text-start text-center'>Past Campaigns</h1>

        <div className='flex md:flex-row flex-col overflow-x-scroll md:scrollbar 
        scrollbar-thumb-gray-500 scrollbar-track-gray-200
        lg:w-[1200px] md:w-[1000px] md:max-h-[500px] max-h-[2000px]  justify-start py-5'>
        {campaigns && campaigns.map((item) => {
          if(item.finished ) {
           // return <CompleteCampaign campaign={item} active={false}/>
           return <FinishedCampaignList campaign={item} status={true}/>
          }
        })}
        </div>
        
    </div>
  )
}

export default UserDashboardCampain;