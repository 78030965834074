import React, { useEffect } from 'react'
import { supabase } from '../utils/supaBase'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import ApproveModalUI from '../components/ApproveModalUI';
import { useNavigate } from 'react-router-dom';
import CardModal from './CardModal'
import { data } from 'autoprefixer';

const UpdateSponsorship = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [integrationdetails, setCampaigns] = useState([]);
    const [error, setError] = useState();
    const campaignId = location.state.campaignId

    const[eventTitle, setEventTitle] = useState('');
    const[description, setDescription] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [formError, setFormError] = useState("");
    
    const[status, setsstatus] = useState('');

    useEffect(() =>{

        async function fetchCampaigns() {
          const { data, error } = await supabase
            .from('integration_details')
            .select('*')
            .eq('campaign_id', campaignId);
             console.log("integration details"+data);
          if (error) {
            setError('Could not fetch data');
            setCampaigns([]);
          } else {
            setCampaigns(data);
            setError(null);
            console.log(setCampaigns);
          }
        }
        
        setEventTitle(integrationdetails.title);
        setsstatus(integrationdetails.status);

        fetchCampaigns();
    
      }, [])
      async function handleSubmit(e) {
       e.preventDefault();
        
        if (eventTitle === '' || description === '') {
        setFormError('Please fill in all the fields correctly.')
    } else {
      
     const { error } = await supabase
      .from('integration_details')
      .update({
        title: eventTitle,  
        description:description, 
        status: status,
       })
       .eq("campaign_id",campaignId)

      if (error) {
        alert(error.error_description || error.message)
      }
      if(!error) {
        console.log(data[0])
        setIsOpen(true)
      }
      }
    }
        return(
            <form className='w-center w-full h-700 relative text-center item-center ' onSubmit={handleSubmit}>
            <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
            className='flex md:flex-row  flex-col w-center h-30 md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8' >
            <h2 className='mb-4 font-semibold text-[20px]'>Update Event Sponsorship Details</h2>
            </div>
            <table className="w-full font-semibold text-[20px]" 
            style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}>
            
            {integrationdetails.map(integrationdetails => (
                <div className="w-full">
               <tr>
                <td >
                <span>Event Title</span>
                </td><td>
                &nbsp;&nbsp;
                <input
                            name="eventTitle"
                            type="text"
                            defaultValue={integrationdetails.title}
                            onChange={(e) => setEventTitle(e.target.value)}
                            className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                            />
                  </td>
                  <td>
                <span>Status</span>
                </td><td>
                <select name='status' defaultValue={integrationdetails.status}
                onChange={(e)=>setsstatus(e.target.value)}
                 className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'>
                <option   value="ACTIVE">ACTIVE</option>
                <option value="CLOSE">CLOSE</option>
                </select>
               
               </td>
               </tr>
               <tr>
                <td >
                <span>Description</span>&nbsp;&nbsp;
                </td>
                <td colspan="3">
                <input
                            type='text'
                            name='description'
                            defaultValue={integrationdetails.description}
                            onChange={(e) => setDescription(e.target.value)}
                            className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3 overflow-y-scroll flex-wrap paddingTop:2px'
                            style={{width:"600px",height:"200px",textAlign:"top"}}
                            />
                            
                    </td>
            </tr>
           
            <tr>
          
            </tr>
            </div>
          ))}
              <tr>
                <td>
                <div className='flex flex-col text-center justify-center items-center md:px-8 sm:px-56 ss:px-32 xs:px-24 px-8'>
                
                <button className='bg-[#D10269] text-white sm:p-3 sm:px-16 p-2 px-8 rounded-md'>Update</button>
                </div>
                <CardModal open={isOpen} onCLose={() => {setIsOpen(false)}} showClose={true}>
                <ApproveModalUI
                    title={'ARE YOU SURE YOU WANT TO Update?'}
                    subtitle={'If you choose to update, all te details should be updated!'}
                    handleApprove={() => {
                    navigate('../sponsorshipdeals')
                    }}
                />
                </CardModal>
                </td>
              </tr>
            </table>
          </form>
        )
}
export default UpdateSponsorship;