import React, { useEffect, useState } from 'react'
import { supabase } from '../utils/supaBase';
import DropDown from './DropDown';
import CardModal from './CardModal';
import axios from 'axios'

const AdminReports = () => {

  const [profiles, setProfiles] = useState();
  const [created_for, setCreated_for] = useState();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [brandagencyOptions, setBrandAgencyOptions] = useState([]);
  const [session, setSession] = useState();
  const [created_by, setCreated_by] = useState();
  const [isOpen, setIsOpen] = useState();

  const [campaign, setCampaign] = useState("");
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const allowedTypes = ["text/csv", "text/pdf", "text/PDF","application/pdf"];
  const [selectedFileID, setSelectedFileID] = useState(null);
  useEffect(() => {

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      
      setCreated_by(session.user.id)

    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      setCreated_by(session.user.id)
    })

    async function fetchProfiles() {

      const { data, error1 } = await supabase
        .from('profiles')
        .select('*')
        .eq("verified", true)
        .in("type", ["brand", "agency"])
        .order("company_name", { ascending: true })

      if (error1) {
        setError('Could not fetch data')
        setProfiles(null)
      } else {
    
        setData(data)

      }

    }

    fetchProfiles();
  }, [])


  function setData(data) {
    const options = data.map(item => ({
      value: item.id,
      label: item.company_name + "-" + item.type
    }));

    setBrandAgencyOptions(options);
    setLoading(false);
    
  }

  async function setCampaignData(campaigns) {

    const { data, error } = await supabase
      .from("campaign")
      .select("*")
      .eq("uid", campaigns)
      .eq("completed", true)
      .eq("finished", false)

    if (error) {
      setError("Could not fetch data");
      setCampaignOptions(null);
    } else {
      
      const options = data.map((item) => ({
        value: item.id,
        label: item.campaign_name + " (" + item.campaign_type + ")",
      }));

      setCampaignOptions(options);
      setLoading(false);
      //console.log(campaignOptions);
    }
  }
  // Function to generate a new filename (customize it according to your needs)
function generateNewFileName(originalName) {
  const getTimestamp = (date = new Date()) => Math.floor(date.getTime() / 1000);
  // const timestamp = (new Date()).toISOString();
  const timestamp = getTimestamp();
  // const campId = campaign;
  const extension = originalName.split('.').pop();
  // return `${timestamp}_${campId}.${extension}`;
  return `${timestamp}.${extension}`;
}

  const handleFileChange = (e) => {
    const selectFile = e.target.files[0];
    const selectedFileType = selectFile?.type;    
    const extension = selectedFileType.split('/').pop();
    const newFileName = generateNewFileName(selectFile.name); 

    if (allowedTypes.includes(selectedFileType)) {
      setSelectedFile(e.target.files[0]);
    setSelectedFileID(newFileName)
    }
    else {
      alert("Only PDF and CSV files are allowed");
      e.target.value = "";
      setSelectedFileID("")
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData();
    
    const newfileName = selectedFileID; //value save in hidden text box
    
   // const filepath = '/src/reportfolder/'+ newfileName;
   const filepath =  newfileName;
    
    try {
      const { error } = await supabase
      .from('reports')
      .insert({
        created_by: created_by,
        created_for: created_for,
        campaign_id: campaign,
        created_at: ((new Date()).toISOString()).toLocaleString('zh-TW'),
        filename : selectedFile.name,
        filepath : filepath
      })
      if (error) {
        alert(error.error_description || error.message)
      }
      else{
        console.log("Report created")
      }
      console.log("campaign====="+campaign)

      const { error1 } = await supabase
      .from('campaign')
      .update({
        finished : true,
        status: "FINISHED",
      }).eq("id", campaign)

        if (error1) {
                alert(error1.error_description || error1.message)
              }
              else{
                console.log("Campaign Finished")
              }
      //data.append('file', selectedFile, selectedFile.name);
      data.append('file', selectedFile, newfileName);
      axios.post("https://brandlytical.in:8080/fileupload", data,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      ).then((res) => {
        console.log(res);
        console.log(">>>>>>>>>>>" + res.statusText+" "+res.statusMessage);
      })
        .catch(error => {
          alert('Error uploading file:'+error.message, error.error_description);
        });
    }
    catch (e) {
      alert(e)
    }

   
    if (error) {
      setError(error.error_description)
    }

    setIsOpen(true)

  }
  return (
    <div className='w-full md:px-20 sm:px-8 px-0 flex flex-col text-center'>

      <h1 className='font-poppins sm:text-2xl text-xl mb-8'>Create Report</h1>
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-10 w-full'>

        {
          !loading &&
          <DropDown
            selectoptions={brandagencyOptions}
            value={created_for}
            onChange={(e) => {
              setCreated_for(e.target.value);
              setCampaignData(e.target.value);
            }}
            label={'Select Brand/Agency'}
            fullWidth={true}
          />
        }
        <div className='flex flex-col  w-full'>

          <DropDown
            selectoptions={campaignOptions}
            value={campaign}
            onChange={(e) => {
              setCampaign(e.target.value);
            }}
            label={"Select Campaign"}
            fullWidth={true}
          />
        </div>

        <div className="mb-4 cursor-pointer mt-2 flex justify-center
         rounded-lg border border-dashed border-gray-900/25 px-6 py-10 w-[500px]">
          <input type="file" name="uploadfile"  accept=".csv,text/csv,.pdf,text/pdf"
                     onChange={handleFileChange}  />
          <input type="hidden" value={selectedFileID}  name="newfileNametext"></input>
          

        </div>

      </div>
      <div>
      <button className='mt-4 bg-green-400 p-2 px-4 rounded-lg mr-5 text-white sm:text-[15px] text-[12px]'
            onClick={handleSubmit}>Upload</button>
      </div>
      <CardModal open={isOpen} onCLose={() => { setIsOpen(false) }} showClose={false}>
        <p className='font-poppins font-bold'>{error ? error : 'Report succesfully sent!'}</p>
        <button className='mt-10 bg-[#D10269] p-2 px-10 
        rounded-sm  text-white sm:text-[15px] text-[12px]'
          onClick={() => {
            window.location.reload();
          }}
        >OK</button>
      </CardModal>
    </div>
  )
}

export default AdminReports