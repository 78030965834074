import React ,{useState}from "react";
import InputField from '../components/InputField';
import { Range } from 'react-range';
import CardModal from '../components/CardModal';
import ApproveModalUI from '../components/ApproveModalUI';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4, v4 } from "uuid";
import { supabase } from '../utils/supaBase';
import { data } from 'autoprefixer';



const SongIntegrationAdmin = () =>
{
    const navigate = useNavigate();
    const[songtitle, setsongtitle] = useState('');
    const[artist, setartist] = useState('');
    const[dateofshoot, setdateofshoot] = useState('');
    const[dateofclosing, setdateofclosing] = useState('');
    const [deliverable, setDeliverable] = useState([]);
    const [budget, setBudget] = useState([500, 100000]);
    const [isOpen, setIsOpen] = useState(false);
    const [formError, setFormError] = useState("");
    const[artistInvolved, setartistInvolved] = useState('');

    const handleOptionChange = (value, selectedOptions, setSelectedOptions, maxInputs) => {
        if (selectedOptions.includes(value)) {
          setSelectedOptions(selectedOptions.filter((option) => option !== value));
        } else if (selectedOptions.length < maxInputs) {
          setSelectedOptions([...selectedOptions, value]);
        }
      };
      async function handleSubmit(e) {
        e.preventDefault();
        //alert("Hello");
        if (songtitle === '' || artist === '' || dateofshoot === '' || dateofclosing === '' || 
     deliverable === '' ) {
        setFormError('Please fill in all the fields correctly.')
    } else {
      
      }
      console.log(e)
      const { error } = await supabase
      .from('integration_details')
      .insert({
        id: v4, 
        integration_type: "SONG_INTEGRATION",  
        title: songtitle,  
        artist: artist, 
        artist_involved: artistInvolved, 
        shoot_date: dateofshoot, 
        closing_date: dateofclosing, 
        deliverables: deliverable, 
        low_budget: budget[0], 
        high_budget: budget[1], 
        status: "ACTIVE",
       })

      if (error) {
        alert(error.error_description || error.message)
      }
      if(!error) {
        console.log(data[0])
        setIsOpen(true)
      }
    }
  
  return (
    <div >
   <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
    className='flex md:flex-row  flex-col w-center h-30 md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8' >
    <h2 className='mb-4 font-semibold text-[35px]'>Enter Song-Track Details</h2>
    </div>
    <form className='w-center w-400px h-700 relative text-left item-left' onSubmit={handleSubmit}>
    <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
    className='flex md:flex-row  
       flex-col w-center h-30 md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8 py-5' >
       <div>
       <span className="text-[25px] font-bold w-[200px]">Song Title</span>
       <InputField
                label=''
                id='songtitle'
                value={songtitle}
                onChange={(e) => setsongtitle(e.target.value)}
                className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3 '
                /></div>
                <div>&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div>
<span className="text-[25px] font-bold w-[200px]">Label</span>
<InputField
                label=''
                id='artist'
                value={artist}
                onChange={(e) => setartist(e.target.value)}
                className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx-8 my-4 '
                />
</div>

    </div>
    <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
    className='flex md:flex-row  flex-col w-justify h-30 md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8' >
       <div>
<span className="text-[25px] font-bold w-[200px]">Date of Shoot</span>
    <input 
       label='dateofshoot'
                type="date"
                id='dateofshoot'
                value={dateofshoot}
                onChange={(e) => setdateofshoot(e.target.value)}
                className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx-10 my-4'
                />
      </div>
     
    
     </div>
     <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
    className='flex md:flex-row  flex-col w-justify h-30 md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8
    item=left' >
  <div>
        <span className="text-[25px] font-bold w-[200px]">Date of Closing</span>
                <input 
                label='dateofclosing'
                type="date"
                id='dateofclosing'
                value={dateofclosing}
                onChange={(e) => setdateofclosing(e.target.value)}
                className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx-8 my-4'
                />
                </div>
    </div>
     <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
    className='flex md:flex-row  flex-col w-justify h-30 md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8 py-5
    item=left' >
      <div>
      <span className="text-[25px] font-bold w-[200px]">Artist Involved</span>
        <InputField
                label=''
                id='artistInvolved'
                value={artistInvolved}
                onChange={(e) => setartistInvolved(e.target.value)}
                className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx-8 my-4'
                />
      </div>
      <div>&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
       <div>
       <span className="text-[25px] font-bold w-[200px]">Deliverables</span>
        <InputField
                label=''
                id='deliverable'
                value={deliverable}
                onChange={(e) => setDeliverable(e.target.value)}
                className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx-8 my-4'
                />
       </div>
      
       </div>
       <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
         className='flex md:flex-row  flex-col w-justify h-50
          md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8 py-8' >
       <div>
       <span className="text-[25px] font-bold w-[200px]"> Budget</span>
       </div>
       <div className='md:w-96 w-52 py-10'>
                  <Range
                  step={1}
                  min={500}
                  max={5000000}
                  values={budget}
                  onChange={(e) => setBudget(e)}
                  renderTrack={({ props, children }) => (
                    <div {...props} className="h-2 flex w-full bg-gray-300 rounded-md">
                      {children}
                      <div
                        className="bg-[#D10269] rounded-full"
                        style={{
                          position: 'absolute',
                          width: `${(budget[1] - budget[0]) * 100 / (5000000 - 500)}%`,
                          height: '100%',
                          left: `${(budget[0] - 500) * 100 / (5000000 - 500)}%`,
                          zIndex: -1,
                          
                        }}
                      />
                    </div>
                  )}
                  renderThumb={({ props }) => (
        <div {...props} className="h-6 w-6 bg-white rounded-full shadow flex justify-center items-center"
                    >
                      <div className="h-5 w-5 bg-white rounded-full border-purple-400" />
                    </div>
                  )}
                />
                </div>
                <p className="mt-2">Budget: {budget[0]} - {budget[1]}</p>
       </div>
       <div className='flex flex-col text-center justify-center items-center md:px-8 sm:px-56 ss:px-32 xs:px-24 px-8'>
                
                <button className='bg-[#D10269] text-white sm:p-3 sm:px-16 p-2 px-8 rounded-md'>Add Song Details</button>
                </div>
      <CardModal open={isOpen} onCLose={() => {setIsOpen(false)}} showClose={true}>
      <ApproveModalUI
        title={'ARE YOU SURE YOU WANT TO ADD?'}
        subtitle={'If you choose to add, your integration deals will be visible for Brand/Agency !'}
        handleApprove={() => {
          navigate('/dashboardadmin')
        }}
      />
      </CardModal>
    </form>

    </div>
    
    
  )
}
export default SongIntegrationAdmin;