import React, { useEffect } from 'react'
import { supabase } from '../utils/supaBase'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import InputField from '../components/InputField';
import ApproveModalUI from '../components/ApproveModalUI';
import { useNavigate } from 'react-router-dom';
import { Range } from 'react-range';
import CardModal from './CardModal'
import { data } from 'autoprefixer';
const UpdateIntegration = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [integrationdetails, setCampaigns] = useState([]);
    const [error, setError] = useState();
    const campaignId = location.state.campaignId

    const[songtitle, setsongtitle] = useState('');
    const[artist, setartist] = useState('');
    const[dateofshoot, setdateofshoot] = useState('');
    const[dateofclosing, setdateofclosing] = useState('');
    const [deliverable, setDeliverable] = useState([]);
    const [budget, setBudget] = useState([500, 100000]);
    const [isOpen, setIsOpen] = useState(false);
    const [formError, setFormError] = useState("");
    const[artistInvolved, setartistInvolved] = useState('');
    const[status, setsstatus] = useState('');

    useEffect(() =>{

        async function fetchCampaigns() {
          const { data, error } = await supabase
            .from('integration_details')
            .select('*')
            .eq('campaign_id', campaignId);
             console.log("integration details"+data);
          if (error) {
            setError('Could not fetch data');
            setCampaigns([]);
          } else {
            setCampaigns(data);
            setError(null);
            console.log(setCampaigns);
          }
        }
        
        setsongtitle(integrationdetails.title);
        setartist(integrationdetails.artist);
        setdateofshoot(integrationdetails.dateofshoot);
        setdateofclosing(integrationdetails.dateofclosing);
        setDeliverable(integrationdetails.deliverables);
        setartistInvolved(integrationdetails.artistInvolved);
        setsstatus(integrationdetails.status);

      fetchCampaigns();
    
      }, [])
      async function handleSubmit(e) {
       e.preventDefault();
        
        if (songtitle === '' || artist === '' || dateofshoot === '' || dateofclosing === '' || 
     deliverable === '' ) {
        setFormError('Please fill in all the fields correctly.')
    } else {
      
     const { error } = await supabase
      .from('integration_details')
      .update({
        title: songtitle,  
        artist: artist, 
        artist_involved: artistInvolved, 
        shoot_date: dateofshoot, 
        closing_date: dateofclosing, 
        deliverables: deliverable, 
        low_budget: budget[0], 
        high_budget: budget[1], 
        status: status,
       })
       .eq("campaign_id",campaignId)

      if (error) {
        alert(error.error_description || error.message)
      }
      if(!error) {
        console.log(data[0])
        setIsOpen(true)
      }
    }
    }
        return(
            <form className='w-center w-full h-700 relative text-center item-center ' onSubmit={handleSubmit}>
            <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
            className='flex md:flex-row  flex-col w-center h-30 md:px-35 sm:px-60 ss:px-35 xs:px-24 px-8' >
            <h2 className='mb-4 font-semibold text-[20px]'>Update Song-Track Details</h2>
            </div>
            <table className="w-full font-semibold text-[20px]" 
            style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}>
            
            {integrationdetails.map(integrationdetails => (
                <div className="w-full">
               <tr>
                
                <td >
                <span>Song Title</span>
                </td><td>
                &nbsp;&nbsp;
                <input
                            name="songtitle"
                            type="text"
                            defaultValue={integrationdetails.title}
                            onChange={(e) => setsongtitle(e.target.value)}
                            className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                            />
                  </td>
               
                <td >
                <span>Singer</span>&nbsp;&nbsp;
                </td><td>
                <input
                            type='text'
                            name='artist'
                            defaultValue={integrationdetails.artist}
                            onChange={(e) => setartist(e.target.value)}
                            className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                            />
                    </td>
            </tr>
             <tr>
                
                <td >
                    <span>Date of Shoot</span>
                    </td><td>
                <input 
                label='Date of Shoot'
                type="date"
                name='dateofshoot'
                defaultValue={integrationdetails.shoot_date}
                onChange={(e) => setdateofshoot(e.target.value)}
                className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx-10 my-4'
                />
                    </td>
                
                <td style={{width:"400px"}}>
                <span>Date of Closing</span>
                </td><td>
                <input 
                label='dateofclosing'
                type="date"
                name='dateofclosing'
                defaultValue={integrationdetails.closing_date}
                onChange={(e) => setdateofclosing(e.target.value)}
                className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx-8 my-4'
                />
                </td>
            </tr>
            <tr>
                
                <td>
                <span>Deliverables</span>
                </td><td>
                     <input
                            label=''
                            type="text"
                            name='deliverables'
                            defaultValue={integrationdetails.deliverables}
                            onChange={(e) => setDeliverable(e.target.value)}
                            className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                            />
                    </td>
                <td>
                    <span>Artist Involved</span>
                    </td><td>
                <input
                            label=''
                            type="text"
                            name='artistInvolved'
                            defaultValue={integrationdetails.artist_involved}
                            onChange={(e) => setartistInvolved(e.target.value)}
                            className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                            />
                    </td>
            </tr>
            <tr>
            <td>
                <span>Status</span>
                </td><td>
                <select name='status' defaultValue={integrationdetails.status}
                onChange={(e)=>setsstatus(e.target.value)}
                 className= 'border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'>
                <option   value="ACTIVE">ACTIVE</option>
                <option value="CLOSE">CLOSE</option>
                </select>
               
               </td>
           
                <td>
                  <span>Budget Entered : ₹{integrationdetails.low_budget}-{integrationdetails.high_budget}</span>
                </td><td>
                <div className='md:w-90 w-50 py-10'>
                  
                <Range
                  step={1}
                  min={integrationdetails.low_budget}
                  max={100000}
                  values={budget}
                  onChange={(e) => setBudget(e)}
                  renderTrack={({ props, children }) => (
                    <div {...props} className="h-2 flex w-full bg-gray-300 rounded-md">
                      {children}
                      <div
                        className="bg-[#D10269] rounded-full"
                        style={{
                          position: 'absolute',
                          width: `${(budget[1] - budget[0]) * 100 / (100000 - 500)}%`,
                          height: '100%',
                          left: `${(budget[0] - 500) * 100 / (100000 - 500)}%`,
                          zIndex: -1,
                          
                        }}
                      />
                    </div>
                  )}
                  renderThumb={({ props }) => (
        <div {...props} className="h-6 w-6 bg-white rounded-full shadow flex justify-center items-center"
                    >
                      <div className="h-5 w-5 bg-white rounded-full border-purple-400" />
                    </div>
                  )}
                />
                </div>
                
                <p className="mt-2 text-black">Budget Involved: {budget[0]} - {budget[1]}<br/>(you can change budget by scrolling)</p>
            </td>
            </tr>
            </div>
          ))}
              <tr>
                <td>
                <div className='flex flex-col text-center justify-center items-center md:px-8 sm:px-56 ss:px-32 xs:px-24 px-8'>
                
                <button className='bg-[#D10269] text-white sm:p-3 sm:px-16 p-2 px-8 rounded-md'>Update</button>
                </div>
                <CardModal open={isOpen} onCLose={() => {setIsOpen(false)}} showClose={true}>
                <ApproveModalUI
                    title={'ARE YOU SURE YOU WANT TO Update?'}
                    subtitle={'If you choose to update, all te details should be updated!'}
                    handleApprove={() => {
                    navigate('../songintegrationadmin')
                    }}
                />
                </CardModal>
                </td>
              </tr>
            
            </table>
           
            </form>
        )
}
export default UpdateIntegration;