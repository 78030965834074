import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img0 from "../assets/pressrelease/press1.png";
import img1 from "../assets/pressrelease/press2.png";
import img2 from "../assets/pressrelease/press3.png";
import img3 from "../assets/pressrelease/press4.png";
import img4 from "../assets/pressrelease/press5.png";
import img5 from "../assets/pressrelease/press6.png";

const Slider = () => {
  
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
        },
    };

    const imgList = [
        {
          image: img0,
        },
        {
          image: img1,
        },
        {
          image: img2,
        },
        {
          image: img3,
        },
        {
          image: img4,
        },
        {
          image: img5,
        }
    ];
    const itemStyle = {
    margin: "auto",
    };
  return (
    <div style={{ margin: "auto", marginBottom: "50px" }}>
      <Carousel
        responsive={responsive}
        partialVisible={true}
        // keyBoardControl={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        infinite={true}
        itemClass="carousel-item"
        dotListClass="text-purple-200"
      >
        {imgList?.map((elem, index) => (
          <div key={index} className="carousel-item-content" style={itemStyle}>
            <div className={`flex object-cover flex-col rounded-md   m-auto  w-[350px]  h-[350px]    relative items-center   text-center mr-2`} >
              <img
                src={elem.image}
                alt=""
                style={{ width: "440px", height: "432px", margin: "auto" }}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slider;
