import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../utils/supaBase";
import FinishedCampaignList from "../components/FinishedCampaignList";

const CampaignFinished = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    async function fetchCampaigns() {
      const { data, error } = await supabase
        .from("campaign")
        .select("*")
        .eq("finished", true)
        .neq("campaign_type", "SONG_INTEGRATION") //otherwise error of map rendering occurred
        .neq("campaign_type", "SPONSORSHIP")
        .order("created_at", { ascending: false })


      if (error) {
        setError("Could not fetch data");
        setCampaigns([]);
      } else {
        setCampaigns(data);
        setError(null);
        console.log(data);
      }
    }

    fetchCampaigns();
 
  }, []);

  return (
    <div className="flex flex-col">
      <div className="">
        <div >
          <div className="flex flex-row justify-between text-center">
            <h1 className="font-poppins sm:text-xl text-md">
             <u> Finished Campaigns </u>
            </h1>
           
          </div>
          
          <div
               className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-y-4'>

          
            {campaigns &&
              campaigns.map((campaign) => (
                <FinishedCampaignList
                  campaign={campaign}
                  key={campaign.id}
                  finished={true}
                />
              ))}

            {!campaigns && <p>No data available</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignFinished;
