import React from 'react'
import { CartState } from '../context/Context'
import { supabase } from '../utils/supaBase'
import { useState } from 'react'
import { useEffect } from 'react'


const SingleInfluencerCart = ({ influencer,deliverable }) => {
 const { state , dispatch, } = CartState()
 let [total,setTotal] = useState();
 
 const [session, setSession] = useState();
 const [profile, setProfile] = useState();
 const [error, setError] = useState();
 const [showCost, setShowCost] = useState();
 let [showReel, setReel] = useState();
 let [showStory, setStory] = useState();
 let [showIgtv, setIgtv] = useState();
 let [showpostCost, setPostCost] = useState();
 let [showCarousel, setCarousel] = useState();
 let [showintgvideo, setintVideo] = useState();
 let [showdedVideo, setDedVideo] = useState();
 let [showytShorts, setytShorts] = useState();
 let [showstaticTweet, setstaticTweet] = useState();
 let [showvideoTweet, setVideoTweet] = useState();
 let [showLinkeden, setLinkeden] = useState();
 let [showVieoStory, setVideoStory] = useState();

  useEffect(() => {
    
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      console.log(session && session.user.id)

      async function fetchProfiles() {
        const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        
      if (error) {
        setError('Could not fetch data')
        setProfile(null)
        
      } else {
        setProfile(data) 
        setError(null)
        showCostManipulate(data[0].type)
        setCalulation()
      }
      }
      fetchProfiles();
    })
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  },[])

  async function showCostManipulate(type) {
    if(type === 'agency') {
      setShowCost(false)
    } else {
      setShowCost(true)
    }
  }

  async function setCalulation() {
    total = 0;

    if(deliverable === null)
    {
     }
    else{
      if(deliverable.includes("Reels")) {
        
        total = total + (influencer.cost_to_be_shown_for_reel?influencer.cost_to_be_shown_for_reel:0)
        setReel(true)
        setTotal(total)
        
      } 
       if(deliverable.includes("Story")) {
        total = total + (influencer.cost_to_be_shown_for_story?influencer.cost_to_be_shown_for_story:0)
        setTotal(total) 
        setStory(true);
         
      }
      if(deliverable.includes("Video Story"))
      {
        setVideoStory(true);
        total = total + (influencer.cost_to_be_shown_video_story?influencer.cost_to_be_shown_video_story:0)
        setTotal(total) 
      }
      if(deliverable.includes("igtv"))
      {
        setIgtv(true);
        total = total + (influencer.igtv_to_be_shown?influencer.igtv_to_be_shown:0)
        setTotal(total) 

      }
      if(deliverable.includes("Static post"))
      {
        setPostCost(true);
         total = total + (influencer.cost_to_be_shown_for_post?influencer.cost_to_be_shown_for_post:0)
        setTotal(total) 

      }
      if(deliverable.includes("Carousel"))
      {
        setCarousel(true);
         total = total + (influencer.corousel_to_be_shown?influencer.corousel_to_be_shown:0)
        setTotal(total) 

        
      }
      if(deliverable.includes("Integration video"))
      {
        setintVideo(true);
         total = total + (influencer.cost_to_be_shown_for_integration_cost?influencer.cost_to_be_shown_for_integration_cost:0)
        setTotal(total) 

      }
      if(deliverable.includes("Dedicated video"))
      {
        setDedVideo(true);
        total = total + (influencer.cost_to_be_shown_for_dedication_cost?influencer.cost_to_be_shown_for_dedication_cost:0)
        setTotal(total) 

      }
      if(deliverable.includes("Youtube shorts"))
      {
        setytShorts(true);
        total = total + (influencer.cost_to_be_shown_for_short?influencer.cost_to_be_shown_for_short:0)
        setTotal(total) 

      }
      if(deliverable.includes("Tweet Static"))
      {
        setstaticTweet(true);
        total = total + (influencer.total_twitter_cost?influencer.total_twitter_cost:0)
        setTotal(total) 

        
      }
      if(deliverable.includes("Video Tweet"))
      {
        setVideoTweet(true);
        total = total + (influencer.total_twitter_cost?influencer.total_twitter_cost:0)
        setTotal(total) 

       
      }
      if(deliverable.includes("Linkeden"))
      {
        setLinkeden(true);
        total = total + (influencer.total_linkedin_cost?influencer.total_linkedin_cost:0)
        setTotal(total) 
     }
    }
  }

  return (
        <>
        {profile && (
        <div className='flex flex-col justify-center pb-10 '>
            <span className='flex flex-row items-center pb-2'>
          <div className='bg-gray-300 rounded-full p-4 mr-2'></div> 
            <p>{influencer.name}</p></span>
            <div className={showCost ? 'block' : 'hidden'}>
              <div className={showReel === true ? 'block' : 'hidden'}>
                <p className='flex flex-row justify-between pb-2'>Reels₹ 
                <span className='text-opacity-40 text-black'>{influencer.cost_to_be_shown_for_reel
                ?influencer.cost_to_be_shown_for_reel : 0}</span></p>
              </div>
              <div className={showStory === true? 'block' : 'hidden'}>
                  <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>Story₹ 
                  <span className='text-opacity-40 text-black'>{influencer.cost_to_be_shown_for_story?
                  influencer.cost_to_be_shown_for_story : 0}</span></p>
              </div>
              <div className={showVieoStory === true? 'block' : 'hidden'}>
                  <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>Video Story₹ 
                  <span className='text-opacity-40 text-black'>{influencer.cost_to_be_shown_video_story?
                  influencer.cost_to_be_shown_video_story : 0}</span></p>
              </div>

              <div className={showIgtv === true? 'block' : 'hidden'}>
                  <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>Igtv₹ 
                  <span className='text-opacity-40 text-black'>
                    {influencer.igtv_to_be_shown?influencer.igtv_to_be_shown:0}</span></p>
              </div>
              
              <div className={showpostCost === true? 'block' : 'hidden'}>
                  <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>Static Post₹ 
                  <span className='text-opacity-40 text-black'>
                  {influencer.cost_to_be_shown_for_post?influencer.cost_to_be_shown_for_post:0}</span></p>
              </div>

              <div className={showCarousel === true? 'block' : 'hidden'}>
                  <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>
                    Corousel₹ 
                  <span className='text-opacity-40 text-black'>
                    {influencer.corousel_to_be_shown?influencer.corousel_to_be_shown:0}</span></p>
              </div>
              <div className={showintgvideo === true? 'block' : 'hidden'}>
                  <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>
                    Integration Video₹ 
                  <span className='text-opacity-40 text-black'>
                    {influencer.cost_to_be_shown_for_integration_cost
                    ? influencer.cost_to_be_shown_for_integration_cost:0}</span></p>
              </div>
              <div className={showdedVideo === true? 'block' : 'hidden'}>
                  <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>
                    Dedicated Video₹
                  <span className='text-opacity-40 text-black'>
                    {influencer.cost_to_be_shown_for_dedication_cost
                    ? influencer.cost_to_be_shown_for_dedication_cost:0}</span></p>
              </div>
              <div className={showytShorts === true? 'block' : 'hidden'}>
                  <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>
                   Youtube shorts₹
                  <span className='text-opacity-40 text-black'>
                    {influencer.cost_to_be_shown_for_short
                    ? influencer.cost_to_be_shown_for_short:0}</span></p>
              </div>
              <div className={showstaticTweet === true? 'block' : 'hidden'}>
                  <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>
                  Tweet Static₹
                  <span className='text-opacity-40 text-black'>
                    {influencer.total_twitter_cost
                    ? influencer.total_twitter_cost:0}</span></p>
              </div>
              <div className={showvideoTweet === true? 'block' : 'hidden'}>
                  <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>
                  Video Tweet₹
                  <span className='text-opacity-40 text-black'>
                    {influencer.total_twitter_cost
                    ? influencer.total_twitter_cost:0}</span></p>
              </div>
              <div className={showLinkeden === true? 'block' : 'hidden'}>
                  <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>
                  Linkden₹
                  <span className='text-opacity-40 text-black'>
                    {influencer.total_linkedin_cost
                    ? influencer.total_linkedin_cost:0}</span></p>
              </div>
            <p className='flex flex-row justify-between border-b-2 border-black border-opacity-20 pb-2 mb-2'>
            <p className='text-[18px]'>Total₹</p>
            <span className='text-[#7A1848]'>{total}</span></p>
            <button 
            className='bg-gray-600 text-white text-[13px] px-6 p-2 rounded-md'
            onClick={() => {
                dispatch({
                type: 'REMOVE_FROM_CART',
                payload: influencer,
                });
            }}>Remove from cart</button>
            </div>
           
        </div> )}
        </>
  ) 
}

export default SingleInfluencerCart