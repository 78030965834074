import React, { useState, useEffect } from "react";
import InfluencerHeader from "../components/InfluencerHeader";
import { CartState } from "../context/Context";
import SingleInfluencer from "../components/SingleInfluencer";
import { useLocation } from "react-router-dom";
import { supabase } from "../utils/supaBase";
import { BsInstagram } from "react-icons/bs";
import youtubelogo from '../assets/ytcampaign.svg';
import ColorfulBar from '../components/Colorful';
import '../Styles/ColourBarStyle.css'

const Influencers = () => {
  let varnum = 0;
  const location = useLocation();
  const campaignId = location.state.campaignId;

  const {
    state: { cart },
    isLoading,
    dispatch,
    filterState: { genderFilter, costFilter, searchQuery },
  } = CartState();
  const [session, setSession] = useState();
  const [profile, setProfile] = useState();

  const [influencers, setInfluencers] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [campaignFilters, setCampaignFilters] = useState();
  const [deliverable, setDelivarable] = useState([]);
  const [niche, setniche] = useState("");
  const [campLocation, setcamplocation] = useState("");
  const [campDetails, setcampDetails] = useState("");
  const [showCost, setShowCost] = useState();
 

  useEffect(() => {

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      console.log(session && session.user.id);

    async function fetchProfiles() {
      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", session.user.id);

      if (error) {
        setError("Could not fetch data");
        setProfile(null);
      } else {
        setProfile(data);
        setError(null);
        showCostManipulate(data[0].type);
        

      }
    }

    fetchProfiles();
  });

    const fetchCampaigns = async () => {

      const { data, error } = await supabase
        .from("campaign")
        .select("*")
        .eq("id", campaignId);

      if (error) {
        setError("Could not fetch data");
      } else {

        setCampaignFilters(data[0]);
        setDelivarable(data[0].deliverable)
        setniche((data[0].niche))
        setcamplocation((data[0].location))

        fetchInfluencers(data);
        setcampDetails(data);
        setError(null);
        setLoading(false);

      }
    };
    const fetchInfluencers = async (campaignData) => {
      const myArr = (campaignData[0].niche.toString()).split(/[ .:;?!~,`"&|()<>{}\[\]\r\n/\\]+/);
      
      const chkLocation1 = (campaignData[0].location.toString()).split(" ,");
      const chkLocation = (chkLocation1.toString()).split(",");

      //setniche((data[0].niche))
        setcamplocation(chkLocation)
       
    

      let campType = ""
      
     {/* const { data, error } = await supabase
        .from("influencers")
        .select("*")
        .in("gender", campaignData[0].gender)
        .in("niche",myArr)
        .in("location",chkLocation)


        if (error) {
        setError("Could not fetch data");
        setInfluencers([]);
      } else {
        setInfluencers(data);
        setError(null);
        setLoading(false);
      }*/}

      if(campaignData !== "")
      {
         campType = campaignData[0].campaign_type;
      }

      if (campType === "Barter") {

        const { data, error } = await supabase
          .from("influencers")
          .select("*")
          .in("campaign_type_prefered", ["barter", "Barter"])
          .in("gender", [campaignData[0].gender])
          .in("niche", [campaignData[0].niche])
          .in("location", [campaignData[0].location])




        if (error) {
          setError("Could not fetch data");
          setInfluencers([]);
        } else {
          setInfluencers(data);
          setError(null);
          setLoading(false);
        }
      }
      else if (campType === "Affiliate Program") {

        const { data, error } = await supabase
          .from("influencers")
          .select("*")
          .in("affiliation", ["yes", "Yes", "YES"])
          .in("gender", campaignData[0].gender)
          .in("niche", [campaignData[0].niche])
          .in("location", [campaignData[0].location])



        if (error) {
          setError("Could not fetch data");
          setInfluencers([]);
        } else {
          setInfluencers(data);
          setError(null);
          setLoading(false);
        }
      }
      else {
        const { data, error } = await supabase
          .from("influencers")
          .select("*")
          .in("gender", campaignData[0].gender)
          .in("niche", [campaignData[0].niche])
          .in("location", [campaignData[0].location])

        if (error) {
          setError("Could not fetch data");
          setInfluencers([]);
        } else {
          setInfluencers(data);
          setError(null);
          setLoading(false);
        }
      }

    };

    fetchCampaigns();
  }, []);

let x = 0;
  const transformInfluencers = () => {
    let sortedInfluencers = influencers;


    if (searchQuery) {
      sortedInfluencers = sortedInfluencers.filter((influencer) =>
        influencer.name.toLowerCase().includes(searchQuery)
      );
    }


    return sortedInfluencers;
  };

  async function showCostManipulate(type) {
    
    if (type === "agency") {
      setShowCost(false);
    } else {
      setShowCost(true);
    }
  }
  let varNumb = 0;
  return (

    <div className="py-10 flex flex-col relative w-full" >

      {campaignFilters && (
        <InfluencerHeader campaignId={campaignId} filters={campaignFilters} deliverable={deliverable} />
      )}
      <div className="md:mt-14 mt-28 max-h-screen overflow-x-scroll bg-[#FFF7F1]">
          
        <table className="md:mt-14 mt-28 max-h-screen overflow-x-scroll"  
        style={{ width: "100%" }}>
          <thead>
          <tr><th></th></tr>
            <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
              <th style={{ textAlign: "left", width:"15%" }}>Account</th>
              <th style={{ textAlign: "left" , width:"8%"}}>G(Age)</th>

              <th style={{ textAlign: "left" ,width:"20%"}}>
              Followers - ER</th>
              <th style={{ textAlign: "left",width:"10%" }}>
              <img src={youtubelogo} color="#3b5998" size={18} className="mx-3" style={{ width: "20px" }}/></th>
              <th style={{ textAlign: "left",width:"15%" }}>
              <BsInstagram color="#d62976" size={18} className="mx-3" />
               {/*Reach*/}</th>
              <th style={{ textAlign: "left" }}>Estimated ROI</th>
              <th className={showCost ? 'block' : 'hidden'} style={{ textAlign: "left" }}>Cost</th>
              <th style={{ textAlign: "left" }}>Add/Remove</th>
            </tr>
          </thead>
         
          {influencers &&
            transformInfluencers().map((single) => {
    x = x+1;
              return (
                <>
                  <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                    <td>({x}).{single.name} </td>
                    <td>{single.gender === 'Male'?'M' :
                    (single.gender === 'Female'?'F':
                    (
                      'O'
                    ))}
                   ({single.age})</td>
                    <td>
                      <tr >
                        <td>
                        <div className="flex flex-col ">
                            <div className="flex flex-row border-b-2 items-center">
                             
                              <a href={single.instagram_links} target="_blank">
                                <BsInstagram
                                  color="#d62976"
                                  size={18}
                                  className="mx-3"
                                />
                              </a>
                              <span className="font-poppins text-sm">
                                {single.instagram_followers}-{single.engagement_rate}%
                              </span>
                             </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <div className="flex flex-col ">
                            <div className="flex flex-row border-b-2 items-center">
                             
                              <a href={single.youtube_link} target="_blank">
                                <img src={youtubelogo}
                                  color="#3b5998"
                                  size={18}
                                  className="mx-3"
                                  style={{ width: "20px" }}
                                />
                              </a>
                            
                              <span className="font-poppins text-sm ">
                                {single.youtube_followers} -  {single. yt_engrate?single. yt_engrate:'0'}%
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </td>
                    <td>
                    {single.yt_average_views?single.yt_average_views:'0000'}</td>
                    <td>
                    <tr className="flex flex-row border-b-2" >
                    <td>Post Reach {single.insta_post_reach?single.insta_post_reach:'00'}
                    <ColorfulBar value={single.insta_post_reach?single.insta_post_reach:"NA"} maxValue={5000} />
                    </td></tr>
                    <tr className="flex flex-row border-b-2">
                    <td>Reel Reach {single.insta_reel_reach?single.insta_reel_reach:"00"}
                    <ColorfulBar value={single.insta_reel_reach?single.insta_reel_reach:"NA"} maxValue={5000} />
                    </td></tr>
                    <tr className="flex flex-row border-b-2">
                    <td>Story Reach {single.insta_story_reach?single.insta_story_reach:"00"}
                    <ColorfulBar value={single.insta_story_reach?single.insta_story_reach:"NA"} maxValue={5000} />
                    </td></tr>
                    </td>
                    
                    <td>
                      <tr>
                        <td>
                          <div className="flex flex-col ">
                            <div className="flex flex-row border-b-2 items-center">
                              
                                <BsInstagram
                                  color="#d62976"
                                  size={18}
                                  className="mx-3"
                                />
                               <span className="font-poppins text-sm">
                                {single.min_roi.toFixed(2)}-{single.roi.toFixed(2)}
                              </span>
                            </div>
                           
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <div className="flex flex-col ">
                            <div className="flex flex-row border-b-2 items-center">
                             
                                <img src={youtubelogo}
                                  color="#3b5998"
                                  size={18}
                                  className="mx-3"
                                  style={{ width: "20px" }}
                                />
                                 <span className="font-poppins text-sm">
                                {single.yt_min_roi}-{single.yt_max_roi}
                              </span>
                            
                            </div>
                            
                          </div>
                        </td>
                        </tr>
                       
                     
                    </td>
                    {/*shows only for brands*/}
                    <td className={showCost ? 'block' : 'hidden'} >
                       <tr className={deliverable.includes("Reels") ? 'block' : 'hidden'}>
                       <td> 
                       <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                       Reels-₹{single.cost_to_be_shown_for_reel
                        ? single.cost_to_be_shown_for_reel : 0}</span></td></tr>
                       <tr className={deliverable.includes("Story") ? 'block' : 'hidden'}>
                       <td>
                       <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                       Story-₹{single.cost_to_be_shown_for_story ?
                        single.cost_to_be_shown_for_story : 0}</span>
                        </td></tr>

                       <tr className={deliverable.includes("Video Story")? 'block' : 'hidden'}>
                       <td>
                       <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                       Video Story-₹{single.cost_to_be_shown_video_story ?
                        single.cost_to_be_shown_video_story : 0}</span></td></tr>

                        <tr className={deliverable.includes("igtv") ? 'block' : 'hidden'}>
                        <td> <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                        Igtv-₹{single.igtv_to_be_shown ? single.igtv_to_be_shown : 0}</span></td></tr>

                        <tr className={deliverable.includes("Static post") ? 'block' : 'hidden'}
                        ><td>
                        <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                        Static Post-₹{single.cost_to_be_shown_for_post ? single.cost_to_be_shown_for_post : 0}
                        </span>
                        </td></tr>
                        <tr className={deliverable.includes("Carousel") ? 'block' : 'hidden'}><td>
                        <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                        Corousel-₹{single.corousel_to_be_shown ? single.corousel_to_be_shown : 0}</span>
                        </td></tr>
                        <tr className={deliverable.includes("Integration video") ? 'block' : 'hidden'}><td>
                        <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                      Integration Video-₹{single.cost_to_be_shown_for_integration_cost
                          ? single.cost_to_be_shown_for_integration_cost : 0}</span>
                        </td></tr>
                        <tr className={deliverable.includes("Dedicated video") ? 'block' : 'hidden'}><td>
                        <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                      Dedicated Video-₹{single.cost_to_be_shown_for_dedication_cost
                          ? single.cost_to_be_shown_for_dedication_cost : 0}</span>
                        </td></tr>
                        <tr className={deliverable.includes("Youtube shorts") ? 'block' : 'hidden'}><td>
                        <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                      Youtube shorts-₹{single.cost_to_be_shown_for_short
                          ? single.cost_to_be_shown_for_short : 0}</span>
                        </td></tr>
                        <tr className={deliverable.includes("Tweet Static") ? 'block' : 'hidden'}><td>
                        <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                      Tweet Static-₹{single.total_twitter_cost
                          ? single.total_twitter_cost : 0}</span>
                        </td></tr>
                        <tr className={deliverable.includes("Video Tweet") ? 'block' : 'hidden'}><td>
                        <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                      Video Tweet-₹{single.total_twitter_cost
                          ? single.total_twitter_cost : 0}</span>
                        </td></tr>
                        <tr className={deliverable.includes("Linkeden") ? 'block' : 'hidden'}><td>
                        <span style={{ textAlign: "left" }} className='flex flex-row justify-between 
                        border-b-2 border-black border-opacity-20 pb-2 font-normal'>
                      Linkden-  ₹{single.total_linkedin_cost
                          ? single.total_linkedin_cost : 0}</span>
                        </td></tr>
                 
                    </td>
                    <td style={{ textAlign: "left" }}>
                      <div className="flex flex-col items-center ">
                        {cart.some(
                          (p) => p.influencer_id === single.influencer_id
                        ) ? (
                          <button
                            className="bg-[#f09ac5] text-white text-[13px] px-5 p-2 rounded-md w-30 mt-4"
                            onClick={() => {
                              dispatch({
                                type: "REMOVE_FROM_CART",
                                payload: single,
                              });
                            }}
                          >
                            Remove from cart
                          </button>
                        ) : (
                          <button
                            className="bg-[#D10269] text-white text-[10px] px-3 p-3 rounded-md w-20  "
                            onClick={() => {
                              dispatch({
                                type: "ADD_TO_CART",
                                payload: single,
                              });
                            }}
                          >
                            Add to cart
                          </button>
                        )}

                      </div>
                    </td>
                  </tr>

                </>)

            })}

        </table>
      </div>
    {/*  <div className="md:mt-14 mt-28 max-h-screen overflow-x-scroll bg-[#FFF7F1]">
     
        {influencers &&
          transformInfluencers().map((single) => {
            varnum = varnum + 1;
            return <SingleInfluencer influencer={single} deliverable={deliverable} niche={niche}
             campLocation={campLocation} varnum = {varnum}
            />;
          })}
      </div>*/}

    </div>
  );
};

export default Influencers;
