import React from 'react'
import styles from '../styles'
import cmptype1 from '../assets/Snapinsta1.png'
import cmptype2 from '../assets/sliderImg4.png'
import cmptype3 from '../assets/sliderImg3.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { SelectCampaignTypeCard } from '../components/SelectCampaignTypeCard'

const SelectCampaignType = () => {

  const navigate = useNavigate();
  const location = useLocation();


  return (
    <div className='bg-white w-full'>

        <div className={`${styles.paddingX} ${styles.flexCenter}  `}>
          <div className={` flex flex-col justify-center  text-center items-center`}>
            <h1 className='text-[#7A1848] font-bold font-spectral italic md:text-3xl text-xl'>SELECT YOUR CAMPAIGN TYPE</h1>
          </div>
        </div>

        <section className={`flex md:flex-row gap-x-2 flex-col text-center md:px-0.5 pt-1 sm:px-0 px-4 items-center w-full`}>
          <SelectCampaignTypeCard 
              image={cmptype1}
              label={'Influencer Marketing'}
              redirectlink={'/dashboard/campaigntype'}
            />
 
            <SelectCampaignTypeCard 
              image={cmptype2}
              label={'Sponsorship Deals'}
              redirectlink={'/dashboard/sponsorshipdealuser'}
            />

            <SelectCampaignTypeCard 
              image={cmptype3}
              label={'Song Integration'}
              redirectlink={'/dashboard/songintegrationcampaign'}
            />

           </section>

    </div>
  )
}

export default SelectCampaignType