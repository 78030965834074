import React, {  useState } from "react";
import {  useLocation } from "react-router-dom";
import { supabase } from "../utils/supaBase";
import ApproveModalUI from '../components/ApproveModalUI';
import { useNavigate } from 'react-router-dom';
import CardModal from '../components/CardModal';
import { BsInstagram, BsTwitter, BsFacebook,BsYoutube } from 'react-icons/bs';

const EditInfluencer = () => {
  const navigate = useNavigate();
  
  const [error, setError] = useState();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const influencer = location.state.influencer;
  const influencerId = influencer.influencer_id;
  console.log(influencer)

  const [engrate, setengrate] = useState(influencer.engagement_rate?influencer.engagement_rate:0);
  const [minroi, setminroi] = useState(influencer.min_roi?influencer.min_roi:0);
  const [roi, setroi] = useState(influencer.roi?influencer.roi:0);
  const [instaFollower, setinstaFollower] = useState(influencer.instagram_followers?influencer.instagram_followers:0);
  const [youtubeFollower, setyoutubeFollower] = useState(influencer.youtube_followers?influencer.youtube_followers:0);
  const [shownreelcost, setshownreelcost] = useState(influencer.cost_to_be_shown_for_reel?influencer.cost_to_be_shown_for_reel:0);
  const [shownstorycost, setshownstorycost] = useState(influencer.cost_to_be_shown_for_story?influencer.cost_to_be_shown_for_story:0);
  const [shownvideocost, setshownvideocost] = useState(influencer.cost_to_be_shown_video_story?influencer.cost_to_be_shown_video_story:0);
  const [staticPost, setstaticPost] = useState(influencer.shown_static_post_cost?influencer.shown_static_post_cost:0);
  const [igtvcost, setigtvcost] = useState(influencer.igtv_shown?influencer.igtv_shown:0);
  const [carouselcost, setcarouselcost] = useState(influencer.carousel_shown?influencer.carousel_shown:0);
  const [shownintegrationcost, setshownintegrationcost] = useState(influencer.cost_to_be_shown_for_integration_cost?influencer.cost_to_be_shown_for_integration_cost:0);
  const [showndedicationcost, setshowndedicationcost] = useState(influencer.cost_to_be_shown_for_dedication_cost?influencer.cost_to_be_shown_for_dedication_cost:0);
  const [shownshortscost, setshownshortscost] = useState(influencer.cost_to_be_shown_for_short?influencer.cost_to_be_shown_for_short:0);
  const [statictweet, setstatictweet] = useState(influencer.shown_tweet_static?influencer.shown_tweet_static:0);
  const [shownvideotweet, setshownvideotweet] = useState(influencer.shown_video_tweet?influencer.shown_video_tweet:0);
  const [youtubeLink, setyoutubeLink] = useState(influencer.youtube_link?influencer.youtube_link:"NA");
  const [formError, setFormError] = useState("");

  const [instaLike, setlikes] = useState();
  const [instaComments, setcomments] = useState();
  const [postreach, setpostreach] = useState();
  const [reelreach, setreelreach] = useState();
  const [storyreach, setstoryreach] = useState();

 
  async function handleUpdate() {
   
    alert("postreach==="+postreach+"==reelreach=="+reelreach+"===storyreach=="+storyreach)
    if (engrate === '' || instaFollower === '' || youtubeFollower === '' || shownreelcost === '' ||
      shownstorycost === '' || shownvideocost === '' || staticPost === "" || igtvcost === "" || carouselcost === ""
      || shownintegrationcost === "" || showndedicationcost === "" || shownshortscost === "" ||
      statictweet === "" || shownvideotweet === "") {

      setFormError('Please fill in all the fields correctly.')
      setIsOpen(false)
    } else {

      const { error1 } = await supabase
        .from("influencers")
        .update({
          engagement_rate: engrate,
          min_roi: minroi,
          roi: roi,
          instagram_followers: instaFollower,
          cost_to_be_shown_for_reel: shownreelcost,
          youtube_followers: youtubeFollower,
          cost_to_be_shown_for_story: shownstorycost,
          cost_to_be_shown_video_story: shownvideocost,
          shown_static_post_cost: staticPost,
          igtv_shown: igtvcost,
          carousel_shown: carouselcost,
          cost_to_be_shown_for_integration_cost: shownintegrationcost,
          cost_to_be_shown_for_dedication_cost: showndedicationcost,
          cost_to_be_shown_for_short: shownshortscost,
          shown_tweet_static: statictweet,
          shown_video_tweet: shownvideotweet,
          youtube_link: youtubeLink,

          insta_avg_likes:instaLike,
          insta_avg_comments:instaComments,
          insta_post_reach:postreach,
          insta_reel_reach :reelreach,
          insta_story_reach:storyreach,


        })
        .eq("influencer_id", influencerId);

      if (error1) {
        alert(error.error_description || error.message);
      } else {
        console.log("sucess")
        console.log("Data updated" + influencer.name)
        setIsOpen(true)
        //window.location.reload(true);
        navigate('/dashboardadmin/influencers')
      }
    }
  }
async function calculateROI(engRate)
{
if(engRate !== "")
{
  setminroi (engrate * 0.7)
  setroi(engRate * 1.435)
  setengrate(engRate)
}
}
  return (
    <>
      <span className='flex flex-col text-end text-[#FF0000] w-right font-DM Sans text-[12px] text-#7A1848red p-3 px-8'>
        *All the fields are required
      </span>
      {formError && <p className="error text-red-600 pt-4">{formError}</p>}
      {influencer ?
        <div className="flex flex-col p-4 bg-[#FFF7F1] rounded-md mb-5 w-[max-content]">
          <div className="flex flex-col justify-self-end font-poppins gap-y-1 ">
            <table
              style={{
                borderCollapse: "collapse",
                width: "max-content",
                border: "1.5px solid rgba(93, 53, 253, 0.2)",
              }}
            >
              <tr className='font-dmSans sm:text-[18px] text-[18px] 
             py-1 text-center bg-pink-300' style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td  colSpan={4}>
                  General Information
                </td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td >Name :  </td>
                <td>{influencer.name}</td>
                <td >
                  Gender(Age) :
                </td>
                <td>{influencer.gender} ( {influencer.age})</td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td style={{ width: "50%" }}>
                  Location : </td>
                <td>
                  {influencer.location}
                </td>
                <td style={{ width: "50%" }}>Niche :
                </td>
                <td>{influencer.niche} </td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td style={{ width: "50%" }}>Deliverables :
                </td>
                <td>{influencer.deliverable}</td>

                <td style={{ width: "50%" }}>Barter : </td>
                <td>{influencer.campaign_type_prefered} </td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
              <td colspan={4} style={{textAlign:"center"}} className='font-dmSans sm:text-[18px] text-[18px] 
             py-1 text-center bg-pink-200'>Instagram Details </td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>Engagement Rate :</td>
                <td>
                  <input
                    name="engrate"
                    type="text"
                    defaultValue={influencer.engagement_rate}
                   // onChange={(e) => setengrate(e.target.value)}
                   onChange={(e) => calculateROI(e.target.value)}
                    className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                  />
                  % </td>
                <td>
                  <input
                    name="minroi"
                    type="hidden"
                    //maxLength={4}
                    defaultValue={influencer.min_roi}
                    onChange={(e) => setminroi(e.target.value)}
                    className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                  />
                </td>
                <td>
                  <input
                    name="roi"
                    type="hidden"
                    defaultValue={influencer.roi}
                    onChange={(e) => setroi(e.target.value)}
                    className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                  />
                </td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>Average Likes :</td>
                <td>
                  <input
                    name="avgLikes"
                    type="text"
                    defaultValue={influencer.insta_avg_likes}
                    onChange={(e) => setlikes(e.target.value)}
                    className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                  />
                   </td>
                   <td>Average Comments :</td>
                <td>
                  <input
                    name="avgComments"
                    type="text"
                    defaultValue={influencer.insta_avg_comments}
                    onChange={(e) => setcomments(e.target.value)}
                    className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                  />
                   </td>
                  </tr>
                  <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td colspan={4} style={{textAlign:"center"}} className='font-dmSans sm:text-[18px] text-[18px] 
             py-1 text-center bg-pink-200'> Estimate Reach:</td>
                </tr>
                <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>
                <div>Post Reach</div>
                  <input
                    name="postreach"
                    type="text"
                    defaultValue={influencer.insta_post_reach}
                    onChange={(e) => setpostreach(e.target.value)}
                    className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                  />
                   </td>
                  
                <td>
                <div>Reel Reach</div>
                  <input
                    name="reelreach"
                    type="text"
                    defaultValue={influencer. insta_reel_reach}
                    onChange={(e) => setreelreach(e.target.value)}
                    className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                  />
                   </td>
                   
                   <td>
                   <div>Story Reach</div>
                  <input
                    name="storyreach"
                    type="text"
                    defaultValue={influencer.insta_story_reach}
                    onChange={(e) => setstoryreach(e.target.value)}
                    className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                  />
                   </td>
                  </tr>
              <tr className='font-dmSans sm:text-[18px] text-[18px] 
             py-1 text-center bg-pink-300' style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td colSpan={4}>Social Media Information</td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td >&nbsp; </td>
                <td style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}> Links:</td>
                <td> Followers</td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
              <td><BsInstagram color='#d62976' size={24} className='mx-5' /></td>
                
                <td>
                <a href={influencer.instagram_links} target='_blank'>{influencer.instagram_links} </a> </td>
                <td>
                  <input
                    name="instaFollower"
                    type="text"
                    defaultValue={influencer.instagram_followers}
                    onChange={(e) => setinstaFollower(e.target.value)}
                    className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                  />
                </td>

              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td><BsFacebook color='#3b5998' size={24} className='mx-5'/></td>
                <td>-  </td>
                <td>- </td>
              </tr>{" "}
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>
                <a href={influencer.youtube_link} target='_blank'>
                <BsYoutube color='#3b5998' size={24} className='mx-5'/>
                </a></td>
                <td>
                <input
                    name="youtubelink"
                    type="text"
                    defaultValue={influencer.youtube_link?influencer.youtube_link:'NA'}
                    onChange={(e) => setyoutubeLink(e.target.value)}
                    className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3 w-[350px]'
                  />
                 </td>
                <td>
                  <input
                    name="youtubeFollower"
                    type="text"
                    defaultValue={influencer.youtube_followers?influencer.youtube_followers:0}
                    onChange={(e) => setyoutubeFollower(e.target.value)}
                    className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                  />
                  
                </td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td><BsTwitter color='#00acee' size={24} className='mx-5'/></td>
                <td> <a href={influencer.twitter_links} target='_blank'>{influencer.twitter_links}</a>  </td>
                <td>-</td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>Linkedin</td>
                <td> <a href={influencer.linkedin_links} target='_blank'> {influencer.linkedin_links}</a> </td>
                <td>- </td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>Others</td>
                <td> <a href={influencer.other_links} target='_blank'>{influencer.other_links} </a> </td>
                <td>-</td>
              </tr>
              <tr className='font-dmSans sm:text-[18px] text-[18px] 
             py-1 text-center bg-pink-300' style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td colSpan={4} style={{font:"20px",alignItems:"center"}}>Costing</td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>Reels</td>

                <td> <input
                  name="shownreelcost"
                  type="text"
                  defaultValue={influencer.cost_to_be_shown_for_reel ? influencer.cost_to_be_shown_for_reel : 0}
                  onChange={(e) => setshownreelcost(e.target.value)}
                  className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                /></td>

                <td>Story</td>

                <td> <input
                  name="shownstorycost"
                  type="text"
                  defaultValue={influencer.cost_to_be_shown_for_story ? influencer.cost_to_be_shown_for_story : 0}
                  onChange={(e) => setshownstorycost(e.target.value)}
                  className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                /></td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>Video-Story</td>

                <td> <input
                  name="shownvideocost"
                  type="text"
                  defaultValue={influencer.cost_to_be_shown_video_story ? influencer.cost_to_be_shown_video_story : 0}
                  onChange={(e) => setshownvideocost(e.target.value)}
                  className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                /></td>

                <td>Static Post</td>

                <td> <input
                  name="staticPost"
                  type="text"
                  defaultValue={influencer.shown_static_post_cost ? influencer.shown_static_post_cost : 0}
                  onChange={(e) => setstaticPost(e.target.value)}
                  className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                /></td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>IGTV</td>

                <td> <input
                  name="igtvcost"
                  type="text"
                  defaultValue={influencer.igtv_shown ? influencer.igtv_shown : 0}
                  onChange={(e) => setigtvcost(e.target.value)}
                  className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                /></td>

                <td>Carousel</td>

                <td> <input
                  name="carouselcost"
                  type="text"
                  defaultValue={influencer.carousel_shown ? influencer.carousel_shown : 0}
                  onChange={(e) => setcarouselcost(e.target.value)}
                  className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                /></td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>Integration Video</td>

                <td> <input
                  name="shownintegrationcost"
                  type="text"
                  defaultValue={influencer.cost_to_be_shown_for_integration_cost ? influencer.cost_to_be_shown_for_integration_cost : 0}
                  onChange={(e) => setshownintegrationcost(e.target.value)}
                  className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                /></td>

                <td>Dedication Video</td>

                <td> <input
                  name="showndedicationcost"
                  type="text"
                  defaultValue={influencer.cost_to_be_shown_for_dedication_cost ? influencer.cost_to_be_shown_for_dedication_cost : 0}
                  onChange={(e) => setshowndedicationcost(e.target.value)}
                  className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                /></td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>Youtube Shorts</td>

                <td> <input
                  name="shownshortscost"
                  type="text"
                  defaultValue={influencer.cost_to_be_shown_for_short ? influencer.cost_to_be_shown_for_short : 0}
                  onChange={(e) => setshownshortscost(e.target.value)}
                  className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                /></td>
              </tr>
              <tr style={{ border: "1.5px solid rgba(93, 53, 253, 0.2)" }}>
                <td>Tweet Static</td>

                <td> <input
                  name="statictweet"
                  type="text"
                  defaultValue={influencer.shown_tweet_static ? influencer.shown_tweet_static : 0}
                  onChange={(e) => setstatictweet(e.target.value)}
                  className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                /></td>


                <td>Video Tweet</td>

                <td> <input
                  name="shownvideotweet"
                  type="text"
                  defaultValue={influencer.shown_video_tweet ? influencer.shown_video_tweet : 0}
                  onChange={(e) => setshownvideotweet(e.target.value)}
                  className='border-2 border-[#FD359999] rounded-md p-3 border-opacity-60 mx=10 my-3'
                /></td>
              </tr>

            </table>
            <button className='bg-[#D10269] text-white sm:p-3 sm:px-16 p-2 px-8 rounded-md'
              onClick={() => {
                setIsOpen(true);

              }}>

              Update</button>

          </div>
          <CardModal open={isOpen} onCLose={() => { setIsOpen(false) }} showClose={true}>
            <ApproveModalUI
              title={'ARE YOU SURE YOU WANT TO Update?'}
              subtitle={'If you choose to update, all te details should be updated!'}
              handleApprove={handleUpdate}
            />
          </CardModal>
        </div>


        : <div>No Data Available</div>}





    </>
  );
};

export default EditInfluencer;