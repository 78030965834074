import React, { useState } from 'react'
import { CartState } from '../context/Context'
import SingleInfluencerCart from './SingleInfluencerCart'
import { supabase } from '../utils/supaBase'
import { useNavigate } from 'react-router-dom'
import CardModal from './CardModal'
import ApproveModalUI from './ApproveModalUI'

const InfluencerCart = ({ id,deliverable}) => {

  const { state: { cart }, dispatch, filterDispatch } = CartState()
  let total = 0
  const navigate = useNavigate();
  const[isOpen, setIsOpen] = useState(false)
  let [strToSave, setstrToSave] = useState([])

  async function submitCampaign() {
    let temp = []
    cart.map((item) => {
     
      strToSave = "";
      temp.push(item.name)
      if(deliverable.includes("Reels")) {
        strToSave = strToSave.concat("Reels-"+(item.cost_to_be_shown_for_reel?item.cost_to_be_shown_for_reel:0)+",")
        setstrToSave(strToSave)
      } 
       if(deliverable.includes("Story")) {
         strToSave = strToSave.concat("Story-"+(item.cost_to_be_shown_for_story?item.cost_to_be_shown_for_story:0)+",")
         setstrToSave(strToSave)
      }
    if(deliverable.includes("Video Story"))
      {
        strToSave = strToSave.concat("Video Story-"+(item.cost_to_be_shown_video_story?item.cost_to_be_shown_video_story:0))
        setstrToSave(strToSave);
      }
      if(deliverable.includes("igtv"))
      {
        strToSave = strToSave.concat("igtv-"+(item.igtv_to_be_shown?item.igtv_to_be_shown:0)+",")
        setstrToSave(strToSave)
}
      if(deliverable.includes("Static post"))
      {
        strToSave = strToSave.concat("Static Story-"+(item.cost_to_be_shown_for_post?item.cost_to_be_shown_for_post:0)+",")
        setstrToSave(strToSave)
      }
      if(deliverable.includes("Carousel"))
      { 
        strToSave = strToSave.concat("Carousel-"+(item.corousel_to_be_shown?item.corousel_to_be_shown:0)+",")
        setstrToSave(strToSave)
      }
      if(deliverable.includes("Integration video"))
      {
        strToSave = strToSave.concat("Integration video -"+(item.cost_to_be_shown_for_integration_cost?item.cost_to_be_shown_for_integration_cost:0)+",")
        setstrToSave(strToSave)
      }
      if(deliverable.includes("Dedicated video"))
      {
        strToSave = strToSave.concat("Dedicated video-"+(item.cost_to_be_shown_for_dedication_cost?item.cost_to_be_shown_for_dedication_cost:0)+",")
        setstrToSave(strToSave)
      }
      if(deliverable.includes("Youtube shorts"))
      {
        strToSave = strToSave.concat("Youtube shorts -"+(item.cost_to_be_shown_for_short?item.cost_to_be_shown_for_short:0)+",")
        setstrToSave(strToSave)
      }
      if(deliverable.includes("Tweet Static"))
      { 
        strToSave = strToSave.concat("Tweet Static -"+(item.total_twitter_cost?item.total_twitter_cost:0)+",")
        setstrToSave(strToSave)
      }
      if(deliverable.includes("Video Tweet"))
      { 
        strToSave = strToSave.concat("Video Tweet-"+(item.total_twitter_cost?item.total_twitter_cost:0)+",")
        setstrToSave(strToSave)
      }
      if(deliverable.includes("Linkeden"))
      { 
        strToSave = strToSave.concat("Linkeden -"+(item.total_linkedin_cost?item.total_linkedin_cost:0))
        setstrToSave(strToSave)
      }

    console.log("-==-=-temp=-=-"+item.influencer_id+"-*-*-*"+strToSave);
          addInfluencers(id,item.influencer_id,strToSave)
    })
   
      const { error } = await supabase
      .from('campaign')
      .update({ influencers: temp, completed : true })
      .eq('id', id)
  
      if (error) {
          alert(error.error_description || error.message)
        } else {
          dispatch({
            type: 'CLEAR_CART'
          })
          filterDispatch({
            type: 'CLEAR_FILTERS'
          })
          navigate('/thankyou')
          //navigate('/dashboard')
        }
    
      
  }
  async function addInfluencers(campaign_id,influencer_id,arrvalue)
{
  setstrToSave(arrvalue)
  
  if(campaign_id !== "" && influencer_id !== "")
  {
    const { error } = await supabase
    .from('campaign_info')
    .insert({
      campaign_id : campaign_id,
      influencer_id : influencer_id,
      description : strToSave
    })
    if (error) {
      alert(error.error_description || error.message)
      return;
    }
  else{
    //console.log("Influencer added")
  }
  }     
}

  return (
    <div className='flex flex-col h-[500px] overflow-y-auto w-72 bg-[#FFF9F1] p-10 overflow-x-auto '>
        {cart.map((influencer) => {
          {deliverable.includes('Reels')?
          total += ((influencer.cost_to_be_shown_for_reel?influencer.cost_to_be_shown_for_reel:0))
          :
          total += 0
          }
          {deliverable.includes('Story')?
          total += ((influencer.cost_to_be_shown_for_story?influencer.cost_to_be_shown_for_story:0))
          :
          total += 0
          }
          {deliverable.includes('Video Story')?
          total += ((influencer.cost_to_be_shown_video_story?influencer.cost_to_be_shown_video_story:0))
          :
          total += 0
          }
          {deliverable.includes('igtv')?
          total += ((influencer.igtv_to_be_shown?influencer.igtv_to_be_shown:0))
          :
          total += 0
          }
          {deliverable.includes('Static post')?
          total += ((influencer.cost_to_be_shown_for_post?influencer.cost_to_be_shown_for_post:0))
          :
          total += 0
          }
          {deliverable.includes('Carousel')?
          total += ((influencer.corousel_to_be_shown?influencer.corousel_to_be_shown:0))
          :
          total += 0
          }
          {deliverable.includes('Integration video')?
          total += ((influencer.cost_to_be_shown_for_integration_cost?
            influencer.cost_to_be_shown_for_integration_cost:0))
          :
          total += 0
          }
          {deliverable.includes('Dedicated video')?
          total += ((influencer.cost_to_be_shown_for_dedication_cost?
            influencer.cost_to_be_shown_for_dedication_cost:0))
          :
          total += 0
          }
          {deliverable.includes('Youtube shorts')?
          total += ((influencer.cost_to_be_shown_for_short?influencer.cost_to_be_shown_for_short:0))
          :
          total += 0
          }
          {deliverable.includes('Tweet Static')?
          total += ((influencer.total_twitter_cost?influencer.total_twitter_cost:0))
          :
          total += 0
          }
          {deliverable.includes('Video Tweet')?
          total += ((influencer.total_twitter_cost?influencer.total_twitter_cost:0))
          :
          total += 0
          }
          {deliverable.includes('Linkeden')?
          total += ((influencer.total_linkedin_cost?influencer.total_linkedin_cost:0))
          :
          total += 0
          }
            return (
                <>
                 <SingleInfluencerCart influencer={influencer} deliverable={deliverable}/>
                </>
            )
        })}
        <p className='flex flex-row justify-between text-[#7A1848] text-[20px] font-[500]'><span>Subtotal</span><span>{total}</span></p>
        <button 
        onClick={() => {setIsOpen(true)}}
        className='bg-[#D10269] text-white  p-2 px-8 rounded-md text-sm absolute bottom-16 right-20 left-20'

        >Submit Campaign</button>

        <CardModal open={isOpen} onCLose={() => {setIsOpen(false)}} showClose={true}>
        <ApproveModalUI
          title={'ARE YOU SURE YOU WANT TO ADD?'}
          subtitle={'If you choose to add, your application will be submitted to our Brandlytical Team'}
          handleApprove={submitCampaign}
        />
        </CardModal>
    </div>
  )
}

export default InfluencerCart