import React ,{useState,useEffect}from "react";
import { supabase } from '../utils/supaBase'
import { useNavigate } from 'react-router-dom';
import IntegrationStatus from '../components/IntegrationStatus';
import plus from '../assets/plus.svg'
import AddSongDetails from '../components/AddSongDetails';
const SongIntegrationAdmin = () =>
{
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([]);
    const [error, setError] = useState();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() =>{
      async function fetchCampaigns() {
        const { data, error1 } = await supabase
        .from('integration_details')
        .select('*')
       // .eq('status', "ACTIVE")
        .eq('integration_type','SPONSORSHIP');
        
        if (error1) {
          setError('Could not fetch data');
          console.log(error1)
          setCampaigns([]);
        } else {
          setCampaigns(data);
          setError(null);
         // console.log(data);
        }
      }
      fetchCampaigns();
    }, [])
  
  return (
    <div >
   <div style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}
    className='flex md:flex-row flex-col w-left h-20 md:px-150 sm:px-150 ss:px-45 xs:px-45 px-6' >
     <div className='mb-10 flex flex-row text-xl left-[80px]'>
        <button 
          className='font-poppins font-normal'
          onClick={() => {
                navigate('/dashboardadmin/addsponsorship', {
                
                })
            }}><span className='flex flex-row items-center'>
            Create New Sponsorship Event <img src={plus} className='pl-2'></img></span> 
        </button>
    </div>
    
    </div>
    <div className='flex md:flex-row  flex-col w-full md:px-200 sm:px-200 ss:px-50 xs:px-50 px-2' >
     
    <div className='flex  md:flex-row  flex-col h-[500px] overflow-y-auto 
      w-full bg-[#FFF9F1] p-10 overflow-x-auto ' style={{border: '1px solid rgba(93, 53, 253, 0.2)'}}>
         <div className='grid sm:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-4'>
         {campaigns && campaigns.map((item)=>{
          return <IntegrationStatus campaign={item} active={true}/>
            }
          )}
         </div>
      
       </div>
    </div>
   </div>
  )
}
export default SongIntegrationAdmin;