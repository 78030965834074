import React, {useEffect, useState } from 'react'
import { supabase } from '../utils/supaBase';
import InfluencerCard from '../components/InfluencerCard';
import { CartState } from '../context/Context';
import { useNavigate } from 'react-router-dom';


const InfluencerListAdmin = () => {
  const navigate = useNavigate();
const{ filterState: { genderFilter, costFilter, searchQuery }, filterDispatch } = CartState()
const [influencers, setInfluencers] = useState([]);
const [error, setError] = useState();
const [Loading, setLoading] = useState(true);

  useEffect(() => {
   const fetchInfluencers = async () => {
    const { data, error1 } = await supabase
        .from('influencers')        
          .select('*');

        
      if (error1) {
        setError('Could not fetch data');
        setInfluencers([]);
      } else {
        setInfluencers(data);
        setError(null);
        setLoading(false);
      }
    };

    fetchInfluencers();
  }, []);


  const transformInfluencers = () => {
    let sortedInfluencers = influencers; 

    if (searchQuery) {
      sortedInfluencers = sortedInfluencers.filter(influencer => influencer.name.toLowerCase().includes(searchQuery))
    }
  
    return sortedInfluencers;
  }
  let varNumb = 0;

  return (
    <>
   
    <div className='grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-4 w-full'>
        {
            influencers && (transformInfluencers().map((item) => {
            varNumb = varNumb + 1;
                return(  <InfluencerCard influencer={item} varNumb ={varNumb}/>)
            }))
        }
    </div>
    </>
  )
}

export default InfluencerListAdmin