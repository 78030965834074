import React, { useState } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Slider from "./PressSlider";
import data from "../collection/PressReleaseJson";

const PressRelease = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 200); // Show the button when scrolling down 200 pixels
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Add event listener to handle scroll events
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-white">
      <Nav />

      <div class="md:px-44 sm:px-16 px-6 bg-white">
        <h1 class="flex-1 lg:text-[40px] sm:text-[34px] text-[25px] text-black text-left pt-2">
          Press Release
        </h1>
          {/* slider show later */}
        <div id="Slider" className="Slider mt-5">
          <Slider />
        </div>

        <h5 class="flex-1 lg:text-[30px] sm:text-[24px] text-[20px] text-black text-left pt-2">
          About Simran
        </h5>
        <p class="font-[400]  font-[DM sans] text-black sm:text-[20px] mt-2 sm:mt-2 text-[10px]  ">
          We are passionate about connecting brands with influential creators to drive
          revenue and achieve impactful results. Here you can get the right
          influencers with the right results for your campaigns. We understand
          the power of influencer marketing in today's digital landscape, and
          our platform is designed to help businesses harness that power to
          maximize their revenue&nbsp;potential.
        </p>


        {/* Fetch Links data from JSON */}
        <h2 class="flex-1 lg:text-[30px] sm:text-[24px] text-[20px] text-black text-left pt-2 mt-5">Links</h2>
        {data.map(({id, url}) => (
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-full mx-auto mt-2">
            <ul className="list-disc pl-4">
              <li> <a style={{wordBreak:"break-all"}} href={url} target="_blank" id={id}>{url}</a> </li>
          </ul>
          </div>

        ))}
      </div>
      <div className="mt-2 bg-white" id="footer">
        <Footer />
      </div>
    </div>
  );
};
export default PressRelease;
