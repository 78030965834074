const PressJSON = [
    {
        id: 1, 
        url: "https://indian-xpress.com/simran-singh-the-young-entrepreneur-making-waves-in-influencer-marketing/" 
    },
    {
        id: 2, 
        url: "https://xpresstimes.in/simran-singh-the-young-entrepreneur-making-waves-in-influencer-marketing/" 
    },
    {
        id: 3, 
        url: "https://digitalscoopindia.com/simran-singh-the-young-entrepreneur-making-waves-in-influencer-marketing/" 
    },
    {
        id: 4, 
        url: "https://webstoriesindia.com/simran-singh-the-young-entrepreneur-making-waves-in-influencer-marketing/" 
    },
    {
        id: 5, 
        url: "https://theentrepreneurbytes.com/simran-singh-the-young-entrepreneur-making-waves-in-influencer-marketing/" 
    },
    {
        id: 6, 
        url: "https://indianflux.com/simran-singh-the-young-entrepreneur-making-waves-in-influencer-marketing/" 
    },
    {
        id: 7, 
        url: "https://bestofhindustan.com/simran-singh-the-young-entrepreneur-making-waves-in-influencer-marketing/" 
    },
    {
        id: 8, 
        url: "https://bharatexclusive.com/simran-singh-the-young-entrepreneur-making-waves-in-influencer-marketing/" 
    },
    {
        id: 10, 
        url: "https://indian-xpress.com/simran-singh-the-young-entrepreneur-making-waves-in-influencer-marketing/" 
    },
    {
        id: 11, 
        url: "https://thefilmybeat.com/simran-singh-the-young-entrepreneur-making-waves-in-influencer-marketing/" 
    },
    {
        id: 12, 
        url: "https://medium.com/@digitalscoopindia/simran-singh-the-young-entrepreneur-making-waves-in-influencer-marketing-637c1ddda583" 
    },
    {
        id: 13, 
        url: "https://theindianalert.com/simran-singh-founder-of-brandlytical-media-influencer-marketing-agency/" 
    },
    {
        id: 14, 
        url: "https://hatkestory.com/breaking-the-glass-ceiling-with-simran-singh/" 
    },
    {
        id: 15, 
        url: "https://revolutionarystartups.com/brandlytical-simran-singh/" 
    }
];
export default PressJSON;


